import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { BoxLogos } from "../BoxLogos/BoxLogos";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";

export interface HeaderPaymentProps {
  title: any;
  titleLogo: string;
  isMobile: boolean;
  logos?: { image: string; value: string }[];
  handleChangePaymentMethod: () => void;
  country?: string;
}
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "120%",
    textTransform: "none",
    color: "#112B45",
  },
  logos: {
    marginBottom: theme.spacing(3),
  },
}));
export const HeaderPayment: React.FC<HeaderPaymentProps> = (
  props: HeaderPaymentProps
) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid
          item
          container
          spacing={0}
          direction={props.isMobile ? "row" : "column"}
        >
          <Grid item container xs={12} md={6} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <img src={props.titleLogo} alt={props.titleLogo} />
              </Box>
              <Box px={1}>
                <Typography variant={"body1"} className={classes.title}>
                  {props.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            alignItems={"center"}
            justify={props.isMobile ? "flex-start" : "flex-end"}
            style={{ paddingRight: "0px" }}
          >
            <Button
              variant={"outlined"}
              onClick={props.handleChangePaymentMethod}
            >
              <T id="changeTypePay" />
            </Button>
          </Grid>
        </Grid>
        {props.country !== CountryEnum.CHL ? (
          <Grid className={classes.logos} item container xs={12}>
            <BoxLogos logos={props.logos} />
          </Grid>
        ) : (
          <Grid xs={12} style={{ height: "48px" }}></Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
