import React from "react";
import { Box, Grid, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import esFlag from "../../../assets/images/sm-flag-es.png";
import {
  LocalizeContextProps,
  Translate as T,
  withLocalize,
} from "react-localize-redux";
import enFlag from "../../../assets/images/sm-flag-en.png";
import brFlag from "../../../assets/images/sm-flag-br.png";

const useStyle = makeStyles({
  modalHeader: {
    padding: "20px",
    display: "flex",
    borderBottom: "1px solid #e9ecef",
    justifyContent: "center",
  },
  modal: {
    maxWidth: "100%",
    width: "100%",
    position: "fixed",
    margin: "0",
    top: "auto",
    right: "auto",
    left: "auto",
    bottom: "0",
    borderRadius: "10px 10px 0 0",
    backgroundColor: "white",
  },
  modalBody: {
    padding: "20px",
  },
  languageRowActive: {
    backgroundColor: "#EEF6FF",
    borderRadius: "5px",
  },
  languageRowInactive: {
    cursor: "pointer",
  },
});

export interface LanguageModalProps {
  handleExit: () => void;
  open: boolean;
}

export type TSelectLanguageModalProps = LocalizeContextProps &
  LanguageModalProps;

export const LanguageModal: React.FC<TSelectLanguageModalProps> = (
  props: TSelectLanguageModalProps
) => {
  const checkRowClass = (language: string) => {
    return props.activeLanguage?.code === language
      ? classes.languageRowActive
      : classes.languageRowInactive;
  };
  const classes = useStyle();
  return (
    <Modal open={props.open} onClose={props.handleExit}>
      <div className={classes.modal}>
        <Grid className={classes.modalHeader}>
          <T id="changeLanguage" />
        </Grid>
        <Grid className={classes.modalBody}>
          <Box
            id="boxEs"
            display="flex"
            className={checkRowClass("es")}
            style={{ padding: "10px" }}
            onClick={() => props.setActiveLanguage("es")}
          >
            <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
              <img width={26} height={20} src={esFlag} alt={esFlag} />
            </Grid>
            <Grid item xs={10} sm={11} md={11} lg={11} xl={1}>
              <T id="languages.es" />
            </Grid>
          </Box>
          <Box
            id="boxEn"
            display="flex"
            className={checkRowClass("en")}
            style={{ padding: "10px" }}
            onClick={() => props.setActiveLanguage("en")}
          >
            <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
              <img width={26} height={20} src={enFlag} alt={enFlag} />
            </Grid>
            <Grid item xs={10} sm={11} md={11} lg={11} xl={1}>
              <T id="languages.en" />
            </Grid>
          </Box>
          <Box
            id="boxBr"
            display="flex"
            className={checkRowClass("pt")}
            style={{ padding: "10px" }}
            onClick={() => props.setActiveLanguage("pt")}
          >
            <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
              <img width={26} height={20} src={brFlag} alt={brFlag} />
            </Grid>
            <Grid item xs={10} sm={11} md={11} lg={11} xl={1}>
              <T id="languages.pt" />
            </Grid>
          </Box>
        </Grid>
      </div>
    </Modal>
  );
};
export default withLocalize<TSelectLanguageModalProps>(LanguageModal);
