import { StringUtils } from "../../shared/utils/StringUtils";
import { makeStyles } from "@material-ui/core/styles";
import { IPurchaseDetail } from "./PurchaseDetail.interfaces";

export const usePurchasDetailStyles = (props: IPurchaseDetail) => {
  const [color]: [string, string] = StringUtils.getContrastYIQ(
    props.backgroundColor
  );

  return makeStyles((theme) => ({
    root: {
      maxWidth: 900,
    },
    boxColor: {
      backgroundColor: props.backgroundColor,
      color: "#FFFFFF",
      padding: theme.spacing(2, 3),
    },
    tittle1: {
      fontFamily: "IBM Plex Sans,sans-serif",
      fontWeight: 300,
      fontSize: "23px",
      lineHeight: "141%",
      color,
    },
    tittle2: {
      fontFamily: "IBM Plex Sans,sans-serif",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "15px",
      lineHeight: "120%",
      marginTop: 16,
      color,
    },
    amountDetail: {
      fontWeight: 300,
      fontSize: 40,
      lineHeight: "52px",
      color,
    },
    indicator: {
      height: "3px",
      backgroundColor: props.backgroundColor,
    },
    scrollButtons: {
      borderBottom: "0.5px solid #CBD5E0 !important",
    },
    nested: {
      paddingLeft: 10,
    },
    collapse: {
      backgroundColor: props.backgroundColor,
      color: "#FFFFFF",
    },
    mobileLabel: {
      fontWeight: "normal",
      fontSize: 10,
      color,
      lineHeight: "135%",
    },
    mobileToPay: {
      fontWeight: 300,
      fontSize: 20,
      color,
    },
    rootList: {
      borderRadius: "8px 8px 8px 8px",
    },
    paddingList: {
      paddingBottom: "0px",
    },
    mobileBox: {
      backgroundColor: "#FFF",
      color: "#293036",
      paddingLeft: 16,
      paddingTop: 28,
      borderRadius: "0px 0px 8px 8px",
    },
    resume: {
      fontWeight: 300,
      fontSize: 14,
      color: "#FFFFFF",
      lineHeight: "141%",
    },
    tabsText: {
      textTransform: "none",
    },
    contrastLabel: {
      color,
    },
  }))();
};
