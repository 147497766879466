import React from "react";
import { PseFormCard } from "./Pse/PseFormCard";
import { SafetyPayFormCard } from "./SafetyPay/SafetyPayFormCard";
import { SpeiFormCard } from "./Spei/SpeiFormCard";
import { EtpayFormCard } from "./Etpay/EtpayFormCard";
import { IFormCardProps } from "../commons/FormCard/FormCard";
import { ProcessorsEnum } from "../../shared/infrastructure/ProcessorEnum";

export const getTransferFormComponent = (
  processorName: string
): React.FC<IFormCardProps> => {
  switch (processorName) {
    case ProcessorsEnum.ETPAY:
      return EtpayFormCard;
    case ProcessorsEnum.ACCENDO:
      return SpeiFormCard;
    case ProcessorsEnum.PSE:
      return PseFormCard;
    default:
      return SafetyPayFormCard;
  }
};
