import MaskedInput, { maskArray } from "react-text-mask";
import React from "react";

interface TextMaskCustomProps {
  inputRef?: (ref: HTMLInputElement | null) => void;
  mask: maskArray;
}

export function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef!(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}
