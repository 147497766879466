import { useState } from "react";

export interface useToggleLanguage {
  displayModal: boolean;
  handler: {
    handleOpenLanguageModal: () => void;
    handleCloseLanguageModal: () => void;
  };
}
export const useToggleLanguageState = (): useToggleLanguage => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const handleOpenLanguageModal = () => {
    setDisplayModal(true);
  };
  const handleCloseLanguageModal = () => {
    setDisplayModal(false);
  };
  return {
    displayModal,
    handler: {
      handleOpenLanguageModal,
      handleCloseLanguageModal,
    },
  };
};
