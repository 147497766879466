import React from "react";
import { Grid, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logoKushki from "../../assets/LogoKushki.svg";
import { useStateApp } from "../../state/useStateApp";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      maxWidth: theme.breakpoints.width("lg"),
      padding: theme.spacing(2, 2),
      margin: "auto",
    },
  })
);

const Header = () => {
  const classes = useStyles();
  const { webcheckout } = useStateApp();

  return (
    <div>
      <header className={classes.header}>
        <Grid container justify={"space-between"} alignItems={"center"}>
          <Grid item>
            {webcheckout?.configuration?.image !== undefined && (
              <img
                width={"100%"}
                height={"100%"}
                style={{ maxHeight: 100 }}
                src={webcheckout.configuration.image}
                alt={"Logo image"}
              />
            )}
          </Grid>
          <Grid item>
            <img width={134} height={32} src={logoKushki} alt={logoKushki} />
          </Grid>
        </Grid>
      </header>
    </div>
  );
};

export default Header;
