import React from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { ArrowLeft } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";

const useStyles = makeStyles(() => ({
  buttonBack: {
    marginBottom: "8px",
    border: " 0.5px solid #CBD5E0",
    borderRadius: "4px",
    padding: "2px 14px",
  },
  arrow: {
    marginRight: "11px",
  },
}));
export interface BackButtonPropsState {
  redirectUrl?: string;
}

export type backButtonProps = BackButtonPropsState;

export const BackButton: React.FC<backButtonProps> = (
  props: backButtonProps
) => {
  const classes = useStyles();
  return (
    <Box
      component={Button}
      className={classes.buttonBack}
      onClick={() => window.location.replace(props.redirectUrl!)}
    >
      <ArrowLeft className={classes.arrow} size={18} />
      <Typography>
        <T id="backButton" />
      </Typography>
    </Box>
  );
};
