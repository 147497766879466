import { createStyles, makeStyles } from "@material-ui/core/styles";

const textColor = "#484E51";

export const useStyles = makeStyles(() =>
  createStyles({
    pciBoxMobil: {
      display: "flex",
      paddingBottom: "16px",
      textAlign: "center",
      color: "#293036",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "16px",
      font: "IBM Plex Sans",
      backgroundColor: "white",
      width: "100%",
      flexDirection: "column",
    },
    footer: {
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      borderBottom: "3px solid #F7FAFC",
      right: 0,
    },
    poweredContainer: {
      marginTop: "25px",
      fontSize: "9.24px",
      textTransform: "uppercase",
    },
    poweredTitle: {
      marginRight: "7px",
      fontStyle: "normal",
      color: "#184661",
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    infoBox: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "90%",
    },
    infoBoxMobile: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    infoLabel: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: textColor,
      margin: "0px 6.16573px",
      paddingLeft: 40,
      display: "flex",
      alignItems: "flex-end",
      paddingBottom: 8,
    },
    pciLabel: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: textColor,
      display: "flex",
      alignItems: "flex-end",
    },
    infoLabelMobil: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: textColor,
      margin: "0px 6.16573px",
      alignItems: "flex-end",
      paddingBottom: 8,
    },
    readMoreMobile: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: textColor,
      margin: "0px 6.16573px",
      alignItems: "flex-end",
      paddingBottom: 8,
      textDecoration: "underline",
    },
    languageLabel: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      color: "#293036",
      paddingLeft: 10,
      paddingRight: 15,
    },
    footerMobil: {
      bottom: 0,
      width: "100%",
    },
    boxContact: {
      background: "#F7FAFC",
      borderRadius: "16px 0px 0px 0px",
      boxShadow:
        "1.23315px 1.23315px 3.69944px rgba(50, 50, 93, 0.12), 0.616573px 0.616573px 1.84972px rgba(0, 0, 0, 0.08)",
      minHeight: 64,
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
      width: "340px",
    },
    fontFooter: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "150%",
      letterSpacing: "0.01em",
      color: "#293036",
      margin: "4px 0px",
      paddingLeft: 10,
      paddingRight: 10,
    },
    pciIcon: {
      position: "relative",
      paddingLeft: "5px",
      paddingRight: "10px",
    },
    readMore: {
      position: "relative",
      paddingLeft: "5px",
      paddingRight: "10px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      color: textColor,
      textDecoration: "underline",
    },
  })
);
