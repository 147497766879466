import { makeStyles } from "@material-ui/core/styles";
import { StringUtils } from "../../shared/utils/StringUtils";

export const useStyle = (color: string) => {
  const [fontColor, lightenColor]: string[] = StringUtils.getContrastYIQ(color);

  return makeStyles({
    headerTitle: {
      textAlign: "center",
      backgroundColor: "#F7FAFC",
    },
    cardContentRoot: {
      padding: 0,
      justifyContent: "center",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "52px",
      color: "#023365",
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 40,
    },
    contentContainer: {
      padding: 24,
      paddingTop: 100,
      textAlign: "center",
    },
    description: {
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "150%",
      color: "#293036",
    },
    continueLabel: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "140%",
      margin: "5px",
    },
    retryLabel: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: " 140%",
    },
    button: {
      backgroundColor: color,
      padding: "0.47rem 1.94rem",
      color: fontColor,
      "&:hover": {
        backgroundColor: lightenColor,
        color: fontColor,
      },
    },
  })();
};
