import React, { FC } from "react";
import { Typography, Modal, Box, CircularProgress } from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../store/reducer";
import useStyle from "./ModalLoading.styles";
import { defaultTo } from "lodash";

export const ModalLoading: FC = () => {
  const classes = useStyle();
  const modalLoading: boolean | undefined = useSelector(
    (state: IWebcheckoutState) => state.modalLoading
  );

  return (
    <>
      <Modal open={defaultTo(modalLoading, false)} className={classes.modal}>
        <Box className={classes.boxMessage}>
          <Box className={classes.circularProgress}>
            <CircularProgress size={"60px"} style={{ color: "#023365" }} />
          </Box>
          <Box>
            <Typography variant={"h5"} className={classes.titleBox}>
              <T id={"modalLoading.titlePrincipal"} />
            </Typography>
            <Typography variant={"h5"} className={classes.titleBox}>
              <T id={"modalLoading.titleSecondary"} />
            </Typography>
            <Typography color="inherit" variant={"caption"}>
              <T id={"modalLoading.subTitle"} />
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
