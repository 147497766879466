import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 24px",
  },
  boxMessage: {
    width: "570px",
    display: "flex",
    background: "white",
    margin: "0px auto",
    padding: "24px 24px",
    boxShadow:
      "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  circularProgress: {
    padding: "0px 6px",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
  },
  titleBox: {
    color: theme.typography.h1.color,
    fontWeight: 500,
  },
}));

export default useStyle;
