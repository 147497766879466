import React, { FC } from "react";
import { CardContent, Tab, Tabs } from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import ProductTab from "../../Tabs/ProductTab";
import AmountDetailTab from "../../Tabs/AmountDetailTab";
import { IPurchaseDetail } from "../PurchaseDetail.interfaces";
import { usePurchasDetailStyles } from "../PurchaseDetail.styles";
import InformationTab from "../../Tabs/InformationTab";
import { isEmpty } from "lodash";
import DescriptionTab from "../../Tabs/DescriptionTab";
import { WebcheckoutTypeEnum } from "../../../shared/infrastructure/WebcheckoutTypeEnum";

const tabsProps = (index: any) => {
  return {
    id: `scrollable-prevent-tab-${index}`,
  };
};

export const ProductsDetailTabs: FC<IPurchaseDetail> = (
  props: IPurchaseDetail
) => {
  const classes = usePurchasDetailStyles(props);
  const products = props.products.filter((element) => {
    if (isEmpty(element)) return false;
    return true;
  });
  const isExpressCheckout =
    props.webcheckoutType === WebcheckoutTypeEnum.EXPRESS_CHECKOUT;
  const hasPaymentDescription = !isEmpty(props.paymentDescription);

  const renderTab = (
    conditionToRender: boolean,
    text: string,
    tabsPropIndex: any
  ): any => {
    return (
      conditionToRender && (
        <Tab
          className={classes.tabsText}
          label={<T id={text} />}
          {...tabsProps(tabsPropIndex)}
        />
      )
    );
  };

  return (
    <>
      <Tabs
        value={props.selectTab}
        onChange={props.handleChangeTab}
        centered={true}
        variant={"fullWidth"}
        classes={{
          flexContainer: classes.scrollButtons,
          indicator: classes.indicator,
        }}
      >
        {renderTab(!isExpressCheckout, "purchaseDetail.item", 0)}
        {renderTab(
          props.metadata !== undefined && props.metadata.length > 0,
          "purchaseDetail.item",
          1
        )}
        {renderTab(!isExpressCheckout, "purchaseDetail.breakdown", 2)}
        {renderTab(
          isExpressCheckout && hasPaymentDescription,
          "purchaseDetail.paymentDescription",
          0
        )}
        {renderTab(isExpressCheckout, "purchaseDetail.breakdown", 1)}
      </Tabs>

      <CardContent style={{ marginTop: 0 }}>
        {!isExpressCheckout && (
          <>
            <ProductTab
              selectTab={props.selectTab}
              toSelect={0}
              products={products}
              currency={props.currency}
            />
            <InformationTab
              selectTab={props.selectTab}
              toSelect={props.metadata?.length === 0 ? 0 : 1}
              metadata={props.metadata}
            />
            <AmountDetailTab
              selectTab={props.selectTab}
              toSelect={props.metadata?.length === 0 ? 1 : 2}
              currency={props.currency}
              amount={props.amount}
              subtotal={props.subtotal}
              iva={props.iva}
              commissions={props.commissions}
            />
          </>
        )}
        {isExpressCheckout && (
          <>
            <DescriptionTab
              selectTab={props.selectTab}
              toSelect={isExpressCheckout && hasPaymentDescription ? 0 : 1}
              paymentDescription={props.paymentDescription}
            />
            <AmountDetailTab
              selectTab={props.selectTab}
              toSelect={isExpressCheckout && hasPaymentDescription ? 1 : 0}
              currency={props.currency}
              amount={props.amount}
              subtotal={props.subtotal}
              iva={props.iva}
              commissions={props.commissions}
            />
          </>
        )}
      </CardContent>
    </>
  );
};
