import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import Failed from "../../../assets/declinado.svg";
import Alert from "../../../assets/Alert.svg";
import { makeStyles } from "@material-ui/core/styles";

export interface ICardMessage {
  img?: any;
  isMobile?: boolean;
  title?: any;
  subtitle?: any;
  message?: any;
  firstButton?: any;
  secondButton?: string;
  onClickFirstButton?: () => void;
}

const CardMessage = (props: ICardMessage) => {
  const useStyles = makeStyles({
    title: {
      fontWeight: 500,
      lineHeight: "38.4px",
      color: "#0E304B",
      fontSize: props.isMobile ? "24px" : "32px",
      marginBottom: "20px",
    },
    subtitle: {
      fontWeight: 500,
      lineHeight: "16px",
      color: "#293036",
    },
    message: {
      color: "#46525C",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "150%",
      fontSize: "16px",
      marginBottom: "20px",
    },
    button: {
      textTransform: "none",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "140%",
      marginBottom: "20px",
    },
  });
  const classes = useStyles();
  return (
    <>
      <Box display={"flex"} justifyContent={"center"} pt={10}>
        <Card style={{ width: "40%" }}>
          <CardContent>
            <Box py={10}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    {props.img === "Failed" ? (
                      <img src={Failed} alt={Failed} />
                    ) : (
                      <img src={Alert} alt={Alert} />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={!props.isMobile ? "h1" : "h3"}
                    className={classes.title}
                    align={"center"}
                  >
                    {props.title}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    className={classes.message}
                    align={"center"}
                  >
                    {props.subtitle}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    className={classes.message}
                    align={"center"}
                  >
                    {props.message}
                  </Typography>
                  <Typography align="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={props.onClickFirstButton}
                    >
                      {props.firstButton}
                    </Button>
                  </Typography>
                  {props.secondButton ? (
                    <Typography align="center">
                      <Button className={classes.button} href="/">
                        {props.secondButton}
                      </Button>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default CardMessage;
