import { DataVerificationFailedProps } from "../DataVerificationFailed";

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SmartLink } from "../../../../types/webcheckout";

export interface useDataVerificationFailed {
  select: {
    handleGoToStore: () => void;
  };
}
export const useDataVerificationFailedState = (
  props: DataVerificationFailedProps
): useDataVerificationFailed => {
  const history = useHistory<SmartLink>();
  const redirectUrl = history.location.state.redirectURL;
  useEffect(() => {
    props.deleteUserSession("diana@gmail.com");
  }, []);

  const handleGoToStore = () => {
    window.location.replace(redirectUrl);
  };
  return {
    select: {
      handleGoToStore,
    },
  };
};
