import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  webpayImg: {
    width: theme.spacing(7),
  },
  img: {
    width: theme.spacing(4),
  },
  imgRedCompra: {
    height: theme.spacing(3.2),
    maxWidth: theme.spacing(10),
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: theme.spacing(10),
    },
  },
}));
