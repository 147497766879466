import React from "react";
import { FormCard, IFormCardProps } from "../../commons/FormCard/FormCard";
import transfer from "../../../assets/logos/transfer.svg";
import speiLogo from "../../../assets/images/speiLogo.svg";
import { Grid, Typography } from "@material-ui/core";
import { EmailInput } from "../../commons/FormCardChildren/FormCardChildren";
import { getValuePayButton } from "../../CardFormCard/state/useCardFormCardState";

export const SpeiFormCard: React.FC<IFormCardProps> = (
  props: IFormCardProps
) => {
  return (
    <React.Fragment>
      <FormCard
        {...props}
        blurryOnLoad
        headerProps={{
          handleChangePaymentMethod: props.handleChangePaymentMethod,
          isMobile: props.isMobile,
          title: "Transferencia",
          titleLogo: transfer,
          logos: [
            {
              image: speiLogo,
              value: speiLogo,
            },
          ],
        }}
        showAcceptTerms
        usePayButton={false}
        submitLabel={getValuePayButton(
          props?.showAmount,
          props.totalAmount,
          props.currency
        )}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                lineHeight: "136%",
                fontSize: "13",
                color: "#293036",
              }}
            >
              Ingresa tu e-mail y haz clic en “Generar orden de pago” para
              recibir los datos necesarios para realizar tu transferencia.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EmailInput form={props.form} />
          </Grid>
        </Grid>
      </FormCard>
    </React.Fragment>
  );
};
