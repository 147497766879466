import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as ErrorIcon } from "../../assets/images/error.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";
import theme from "../../theme";

const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    lineHeight: "120%",
    alignItems: "center",
    color: "#293036",
  },
  description: {
    lineHeight: "150%",
    textAlign: "center",
    color: "#293036",
    margin: "0px 20px",
  },
  btn: {
    border: "0.5px solid #CBD5E0",
    boxSizing: "border-box",
    borderRadius: "4px",
    marginBottom: 50,
  },
  btnLabel: {
    fontWeight: 500,
    lineHeight: "140%",
    color: "#023365",
    margin: "10px 50px",
  },
}));

export interface IError404 {
  isMobile: boolean;
}

export const Error404 = (props: IError404) => {
  const classes = useStyle();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "65%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box maxWidth={isMobile ? "90%" : "50%"}>
        {!isMobile ? (
          <Card>
            <CardContent>
              <Box style={{ left: "50%", top: "50%" }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <ErrorIcon
                        style={{
                          marginTop: 50,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Typography variant={"h1"} className={classes.title}>
                      Oops!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      variant={"h6"}
                      className={classes.description}
                      style={{
                        marginTop: 5,
                        paddingRight: "60px",
                        textAlign: "left",
                      }}
                    >
                      <T id="pageNotFound" />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Box style={{ left: "50%", top: "50%" }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <ErrorIcon
                        style={{
                          marginTop: 50,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Typography variant={"h1"} className={classes.title}>
                      Oops!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant={"h6"}
                      className={classes.description}
                      style={{
                        marginTop: 5,
                        textAlign: "center",
                      }}
                    >
                      <T id="pageNotFound" />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
    </div>
  );
};

export default Error404;
