import React from "react";
import { Box } from "@material-ui/core";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import { useStyles } from "./BoxLogos.styles";

export interface BoxLogosProps {
  logos?: { image: string; value: string }[];
}

export const BoxLogos: React.FC<BoxLogosProps> = ({ logos }: BoxLogosProps) => {
  const classes = useStyles();

  return (
    <ConditionalRender condition={Boolean(logos)}>
      {logos?.map((logo: { image: string; value: string }) => (
        <Box
          component="li"
          key={`${logo.value}_imageLogobox`}
          pr={1}
          display="flex"
        >
          <img
            width={32}
            height={32}
            className={
              logo.value === "redCompra"
                ? classes.imgRedCompra
                : logo.value === "webpay"
                ? classes.webpayImg
                : classes.img
            }
            key={`${logo.value}_imageLogoImg`}
            src={logo.image}
            alt={logo.value}
          />
        </Box>
      ))}
    </ConditionalRender>
  );
};
