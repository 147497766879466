import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import ConditionalRender from "../../ConditionalRender/ConditionalRender";
import { PaymentMethod } from "../../PaymentMethod/PaymentMethod";
import {
  BrandsCardAsync,
  WebpayCardAsync,
} from "../../../shared/constants/BrandsCardAsync";
import useStyles from "../ListPaymentMethods.styles";
import { ListPaymentVersionsProps } from "../ListPaymentMethods.interface";

const DesktopView = ({
  handleSelectMethod,
  logos,
  methodsActive,
  paymentSubscriptions,
  showCardDynamic,
  labelCreditCardPaymentMethod,
}: ListPaymentVersionsProps) => {
  const thereIsSavedPaymentData =
    paymentSubscriptions.savedCardPaymentData &&
    paymentSubscriptions.savedCardPaymentData.length > 0;

  const styles = useStyles();
  return (
    <Card variant="outlined">
      <CardContent className={styles.cardContent}>
        <Box pb={3}>
          <Typography className={styles.title} color={"primary"}>
            <T id="listPaymentMethods.titlePayMethods" />
          </Typography>
        </Box>
        <Grid container>
          <ConditionalRender condition={thereIsSavedPaymentData}>
            <Grid item xs={12}>
              <Typography color={"textSecondary"}>
                <T id="listPaymentMethods.titleOtherPay" />
              </Typography>
            </Grid>
          </ConditionalRender>
          <Grid
            item
            xs={12}
            hidden={
              !methodsActive.includes("credit-card") ||
              methodsActive.includes("card-dynamic")
            }
            className={styles.gridItem}
          >
            <PaymentMethod
              title={<T id={labelCreditCardPaymentMethod} />}
              logos={logos!.card}
              handleSelectMethod={handleSelectMethod}
              value={"card"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            hidden={
              !methodsActive.includes("card-async") ||
              methodsActive.includes("card-dynamic")
            }
            className={styles.gridItem}
          >
            <PaymentMethod
              title={<T id="listPaymentMethods.titleDebit" />}
              logos={WebpayCardAsync.logos}
              handleSelectMethod={handleSelectMethod}
              value={"cardAsync"}
            />
          </Grid>
          <ConditionalRender condition={showCardDynamic}>
            <Grid item xs={12} hidden={false} className={styles.gridItem}>
              <PaymentMethod
                title={<T id="listPaymentMethods.titleCardDynamic" />}
                handleSelectMethod={handleSelectMethod}
                value={"card-dynamic"}
                logos={[...logos!.card, ...BrandsCardAsync.logos]}
              />
            </Grid>
          </ConditionalRender>
          <Grid
            item
            xs={12}
            hidden={!methodsActive.includes("transfer")}
            className={styles.gridItem}
          >
            <PaymentMethod
              title={<T id="listPaymentMethods.titleTransfer" />}
              handleSelectMethod={handleSelectMethod}
              value={"transfer"}
              logos={logos!.transfer}
            />
          </Grid>
          <Grid
            item
            xs={12}
            hidden={!methodsActive.includes("cash")}
            className={styles.gridItem}
          >
            <PaymentMethod
              title={<T id="listPaymentMethods.titleCash" />}
              handleSelectMethod={handleSelectMethod}
              value={"cash"}
              logos={[]}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DesktopView;
