import React from "react";
import CardMessage from "../commons/Message/CardMessage";
import { connect } from "react-redux";
import { useDataVerificationFailedState } from "./state/useDataVerificationFailed";
import { useParams } from "react-router-dom";
import { Translate as T } from "react-localize-redux";

export interface IDataVerificationFailed {
  email: string;
}
export interface DataVerificationFailedFunction {
  deleteUserSession: (email: string) => void;
}

export type DataVerificationFailedProps = DataVerificationFailedFunction &
  IDataVerificationFailed;

export const DataVerificationFailed: React.FC<DataVerificationFailedProps> = (
  props: DataVerificationFailedProps
) => {
  const { error } = useParams();
  let img;
  let subtitle;
  let firstButton;
  let message;
  let title;
  const { select } = useDataVerificationFailedState(props);

  if (error === "otp") {
    img = "Failed"; // la imagen puede ser Failed o Alert
    title = <T id="dataVerificationFailed" />;
    subtitle = <T id="dataVerificationFailedMessage" />;
    message = <T id="checkInformationSave" />;
    firstButton = <T id="backStore" />;
  } else if (error === "link") {
    img = "Alert";
    title = "Sesión expirada";
    subtitle = "El enlace ha caducado. ";
    firstButton = "Regresar a la tienda";
  } else if (error === "session") {
    img = "Alert";
    title = "Sesión expirada";
    subtitle = "Tu sesión ha expirado por límite de tiempo.";
    message = "Por favor, ingresa nuevamente";
    firstButton = "Regresar a la tienda";
  } else if (error === "email") {
    img = "Failed";
    title = <T id="dataVerificationFailed" />;
    subtitle = <T id="dataVerificationFailedMessage" />;
    message = <T id="dataVerificationFMessage" />;
    firstButton = <T id="backStore" />;
  }

  return (
    <>
      <CardMessage
        img={img}
        title={title}
        subtitle={subtitle}
        message={message}
        firstButton={firstButton}
        onClickFirstButton={select.handleGoToStore}
      />
    </>
  );
};
export default connect(null)(DataVerificationFailed);
