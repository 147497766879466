import { CardBrandsEnum } from "@kushki/js/lib/infrastructure/CardBrandsEnum";
import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export interface BrandAnimationProps {
  brand: CardBrandsEnum;
  isMobile: boolean;
}

const useStyles = makeStyles((theme) => ({
  containerVisaAnimation: {
    backdropFilter: "blur(9px)",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    zIndex: 15,
  },
  containerMcAnimation: {
    backgroundColor: "white",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    zIndex: 15,
  },
  mcBoxAnimation: {
    width: "50%",
  },
  visaBoxAnimationMobile: {
    height: "100%",
    width: "100%",
  },
  mcBoxAnimationMobile: {
    backgroundColor: "white",
    height: "100%",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 999,
  },
}));

export const BrandAnimation = (props: BrandAnimationProps): JSX.Element => {
  const classes = useStyles();

  const getBrandBox = (): JSX.Element => {
    switch (props.brand) {
      case CardBrandsEnum.VISA:
        return (
          <Box
            id="visa-sensory-branding"
            className={props.isMobile ? classes.visaBoxAnimationMobile : ""}
          />
        );
      case CardBrandsEnum.MASTERCARD:
        return (
          <Box
            id="mc-container"
            className={
              props.isMobile
                ? classes.mcBoxAnimationMobile
                : classes.mcBoxAnimation
            }
          />
        );
    }
  };

  const getBrandContainerStyle = (): string => {
    switch (props.brand) {
      case CardBrandsEnum.VISA:
        return classes.containerVisaAnimation;
      case CardBrandsEnum.MASTERCARD:
        return classes.containerMcAnimation;
    }
  };

  const getBrandContainer = (): JSX.Element =>
    props.isMobile ? (
      getBrandBox()
    ) : (
      <div className={getBrandContainerStyle()}>{getBrandBox()}</div>
    );

  return getBrandContainer();
};
