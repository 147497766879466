import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { Clock } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";

export interface TimerAttributes {
  minute?: string;
  second?: string;
  shows?: boolean;
}

const darkMidnightBlueColor = "#023365";
const useStyles = makeStyles(() => ({
  timeNormal: {
    color: darkMidnightBlueColor,
    fontFamily: "IBM Plex Sans,sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  background: {
    backgroundColor: "#BFDFFA",
    marginTop: "25px",
  },
}));

export const RedirectTimer: React.FC<TimerAttributes> = (
  props: TimerAttributes
) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {props.shows && (
        <Box
          display={"flex"}
          component={Paper}
          maxWidth={"400px"}
          width={{ xs: "100%" }}
          minWidth={{ xs: "100%", sm: "400px" }}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"nowrap"}
          className={classes.background}
        >
          <Box p={1}>
            <Clock size={18} color={darkMidnightBlueColor} />
          </Box>
          <Box p={1}>
            <Typography className={classes.timeNormal}>
              <T id="redirectTimerMessage.start" />
              <span>{props.minute}</span>
              {" : "}
              <span>{props.second}</span>
              <T id="redirectTimerMessage.end" />
            </Typography>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default RedirectTimer;
