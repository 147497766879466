import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import {
  LocalizeContextProps,
  Translate as T,
  withLocalize,
} from "react-localize-redux";
import esFlag from "../../../assets/images/sm-flag-es.png";
import enFlag from "../../../assets/images/sm-flag-en.png";
import brFlag from "../../../assets/images/sm-flag-br.png";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    language: {
      paddingRight: "10px",
    },
  })
);

export type TSelectLanguageProps = LocalizeContextProps;
export const SelectLanguage = (props: TSelectLanguageProps) => {
  const classes = useStyles();

  return (
    <div>
      <Select id="languages" value={props.activeLanguage?.code || "es"}>
        <MenuItem
          id="es"
          value="es"
          onClick={() => props.setActiveLanguage("es")}
        >
          <img
            src={esFlag}
            width={26}
            height={20}
            className={classes.language}
            alt={esFlag}
          />
          <T id="languages.es" />
        </MenuItem>
        <MenuItem
          id="en"
          value="en"
          onClick={() => props.setActiveLanguage("en")}
        >
          <img
            src={enFlag}
            width={26}
            height={20}
            className={classes.language}
            alt={enFlag}
          />
          <T id="languages.en" />
        </MenuItem>
        <MenuItem
          id="pt"
          value="pt"
          onClick={() => props.setActiveLanguage("pt")}
        >
          <img
            src={brFlag}
            width={26}
            height={20}
            className={classes.language}
            alt={brFlag}
          />
          <T id="languages.pt" />
        </MenuItem>
      </Select>
    </div>
  );
};
export default withLocalize<LocalizeContextProps>(SelectLanguage);
