import React from "react";
import { isEmpty } from "lodash";
import { ListPaymentMethodsProps } from "./ListPaymentMethods.interface";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import DesktopView from "./Variants/DesktopView";
import MobileView from "./Variants/MobileView";
import { CircularProgress, Grid } from "@material-ui/core";
import useStyles from "./ListPaymentMethods.styles";

export const ListPaymentMethods = (props: ListPaymentMethodsProps) => {
  const styles = useStyles();
  const { isMobile, methodsActive, showOptionMethod } = props;
  const isDesktop = !isMobile;
  const showCardDynamic = methodsActive.includes("card-dynamic");
  const showPaymentMethods = !isEmpty(methodsActive) && showOptionMethod;

  if (props.labelCreditCardPaymentMethod === "")
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={styles.listPaymentMethodsLoader}
      >
        <CircularProgress />
      </Grid>
    );

  return (
    <ConditionalRender condition={showPaymentMethods}>
      <ConditionalRender condition={isDesktop}>
        <DesktopView {...props} showCardDynamic={showCardDynamic} />
      </ConditionalRender>
      <ConditionalRender condition={isMobile}>
        <MobileView {...props} showCardDynamic={showCardDynamic} />
      </ConditionalRender>
    </ConditionalRender>
  );
};
