import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import alert from "../../assets/Alert.svg";
import { Translate as T } from "react-localize-redux";
import { setGAEvent } from "../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../shared/constants/GAConstants";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../store/reducer";
import { get } from "lodash";
import { WebcheckoutTypeEnum } from "../../shared/infrastructure/WebcheckoutTypeEnum";

export const SessionExpiredIndex: React.FC = () => {
  useEffect(() => {
    setGAEvent(
      GA_CONSTANTS.expiredPage.category,
      GA_CONSTANTS.actions.view,
      GA_CONSTANTS.expiredPage.labels.H1
    );
  }, []);
  const redirectUrl = useSelector((state: IWebcheckoutState) =>
    get(state, "webcheckout.redirectURL", "")
  );
  const isExpressCheckout =
    useSelector((state: IWebcheckoutState) =>
      get(state, "webcheckout.kushkiMetadata.source", "")
    ) === WebcheckoutTypeEnum.EXPRESS_CHECKOUT;

  return (
    <React.Fragment>
      <Container>
        <Grid container component={Box} py={7} justify={"center"}>
          <Grid item container xs={12} justify={"center"}>
            <Box width={{ xs: "100%", sm: "70%", md: "50%" }} pb={3}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} component={Box} p={5}>
                    <Grid item xs={12}>
                      <Box display={"flex"} justifyContent={"center"}>
                        <img src={alert} alt={"alert"} />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align={"center"} variant={"h2"}>
                        <T id="sessionExpiredTitle" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align={"center"}>
                        <T id="sessionExpired" />
                      </Typography>
                    </Grid>
                    {!isExpressCheckout && (
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"}>
                          <Button
                            variant={"outlined"}
                            onClick={() => {
                              setGAEvent(
                                GA_CONSTANTS.rateOfReturn.category,
                                GA_CONSTANTS.actions.click,
                                GA_CONSTANTS.rateOfReturn.labels.G1
                              );
                              window.location.replace(redirectUrl);
                            }}
                          >
                            <T id="backStore" />
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default SessionExpiredIndex;
