import React, { RefObject } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { HeaderPayment } from "../HeaderPayment/HeaderPayment";
import { Calendar, CreditCard, Lock, User } from "react-feather";
import card from "../../assets/logos/card.svg";
import { TermsConditions } from "../TermsConditions/TermsConditions";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { SelectOption } from "../../containers/state/useMainState";
import { LoadingCard } from "../LoadingCard/LoadingCard";
import { DeferredOptions } from "../DeferredOptions/DeferredOptions";
import {
  getValuePayButton,
  useCardFormCardState,
} from "./state/useCardFormCardState";
import { IWebcheckoutState } from "../../store/reducer";
import { connect } from "react-redux";
import {
  BillingDetails,
  SmartLink,
  Webcheckout,
} from "../../../types/webcheckout";
import type { MerchantSettingsResponse } from "@kushki/js/lib/types/merchant_settings_response";
import { Translate as T, TranslateFunction } from "react-localize-redux";
import { setGAEvent } from "../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../shared/constants/GAConstants";
import pci from "../../assets/images/pci.svg";
import { get, isEmpty } from "lodash";
import { StringUtils } from "../../shared/utils/StringUtils";
import { ISiftscienceForm } from "./state/interfaces/ISiftScienceForm";
import { TextMaskCustom } from "./commons/TextMaskCustom";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { PayInInstallmentsMessage } from "../../shared/constants/MonthlyPaymentMessage";

export interface CardFormCardStateProps {
  webcheckout?: SmartLink | Webcheckout;
  settings?: MerchantSettingsResponse;
  webcheckoutEmail?: string;
}

export interface CardFormCardProps {
  logos: { image: string; value: string }[];
  paymentMethodForm: string;
  isMobile: boolean;
  handleChangePaymentMethod: () => void;
  handleSaveMethod: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openTerms: boolean;
  handleClose: () => void;
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    handleSubmit: any;
    getValues: (field: string) => any;
    handleOnSubmitForm: () => void;
    control: Control<Record<string, any>>;
    currentValues: {
      months: string;
      monthsOfGrace: string;
      typeDeferred: string;
    };
  };
  color?: string;
  secondaryColor?: string;
  buttonStyle?: string;
  optionsDeferred?: string[];
  handleSetFields: (kind: string, value?: string) => void;
  currency: string;
  totalAmount: number;
  brandCardInput?: string;
  typeCardInput?: string;
  deferredOptions?: {
    hasDeferred?: boolean;
    isDynamic?: boolean;
    typesDeferred?: SelectOption[];
    months?: SelectOption[];
    monthsOfGrace?: SelectOption[];
    isActive?: boolean;
  };
  loading?: boolean;
  translate: TranslateFunction;
  siftscienceForm?: ISiftscienceForm;
  isValidDate: (expDate: string) => boolean;
  isValidCVV: (cvv: string) => boolean;
  billingDetails?: BillingDetails;
}

export type TCardFormCardProps = CardFormCardProps & CardFormCardStateProps;

export const CardFormCard: React.FC<TCardFormCardProps> = (
  props: TCardFormCardProps
) => {
  const getBorderRadious = () => {
    if (props.buttonStyle === "round") {
      return "20px";
    }
    if (props.buttonStyle === "semi") {
      return "12px";
    }
    return "2px";
  };

  const { secondaryColor = "#000000" } = props;
  const [fontColor, lightenColor]: [
    string,
    string
  ] = StringUtils.getContrastYIQ(secondaryColor);

  const useStyles = makeStyles((theme) => ({
    root: {
      "&$checked": {
        color: props.color,
      },
      "& a": {
        color: props.color,
      },
    },
    checked: {},
    link: {
      color: props.color,
      fontSize: "14px",
    },
    infoLabel: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: "#6D7781",
      margin: "0px 6.16573px",
      display: "flex",
      marginTop: "5px",
      alignItems: "flex-end",
      paddingBottom: 8,
    },
    pciIcon: {
      position: "relative",
      paddingLeft: "5px",
      paddingRight: "10px",
    },
    label: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal",
      color: "#293036",
    },
    button: {
      borderRadius: getBorderRadious(),
      backgroundColor: props.secondaryColor,
      color: fontColor,
      "&:hover": {
        backgroundColor: lightenColor,
        color: fontColor,
      },
    },
    poweredContainer: {
      marginTop: "25px",
      fontSize: "9.24px",
      textTransform: "uppercase",
    },
    poweredTitle: {
      marginRight: "7px",
      fontStyle: "normal",
      color: "#184661",
      display: "inline",
    },
    readMore: {
      position: "relative",
      paddingLeft: "5px",
      paddingRight: "10px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      color: "#6D7781",
    },
    spacing: {
      marginBottom: theme.spacing(1),
    },
    placeholder: {
      "& input": {
        color: "#6D7781",
      },
    },
    header: {
      paddingRight: `${theme.spacing(2)}px !important`,
      paddingLeft: `${theme.spacing(2)}px !important`,
      marginTop: theme.spacing(2),
    },
    body: {
      paddingRight: `${theme.spacing(3)}px !important`,
      paddingLeft: `${theme.spacing(3)}px !important`,
    },
    blurry: {
      WebkitFilter: "blur(5px)",
      msFilter: "blur(5px)",
      filter: "blur(5px)",
      userSelect: "none",
    },
  }));
  const classes = useStyles();
  const {
    actions,
    showSiftForm,
    showAmount,
    checkCardTitleLabel,
  } = useCardFormCardState(props);

  return props.paymentMethodForm === "card" ? (
    <React.Fragment>
      <Card
        hidden={props?.siftscienceForm?.open}
        style={{
          position: "relative",
        }}
      >
        <CardContent>
          <form onSubmit={props.form.handleSubmit(actions.handleSubmitForm)}>
            {props.loading && <LoadingCard color={props.color} />}
            <Box
              width={"100%"}
              height={"100%"}
              className={props.loading ? classes.blurry : ""}
            >
              <Grid item className={classes.header}>
                <HeaderPayment
                  title={checkCardTitleLabel()}
                  titleLogo={card}
                  isMobile={props.isMobile}
                  logos={props.logos}
                  handleChangePaymentMethod={props.handleChangePaymentMethod}
                />
              </Grid>

              <Grid item container spacing={2} className={classes.body}>
                <Grid item xs={12} className={classes.spacing}>
                  <TextField
                    onClick={() =>
                      setGAEvent(
                        GA_CONSTANTS.dataForCreditCard.category,
                        GA_CONSTANTS.actions.click,
                        GA_CONSTANTS.dataForCreditCard.labels.A1
                      )
                    }
                    fullWidth
                    variant={"outlined"}
                    placeholder={props.translate("form.name") as string}
                    name={"name"}
                    InputProps={{
                      className: classes.placeholder,
                      startAdornment: (
                        <InputAdornment position="start">
                          <User color={"#6D7781"} size={16} />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={props.form.register({
                      required: <T id="form.fieldRequired" />,
                    })}
                    error={!!props.form.errors.name}
                    helperText={
                      props.form.errors.name
                        ? props.form.errors.name.message
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} className={classes.spacing}>
                  <TextField
                    onClick={() =>
                      setGAEvent(
                        GA_CONSTANTS.dataForCreditCard.category,
                        GA_CONSTANTS.actions.click,
                        GA_CONSTANTS.dataForCreditCard.labels.A2
                      )
                    }
                    fullWidth
                    variant={"outlined"}
                    placeholder={props.translate("form.cardNumber") as string}
                    onChange={() => props.handleSetFields("card")}
                    name={"cardNumber"}
                    InputProps={{
                      className: classes.placeholder,
                      startAdornment: (
                        <InputAdornment position="start">
                          {!props.brandCardInput ? (
                            <CreditCard color={"#6D7781"} size={16} />
                          ) : (
                            <img
                              width={32}
                              height={32}
                              src={props.brandCardInput}
                              alt="cardLogo"
                            />
                          )}
                        </InputAdornment>
                      ),
                      inputComponent: TextMaskCustom as any,
                      inputProps: {
                        mask: [
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                        ],
                      },
                    }}
                    inputRef={props.form.register({
                      required: <T id="form.numberCardInvalid" />,
                      minLength: {
                        value: 17,
                        message: <T id="form.numberCardInvalid" />,
                      },
                      maxLength: {
                        value: 22,
                        message: <T id="form.numberCardInvalid" />,
                      },
                    })}
                    error={!!props.form.errors.cardNumber}
                    helperText={
                      props.form.errors.cardNumber
                        ? props.form.errors.cardNumber.message
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onClick={() =>
                      setGAEvent(
                        GA_CONSTANTS.dataForCreditCard.category,
                        GA_CONSTANTS.actions.click,
                        GA_CONSTANTS.dataForCreditCard.labels.A3
                      )
                    }
                    fullWidth
                    variant={"outlined"}
                    placeholder={props.translate("form.mmyy") as string}
                    name={"dateExp"}
                    InputProps={{
                      className: classes.placeholder,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Calendar color={"#6D7781"} size={16} />
                        </InputAdornment>
                      ),
                      inputComponent: TextMaskCustom as any,
                      inputProps: {
                        mask: [/[0-1]/, /[0-9]/, "/", /[1-9]/, /\d/],
                      },
                    }}
                    inputRef={props.form.register({
                      required: <T id="form.fieldRequired" />,
                      validate: props.isValidDate,
                    })}
                    error={!!props.form.errors.dateExp}
                    helperText={
                      props.form.errors.dateExp
                        ? props.form.errors.dateExp.message
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onClick={() =>
                      setGAEvent(
                        GA_CONSTANTS.dataForCreditCard.category,
                        GA_CONSTANTS.actions.click,
                        GA_CONSTANTS.dataForCreditCard.labels.A4
                      )
                    }
                    fullWidth
                    variant={"outlined"}
                    placeholder={"CVC"}
                    name={"cvv"}
                    type="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color={"#6D7781"} size={16} />
                        </InputAdornment>
                      ),
                      inputComponent: TextMaskCustom as any,
                      inputProps: { mask: [/\d/, /\d/, /\d/, /\d/] },
                    }}
                    inputRef={props.form.register({
                      required: <T id="form.fieldRequired" />,
                      validate: props.isValidCVV,
                    })}
                    error={!!props.form.errors.cvv}
                    helperText={
                      props.form.errors.cvv ? props.form.errors.cvv.message : ""
                    }
                  />
                </Grid>
                {!props.webcheckoutEmail && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant={"outlined"}
                      placeholder={props.translate("form.email") as string}
                      name={"email"}
                      InputProps={{
                        className: classes.placeholder,
                        startAdornment: (
                          <InputAdornment position="start">
                            <User color={"#6D7781"} size={16} />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={props.form.register({
                        required: <T id="form.fieldRequired" />,
                      })}
                      error={props.form.errors.email}
                      helperText={
                        props.form.errors.email
                          ? props.form.errors.email.message
                          : ""
                      }
                    />
                  </Grid>
                )}
                {props.typeCardInput === "credit" && (
                  <DeferredOptions
                    handleSetFields={props.handleSetFields}
                    form={props.form}
                    color={props.color}
                    deferredOptions={props.deferredOptions}
                    showMonthlyPaymentMessage={
                      props.webcheckout?.configuration.showMonthlyPaymentMessage
                    }
                    country={props.webcheckout?.country}
                  />
                )}
                <Grid item xs={12}>
                  <FormControl error={!!props.form.errors.terms}>
                    <FormControlLabel
                      labelPlacement={"end"}
                      label={
                        <Typography className={classes.label}>
                          <T id="agree" />
                          <Link
                            onClick={props.handleClose}
                            className={classes.link}
                          >
                            <T id="terms" />
                          </Link>
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onClick={() =>
                            setGAEvent(
                              GA_CONSTANTS.dataForCreditCard.category,
                              GA_CONSTANTS.actions.click,
                              GA_CONSTANTS.dataForCreditCard.labels.A6
                            )
                          }
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                          color={"primary"}
                        />
                      }
                      name={"terms"}
                      inputRef={props.form.register({
                        required: true,
                      })}
                    />
                    {props.form.errors.terms && (
                      <FormHelperText>
                        <T id="termsConditions" />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Box hidden={props.webcheckout!.country !== "Peru"}>
                  <Typography className={classes.infoLabel}>
                    <img className={classes.pciIcon} src={pci} alt={pci} />
                    <Typography className={classes.readMore}>
                      <T id="footer.messagePay" />
                    </Typography>
                    <Link
                      rel={"noreferrer"}
                      target={"_blank"}
                      href={
                        "https://kushkipagos.com/en/blog/certificacion-pci-pagos-mas-seguros-para-tus-clientes-y-tu-negocio"
                      }
                      style={{
                        color: "#6D7781",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <T id="footer.readMore" />
                      </Typography>
                    </Link>
                  </Typography>
                </Box>

                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button
                      type="submit"
                      variant={"contained"}
                      className={classes.button}
                      onClick={() =>
                        setGAEvent(
                          GA_CONSTANTS.dataForCreditCard.category,
                          GA_CONSTANTS.actions.click,
                          GA_CONSTANTS.dataForCreditCard.labels.A8
                        )
                      }
                    >
                      {!isEmpty(props.webcheckout!.configuration.buttonText) ? (
                        props.webcheckout!.configuration.buttonText
                      ) : (
                        <T id="payButtonWithMount" />
                      )}
                      {getValuePayButton(
                        get(
                          props,
                          "webcheckout.configuration.showAmount",
                          false
                        ) || showAmount,
                        props.totalAmount,
                        props.currency
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </CardContent>
        <TermsConditions
          handleClose={props.handleClose}
          open={props.openTerms}
          isMobile={props.isMobile}
        />
      </Card>
    </React.Fragment>
  ) : null;
};

export const mapStateToProps: (
  state: IWebcheckoutState
) => CardFormCardStateProps = (
  state: IWebcheckoutState
): CardFormCardStateProps => ({
  webcheckout: state.webcheckout,
  settings: state.settings,
  webcheckoutEmail: state.webcheckoutEmail,
});

export default connect(mapStateToProps, null)(CardFormCard);
