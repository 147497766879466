import React from "react";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IAdditionalInfo } from "../../../types/webcheckout";

export interface IInformationTab {
  selectTab: number;
  toSelect: number;
  metadata?: IAdditionalInfo[];
}

export const InformationTab: React.FC<IInformationTab> = (
  props: IInformationTab
) => {
  const useStyles = makeStyles({
    label: {
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "120%",
      color: "#293036",
    },
    labelAmount: {
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "120%",
      color: "#293036",
      textAlign: "right",
    },
  });
  const classes = useStyles();

  return (
    <Box
      hidden={props.selectTab !== props.toSelect}
      id={`scrollable-prevent-tabpanel-${props.toSelect}`}
    >
      <Grid container spacing={5}>
        {props.metadata !== undefined &&
          props.metadata.map((info: IAdditionalInfo, index: number) => (
            <React.Fragment key={index}>
              <Grid item xs={5} className={classes.label}>
                {info.label}:
              </Grid>
              <Grid item xs={7} className={classes.labelAmount}>
                {info.value}
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Box>
  );
};

export default InformationTab;
