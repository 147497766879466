import React from "react";
import { ListPaymentMethods } from "../components/ListPaymentMethods/ListPaymentMethods";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import CardFormCard from "../components/CardFormCard/CardFormCard";
import { useMainState } from "./state/useMainState";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import type { BinInfoResponse } from "@kushki/js/lib/types/bin_info_response";
import { IWebcheckoutState } from "../store/reducer";
import {
  charge,
  chargeAsync,
  getCardAsyncToken,
  getCardBrand,
  getCashToken,
  getCommission,
  getDeferredOptions,
  getListBrandsMerchant,
  getToken,
  getTransferToken,
  getWebcheckout,
  initCardBrandingAnimation,
  IWebcheckoutAction,
  requestTransferSubscriptionToken,
  secureServiceValidation,
  setTimer,
  setWebcheckoutEmail,
  showSaveEmailModal,
  validate3Ds,
} from "../store/actionCreators";
import type { IDeferredResponse } from "@kushki/js/lib/types/remote/deferred_response";
import TimerSession from "../components/TimerSession/TimerSession";
import type { TokenRequest } from "@kushki/js/lib/types/token_request";
import type { ErrorResponse } from "@kushki/js/lib/types/error_response";
import type { TokenResponse } from "@kushki/js/lib/types/remote/token_response";
import type { SecureOtpRequest } from "@kushki/js/lib/types/secure_otp_request";
import type { SecureOtpResponse } from "@kushki/js/lib/types/secure_otp_response";
import { find, get } from "lodash";
import {
  IAdditionalInfo,
  SmartLink,
  Webcheckout,
  ExpressCheckout,
} from "../../types/webcheckout";
import { ChargesRequest } from "../../types/charges_request";
import Details from "../components/PurchaseDetail/PurchaseDetail";
import { BankList } from "../../types/bank_list";
import { getTransferFormComponent } from "../components/TransferFormCard/TransferFormCard";
import { CashFormCard } from "../components/CashFormCard/CashFormCard";
import { SavedCardForm } from "../components/CardFormCard/SavedCardForm/SavedCardForm";
import type { CashTokenRequest } from "@kushki/js/lib/types/cash_token_request";
import type { TransferTokenRequest } from "@kushki/js/lib/types/remote/transfer_token_request";
import { ConfirmMessage } from "../components/commons/ConfirmMessage/ConfirmMessage";
import kpay from "../assets/logos/kpay_logo.svg";
import { CapturePasswordless } from "../components/CapturePasswordless/CapturePasswordless";
import { Translate as Translation } from "react-localize-redux";
import type { MerchantSettingsResponse } from "@kushki/js/lib/types/merchant_settings_response";
import { PaymentDataResponse } from "../../types/payment_data_response";
import type { TokenKPayRequest } from "@kushki/js/lib/types/token_kpay_request";
import { BackButton } from "../components/BackButton/BackButton";
import { useTimerSessionState } from "../components/TimerSession/state/useTimerSessionState";
import type { Validate3DsResponse } from "@kushki/js/lib/types/validate_3ds_response";
import { SiftScienceMerchantResponse } from "../../types/siftscience_merchant_response";
import type { TransferSubscriptionTokenRequest } from "@kushki/js/lib/types/transfer_subscription_token_request";
import { CountryEnum } from "../shared/infrastructure/CountryEnum";
import { SpeiFormCard } from "../components/TransferFormCard/Spei/SpeiFormCard";
import { ICommission } from "../components/PurchaseDetail/PurchaseDetail.interfaces";
import AsyncCardFormCard from "../components/CardFormCard/asyncCardForm/AsyncCardFormCard";
import type { CardAsyncTokenRequest } from "@kushki/js/lib/types/card_async_token_request";
import type { CardAsyncTokenResponse } from "@kushki/js/lib/types/card_async_token_response";
import ConditionalRender from "../components/ConditionalRender/ConditionalRender";
import loadable from "@loadable/component";
import { GetBrandsLogosByMerchantResponse } from "../../types/get_brands_logos_by_merchant_response";
import { makeStyles } from "@material-ui/core/styles";
import { BrandAnimation } from "../components/BrandAnimation/BrandAnimation";
import { CardBrandsEnum } from "@kushki/js/lib/infrastructure/CardBrandsEnum";
import { WebcheckoutTypeEnum } from "../shared/infrastructure/WebcheckoutTypeEnum";
import UserInfo from "../components/UserInfo/UserInfo";

const CardDynamicForm = loadable(
  () => import("../components/CardDynamic/CardDynamic")
);
const CaptureOtp = loadable(
  () => import("../components/CaptureOtp/CaptureOtp")
);
const UserInfoBox = loadable(() => import("../components/UserInfo/UserInfo"));

const CustomContainer = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    paddingBottom: theme.spacing(13),
  },
}))(Container);

const CustomInitialContainer = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 5),
    paddingBottom: theme.spacing(13),
    position: "absolute",
    top: "50%",
  },
}))(Container);

export interface MainPropsState {
  brandsList?: GetBrandsLogosByMerchantResponse[];
  brandCard?: BinInfoResponse;
  optionsFetched?: IDeferredResponse[];
  webcheckout?: SmartLink | Webcheckout | ExpressCheckout;
  loading?: boolean;
  initialLoading?: boolean;
  commission?: ICommission;
  banks?: BankList;
  settings?: MerchantSettingsResponse;
  link?: { link: string };
  savedPaymentMethods?: PaymentDataResponse[];
  webcheckoutEmail?: string;
  metadata?: IAdditionalInfo[];
  userBlocked?: boolean;
  merchantSiftScience?: SiftScienceMerchantResponse;
}

export interface TimerSessionState {
  timer?: { endTime: number };
}

export type TimerSessionProps = TimerSessionState;

export interface MainPropsFunction {
  getWebcheckout: (smartLinkId: string) => void;
  getListBrandsMerchant: (publicMerchantId: string) => void;
  getCardBrand: (publicMerchantId: string, binNumber: string) => void;
  getDeferredOptions: (publicMerchantId: string, binNumber: string) => void;
  getCommission: (
    publicMerchantId: string,
    totalAmount: number,
    currency: string
  ) => void;
  getToken: (
    publicMerchantId: string,
    tokenRequest: TokenRequest | TokenKPayRequest,
    handleToken: (token: TokenResponse | ErrorResponse) => void
  ) => void;
  getCardAsyncToken: (
    publicMerchantId: string,
    tokenRequest: CardAsyncTokenRequest,
    handleToken: (token: CardAsyncTokenResponse | ErrorResponse) => void
  ) => void;
  getTransferToken: (
    publicMerchantId: string,
    tokenRequest: TransferTokenRequest,
    handleTransferToken: (token: TokenResponse | ErrorResponse) => void
  ) => void;
  getCashToken: (
    publicMerchantId: string,
    tokenRequest: CashTokenRequest,
    handleToken: (token: TokenResponse | ErrorResponse) => void
  ) => void;
  secureServiceValidation: (
    publicMerchantId: string,
    secureServiceRequest: SecureOtpRequest,
    handleResponse: (response: SecureOtpResponse | ErrorResponse) => void
  ) => void;
  charge: (
    smartLinkId: string,
    chargeRequest: ChargesRequest,
    onSuccess: (data: any) => void,
    onError: () => void
  ) => void;
  chargeAsync: (
    smartLinkId: string,
    chargeRequest: ChargesRequest,
    onSuccess: (data: any) => void,
    onError: () => void
  ) => void;
  setWebcheckoutEmail: (email: string) => void;
  showSaveEmailModal: () => void;
  setTimer: (endTime: { endTime: number }) => void;
  validate3Ds: (
    publicMerchantId: string,
    tokenRequest: TokenResponse,
    handleValidate3Ds: (
      token: ErrorResponse | Validate3DsResponse,
      data: TokenResponse
    ) => void,
    onError: () => void
  ) => void;
  getTransferSubscriptionToken: (
    publicMerchantId: string,
    request: TransferSubscriptionTokenRequest,
    handleResponse: (token: TokenResponse | ErrorResponse) => void
  ) => void;
  initCardBrandingAnimation: (
    brand: CardBrandsEnum,
    publicMerchantId: string,
    data: object,
    onSuccess: (data: object) => void,
    isMobile: boolean
  ) => void;
}

export interface IMainProps {
  setActiveLanguage: (languageCode: string) => void;
}

export type MainProps = MainPropsState &
  MainPropsFunction &
  TimerSessionProps &
  IMainProps;

export const Main: React.FC<MainProps> = (props: MainProps) => {
  const {
    isMobile,
    disablePay,
    paymentMethod,
    paymentMethodForm,
    handler,
    openTerms,
    form,
    webcheckout,
    buttonText,
    deferredOptions,
    selectTab,
    openCollapse,
    captureOtp,
    userName,
    userEmail,
    transferForm,
    openSavedCardForm,
    cardSavedPaymentData,
    transferSavedPaymentData,
    confirmMessage,
    capturePasswordless,
    cashSavedPaymentData,
    eventType,
    subtotal,
    siftscienceForm,
    savedCardId,
    isValidDate,
    isValidCVV,
    isLoadingProps,
    showCardBrandingAnimation,
    showMonthlyPaymentMessage,
  } = useMainState(props);
  const { minutes, seconds, warning, show } = useTimerSessionState(props);
  const TransferFormCard =
    webcheckout.country === CountryEnum.MXN
      ? SpeiFormCard
      : getTransferFormComponent(webcheckout.transferProcessorName);
  const redirectUrl = webcheckout.redirectURL;
  const cardSelected = find(props.savedPaymentMethods, ["id", savedCardId]);
  const showAmount =
    props.webcheckout !== undefined &&
    Object.entries(props.webcheckout!.configuration).length === 0;
  const isExpressCheckout =
    webcheckout.webcheckoutType === WebcheckoutTypeEnum.EXPRESS_CHECKOUT;
  const useStyles = makeStyles((theme) => ({
    containerPayment: {
      height: "100%",
      position: "relative",
      width: "100%",
    },
  }));
  const classes = useStyles();

  return props.initialLoading || isLoadingProps ? (
    <>
      <CustomInitialContainer>
        <Box textAlign={"center"}>
          <CircularProgress />
        </Box>
      </CustomInitialContainer>
    </>
  ) : (
    <Translation>
      {({ translate }) => (
        <CustomContainer>
          {isMobile && showCardBrandingAnimation && (
            <BrandAnimation
              brand={get(props, "brandCard.brand")}
              isMobile={isMobile}
            />
          )}
          {!isMobile ? (
            <Box pt={5} pb={4}>
              <Grid container>
                <Grid item xs={6}>
                  <UserInfoBox email={userEmail} name={userName} />
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display={"flex"}
                    alignItems={"flex-end"}
                    flexDirection={"column"}
                  >
                    {!isExpressCheckout && (
                      <BackButton redirectUrl={redirectUrl} />
                    )}
                    <TimerSession
                      minute={minutes}
                      second={seconds}
                      shows={show}
                      warn={warning}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box pt={5} pb={4}>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"flex-end"}
                  flexDirection={"column"}
                  width={"100%"}
                >
                  {!isExpressCheckout && (
                    <BackButton redirectUrl={redirectUrl} />
                  )}
                  <TimerSession
                    minute={minutes}
                    second={seconds}
                    shows={show}
                    warn={warning}
                  />
                </Box>
              </Grid>
            </Box>
          )}
          <Grid container spacing={5}>
            {isMobile && (
              <Box p={2}>
                <Grid item xs={12}>
                  <UserInfoBox email={userEmail} name={userName} />
                </Grid>
              </Box>
            )}
            <Grid item xs={12} md={5}>
              {webcheckout.totalAmount !== 0 && (
                <Details
                  isMobile={isMobile}
                  handleOpenCollapse={handler.handleOpenCollapse}
                  openCollapse={openCollapse}
                  selectTab={selectTab}
                  handleChangeTab={handler.handleChangeTab}
                  backgroundColor={webcheckout.color}
                  amount={webcheckout.totalAmount}
                  currency={webcheckout.paymentConfig!.amount.currency}
                  subtotal={subtotal}
                  iva={webcheckout.paymentConfig!.amount.iva}
                  products={webcheckout.products!}
                  metadata={webcheckout?.metadata}
                  commissions={webcheckout.commission}
                  webcheckoutType={webcheckout.webcheckoutType}
                  paymentDescription={webcheckout.paymentDescription}
                />
              )}
            </Grid>
            <Grid item xs={12} md={7} hidden={!!capturePasswordless?.show}>
              <Box className={classes.containerPayment}>
                {!isMobile && showCardBrandingAnimation && (
                  <BrandAnimation
                    brand={get(props, "brandCard.brand")}
                    isMobile={isMobile}
                  />
                )}
                <ListPaymentMethods
                  handleSelectMethod={paymentMethod.handleSelectMethod}
                  isMobile={isMobile}
                  showOptionMethod={paymentMethod.showOptionMethod}
                  logos={paymentMethod.logos}
                  methodsActive={paymentMethod.methodsActive}
                  paymentSubscriptions={paymentMethod.paymentSubscriptions}
                  handleDeletePayMethod={paymentMethod.handleDeletePayMethod}
                  handlePaySavedMethod={paymentMethod.handlePaySavedMethod}
                  handleCardSavedSelected={
                    paymentMethod.handleCardSavedSelected
                  }
                  labelCreditCardPaymentMethod={
                    paymentMethod.labelCreditCardPaymentMethod
                  }
                />

                {captureOtp?.showOTP ? (
                  <CaptureOtp
                    {...captureOtp}
                    openOtpError={captureOtp!.openOtpError}
                    loading={props.loading}
                    smartLink={props.webcheckout}
                  />
                ) : (
                  <CardFormCard
                    form={form}
                    isMobile={isMobile}
                    paymentMethodForm={paymentMethodForm}
                    logos={webcheckout.logos!.cardBrands}
                    handleChangePaymentMethod={
                      handler.handleChangePaymentMethod
                    }
                    openTerms={openTerms}
                    handleClose={handler.handleOpenTermsConditions}
                    color={webcheckout.color}
                    secondaryColor={webcheckout.secondaryColor}
                    buttonStyle={webcheckout.buttonStyle}
                    handleSetFields={handler.handleSetFields}
                    totalAmount={webcheckout.totalAmount}
                    brandCardInput={webcheckout.brandCardInput}
                    typeCardInput={webcheckout.cardType}
                    deferredOptions={deferredOptions}
                    currency={webcheckout.paymentConfig!.amount.currency}
                    loading={props.loading}
                    handleSaveMethod={handler.handleSaveMethod}
                    translate={translate}
                    siftscienceForm={siftscienceForm}
                    isValidDate={isValidDate}
                    isValidCVV={isValidCVV}
                    billingDetails={props.webcheckout!.billingDetails}
                  />
                )}
                <ConditionalRender
                  condition={paymentMethodForm === "card-dynamic"}
                >
                  {captureOtp?.showOTP ? (
                    <CaptureOtp
                      {...captureOtp}
                      openOtpError={captureOtp!.openOtpError}
                      loading={props.loading}
                      smartLink={props.webcheckout}
                    />
                  ) : (
                    <>
                      <CardDynamicForm
                        form={form}
                        brandCardInput={webcheckout.brandCardInput}
                        handleSetFields={handler.handleSetFields}
                        handleChangePaymentMethod={
                          handler.handleChangePaymentMethod
                        }
                        translate={translate}
                        isValidDate={isValidDate}
                        isValidCVV={isValidCVV}
                        handleClose={handler.handleOpenTermsConditions}
                        isMobile={isMobile}
                        openTerms={openTerms}
                        totalAmount={webcheckout.totalAmount}
                        currency={webcheckout.paymentConfig!.amount.currency}
                        typeCardInput={webcheckout.cardType}
                        deferredOptions={deferredOptions}
                        siftscienceForm={siftscienceForm}
                        color={webcheckout.color}
                        loading={props.loading}
                        secondaryColor={webcheckout.secondaryColor}
                        logos={webcheckout.logos!.cardBrands}
                        billingDetails={props.webcheckout!.billingDetails}
                      />
                    </>
                  )}
                </ConditionalRender>
                <TransferFormCard
                  buttonText={buttonText}
                  handleSaveMethod={handler.handleSaveMethod}
                  handleAcceptTerms={handler.handleAcceptTerms}
                  bankList={props.banks}
                  handleSetFields={handler.handleSetFields}
                  disablePay={disablePay}
                  color={webcheckout.color}
                  secondaryColor={webcheckout.secondaryColor}
                  buttonStyle={webcheckout.buttonStyle}
                  loading={props.loading}
                  isMobile={isMobile}
                  visible={paymentMethodForm === "transfer"}
                  logos={webcheckout.logos!.transferBrands}
                  handleChangePaymentMethod={handler.handleChangePaymentMethod}
                  form={form}
                  handleClose={handler.handleOpenTermsConditions}
                  openTerms={openTerms}
                  totalAmount={webcheckout.totalAmount}
                  currency={webcheckout.paymentConfig!.amount.currency}
                  identificationType={transferForm.identificationType}
                  country={webcheckout.country}
                  savedSubscription={transferSavedPaymentData}
                  showAmount={
                    props.webcheckout?.configuration?.showAmount || showAmount
                  }
                />
                <CashFormCard
                  buttonText={buttonText}
                  handleSaveMethod={handler.handleSaveMethod}
                  handleAcceptTerms={handler.handleAcceptTerms}
                  bankList={props.banks}
                  handleSetFields={handler.handleSetFields}
                  disablePay={disablePay}
                  color={webcheckout.color}
                  secondaryColor={webcheckout.secondaryColor}
                  buttonStyle={webcheckout.buttonStyle}
                  loading={props.loading}
                  isMobile={isMobile}
                  visible={paymentMethodForm === "cash"}
                  logos={webcheckout.logos!.cashBrands}
                  handleChangePaymentMethod={handler.handleChangePaymentMethod}
                  form={form}
                  handleClose={handler.handleOpenTermsConditions}
                  openTerms={openTerms}
                  totalAmount={webcheckout.totalAmount}
                  currency={webcheckout.paymentConfig!.amount.currency}
                  identificationType={transferForm.identificationType}
                  country={webcheckout.country}
                  savedSubscription={cashSavedPaymentData}
                  showAmount={
                    props.webcheckout?.configuration?.showAmount || showAmount
                  }
                />

                <AsyncCardFormCard
                  form={form}
                  isMobile={isMobile}
                  paymentMethodForm={paymentMethodForm}
                  logos={webcheckout.logos!.cardBrands}
                  handleChangePaymentMethod={handler.handleChangePaymentMethod}
                  openTerms={openTerms}
                  handleClose={handler.handleOpenTermsConditions}
                  color={webcheckout.color}
                  secondaryColor={webcheckout.secondaryColor}
                  buttonStyle={webcheckout.buttonStyle}
                  handleSetFields={handler.handleSetFields}
                  totalAmount={webcheckout.totalAmount}
                  brandCardInput={webcheckout.brandCardInput}
                  typeCardInput={webcheckout.cardType}
                  deferredOptions={deferredOptions}
                  currency={webcheckout.paymentConfig!.amount.currency}
                  loading={props.loading}
                  handleSaveMethod={handler.handleSaveMethod}
                  translate={translate}
                  siftscienceForm={siftscienceForm}
                  isValidDate={isValidDate}
                  isValidCVV={isValidCVV}
                  billingDetails={props.webcheckout!.billingDetails}
                />

                <SavedCardForm
                  title={cardSavedPaymentData.cardHolderName}
                  subtitle={"****" + cardSavedPaymentData.lastFourDigits}
                  logos={webcheckout.logos!.cardBrands}
                  logo={cardSavedPaymentData.brand}
                  openForm={openSavedCardForm}
                  isMobile={isMobile}
                  handleChangePaymentMethod={handler.handleChangePaymentMethod}
                  form={form}
                  handleClose={handler.handleOpenTermsConditions}
                  color={webcheckout.color}
                  secondaryColor={webcheckout.secondaryColor}
                  buttonStyle={webcheckout.buttonStyle}
                  handleSetFields={handler.handleSetFields}
                  deferredOptions={deferredOptions}
                  typeCard={webcheckout.cardType}
                  currency={webcheckout.paymentConfig!.amount.currency}
                  totalAmount={webcheckout.totalAmount}
                  openTerms={openTerms}
                  loading={props.loading}
                  translate={translate}
                  siftscienceForm={siftscienceForm}
                  siftFormData={get(cardSelected, "siftScienceDetails")}
                  savedCardId={savedCardId}
                  billingDetails={props.webcheckout!.billingDetails}
                  showMonthlyPaymentMessage={showMonthlyPaymentMessage}
                  country={props.webcheckout?.country}
                />
              </Box>
            </Grid>
            {!!get(capturePasswordless, "show") && (
              <Grid item xs={12} md={7}>
                <CapturePasswordless
                  color={webcheckout.color}
                  handleRetryPayment={handler.handleRetryPayment}
                  translate={translate}
                  eventType={eventType}
                />
              </Grid>
            )}
          </Grid>

          <ConfirmMessage
            title={<Translation id="confirmMessage.title" />}
            message={<Translation id="confirmMessage.messageComplete" />}
            open={confirmMessage.open}
            loading={confirmMessage.loading}
            textSecondaryButton={<Translation id="confirmMessage.cancel" />}
            textPrimaryButton={<Translation id="confirmMessage.ok" />}
            handlePrimaryButton={confirmMessage.handlePrimaryButton}
            handleSecondaryButton={confirmMessage.handleSecondaryButton}
            handleOnClose={confirmMessage.handleOnClose}
            colorPrimaryButton={"#E24763"}
          >
            <Typography>
              <Translation id="confirmMessage.message" />{" "}
              <img src={kpay} alt={kpay} height="20px" />
              <Translation id="confirmMessage.messageSecond" />
            </Typography>
          </ConfirmMessage>
        </CustomContainer>
      )}
    </Translation>
  );
};

export const mapDispatchToProps: (dispatch: Dispatch) => MainPropsFunction = (
  dispatch: ThunkDispatch<IWebcheckoutState, undefined, IWebcheckoutAction>
): MainPropsFunction => ({
  getWebcheckout: (smartLinkId: string): void =>
    dispatch(getWebcheckout(smartLinkId)),
  getListBrandsMerchant: (publicMerchantId: string): void =>
    dispatch(getListBrandsMerchant(publicMerchantId)),
  getCardBrand: (publicMerchantId: string, binNumber: string): void =>
    dispatch(getCardBrand(publicMerchantId, binNumber)),
  getDeferredOptions: (publicMerchantId: string, binNumber: string): void =>
    dispatch(getDeferredOptions(publicMerchantId, binNumber)),
  getCommission: (
    publicMerchantId: string,
    totalAmount: number,
    currency: string
  ): void => dispatch(getCommission(publicMerchantId, totalAmount, currency)),
  getToken: (
    publicMerchantId: string,
    tokenRequest: TokenRequest | TokenKPayRequest,
    handleToken: (token: TokenResponse | ErrorResponse) => void
  ): void => dispatch(getToken(publicMerchantId, tokenRequest, handleToken)),
  getCardAsyncToken: (
    publicMerchantId: string,
    tokenRequest: CardAsyncTokenRequest,
    handleToken: (token: CardAsyncTokenResponse | ErrorResponse) => void
  ): void =>
    dispatch(getCardAsyncToken(publicMerchantId, tokenRequest, handleToken)),
  validate3Ds: (
    publicMerchantId: string,
    tokenRequest: TokenResponse,
    handleValidate3Ds: (
      token: ErrorResponse | Validate3DsResponse,
      data: TokenResponse
    ) => void,
    onError: () => void
  ): void =>
    dispatch(
      validate3Ds(publicMerchantId, tokenRequest, handleValidate3Ds, onError)
    ),
  setTimer: (endTime: { endTime: number }): IWebcheckoutAction =>
    dispatch(setTimer(endTime)),
  getTransferToken: (
    publicMerchantId: string,
    tokenTransferRequest: TransferTokenRequest,
    handleTransferToken: (token: TokenResponse | ErrorResponse) => void
  ): void =>
    dispatch(
      getTransferToken(
        publicMerchantId,
        tokenTransferRequest,
        handleTransferToken
      )
    ),
  getCashToken: (
    publicMerchantId: string,
    tokenRequest: CashTokenRequest,
    handleToken: (token: TokenResponse | ErrorResponse) => void
  ): void =>
    dispatch(getCashToken(publicMerchantId, tokenRequest, handleToken)),
  secureServiceValidation: (
    publicMerchantId: string,
    secureServiceRequest: SecureOtpRequest,
    handleResponse: (response: SecureOtpResponse | ErrorResponse) => void
  ) =>
    dispatch(
      secureServiceValidation(
        publicMerchantId,
        secureServiceRequest,
        handleResponse
      )
    ),
  charge: (
    smartLinkId: string,
    chargeRequest: ChargesRequest,
    onSuccess: (data: any) => void,
    onError: () => void
  ) => dispatch(charge(smartLinkId, chargeRequest, onSuccess, onError)),
  chargeAsync: (
    smartLinkId: string,
    chargeRequest: ChargesRequest,
    onSuccess: (data: any) => void,
    onError: () => void
  ) => dispatch(chargeAsync(smartLinkId, chargeRequest, onSuccess, onError)),
  setWebcheckoutEmail: (email): IWebcheckoutAction =>
    dispatch(setWebcheckoutEmail(email)),
  showSaveEmailModal: (): IWebcheckoutAction => dispatch(showSaveEmailModal()),
  getTransferSubscriptionToken: (
    publicMerchantId: string,
    request: TransferSubscriptionTokenRequest,
    handleResponse: (token: TokenResponse | ErrorResponse) => void
  ) =>
    dispatch(
      requestTransferSubscriptionToken(
        publicMerchantId,
        request,
        handleResponse
      )
    ),
  initCardBrandingAnimation: (
    brand: CardBrandsEnum,
    publicMerchantId: string,
    data: object,
    onSuccess: (data: object) => void,
    isMobile: boolean
  ): void =>
    dispatch(
      initCardBrandingAnimation(
        brand,
        publicMerchantId,
        data,
        onSuccess,
        isMobile
      )
    ),
});

export const mapStateToProps: (state: IWebcheckoutState) => MainPropsState = (
  state: IWebcheckoutState
): MainPropsState => ({
  brandCard: state.brands,
  brandsList: state.brandsList,
  optionsFetched: state.optionsFetched,
  webcheckout: state.webcheckout,
  commission: state.commission,
  loading: state.loading,
  initialLoading: state.initialLoading,
  banks: state.banks,
  settings: state.settings,
  link: state.link,
  savedPaymentMethods: state.savedPaymentMethods,
  webcheckoutEmail: state.webcheckoutEmail,
  userBlocked: state.userBlocked,
  merchantSiftScience: state.merchantSiftScience,
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
