import { useMediaQuery } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { CreateSubscriptionResponse } from "../../../../types/create_subscription_response";
import { formatAmount } from "../../../components/PurchaseDetail/PurchaseDetail";
import { SpeiTransactionSummaryIndexProps } from "../SpeiTransactionSummaryIndex";

export interface useSpeiTransactionSummaryHook {
  isMobile: boolean;
  amount: string;
  clabe: string;
}

export type SpeiHistoryState = CreateSubscriptionResponse & {
  totalAmount: number;
  currency: string;
};

export const useSpeiTransactionSummaryIndex = (
  props: SpeiTransactionSummaryIndexProps
): useSpeiTransactionSummaryHook => {
  const history = useHistory<SpeiHistoryState>();
  const params = useParams<{ smartlinkId: string }>();
  props.hideTimer(true);

  isEmpty(history.location.state) &&
    history.push(`/webcheckout/${params.smartlinkId}/error-404`);

  const state = { ...history.location.state };

  return {
    isMobile: useMediaQuery("(max-width:960px)"),
    amount: formatAmount(state.totalAmount, state.currency),
    clabe: get(history.location, "state.referenceNumber", ""),
  };
};
