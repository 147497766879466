import React from "react";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import { PaymentMethod } from "../../PaymentMethod/PaymentMethod";
import {
  BrandsCardAsync,
  WebpayCardAsync,
} from "../../../shared/constants/BrandsCardAsync";
import { ListPaymentVersionsProps } from "../ListPaymentMethods.interface";
import ConditionalRender from "../../ConditionalRender/ConditionalRender";

const MobileView = ({
  isMobile,
  handleSelectMethod,
  logos,
  methodsActive,
  showCardDynamic,
  labelCreditCardPaymentMethod,
}: ListPaymentVersionsProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Box pb={4}>
        <Typography color={"primary"}>
          <T id="listPaymentMethods.titlePayMethods" />
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          hidden={
            !methodsActive.includes("credit-card") ||
            methodsActive.includes("card-dynamic")
          }
        >
          <PaymentMethod
            title={<T id={labelCreditCardPaymentMethod} />}
            logos={logos!.card}
            handleSelectMethod={handleSelectMethod}
            value={"card"}
            color={theme.palette.background.paper}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={
            !methodsActive.includes("card-async") ||
            methodsActive.includes("card-dynamic")
          }
        >
          <PaymentMethod
            title={<T id="listPaymentMethods.titleDebit" />}
            logos={WebpayCardAsync.logos}
            handleSelectMethod={handleSelectMethod}
            value={"cardAsync"}
            color={theme.palette.background.paper}
          />
        </Grid>
        <ConditionalRender condition={showCardDynamic}>
          <Grid item xs={12} hidden={false}>
            <PaymentMethod
              title={<T id="listPaymentMethods.titleCardDynamic" />}
              handleSelectMethod={handleSelectMethod}
              value={"card-dynamic"}
              color={theme.palette.background.paper}
              logos={[...logos!.card, ...BrandsCardAsync.logos]}
            />
          </Grid>
        </ConditionalRender>
        <Grid item xs={12} hidden={!methodsActive.includes("transfer")}>
          <PaymentMethod
            title={<T id="listPaymentMethods.titleTransfer" />}
            handleSelectMethod={handleSelectMethod}
            value={"transfer"}
            color={theme.palette.background.paper}
            logos={logos!.transfer}
          />
        </Grid>
        <Grid item xs={12} hidden={!methodsActive.includes("cash")}>
          <PaymentMethod
            isMobile={isMobile}
            title={<T id="listPaymentMethods.titleCash" />}
            handleSelectMethod={handleSelectMethod}
            value={"cash"}
            color={theme.palette.background.paper}
            logos={[]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileView;
