import React, { Fragment } from "react";
import { Box, Grid, GridList, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { BoxLogos } from "../BoxLogos/BoxLogos";
import { isEmpty } from "lodash";
import cash from "../../assets/logos/cash.svg";
import { useStyles } from "./PaymentMethod.styles";
import { PaymentMethodProps } from "./PaymentMethod.interface";

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  title,
  logos,
  handleSelectMethod,
  value,
  isMobile,
  color,
}: PaymentMethodProps) => {
  const classes = useStyles();

  const fallBackLogo = [
    {
      image: value === "cash" ? cash : "",
      value: value === "cash" ? "cash" : "",
    },
  ];

  const logoFallBack = isEmpty(logos) ? fallBackLogo : logos;
  const isCash = value === "cash";
  const isDesktop = !isMobile;
  const isCardDynamic = value === "card-dynamic";

  const cashWidth = isCash && isDesktop ? "60%" : "100%";
  const cashWrap = isCardDynamic ? "wrap" : "nowrap";

  return (
    <Fragment>
      <ToggleButtonGroup
        onChange={handleSelectMethod}
        style={{ backgroundColor: color }}
        className={classes.toggleGroup}
        exclusive
      >
        <ToggleButton size="large" style={{ width: "100%" }} value={value}>
          <Grid container component={Box} py={{ md: 1, xs: 0 }} spacing={1}>
            <Grid item xs={12} md={6} className={classes.textContent}>
              <Typography align="left" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <GridList
                className={classes.gridList}
                style={{
                  width: cashWidth,
                  flexWrap: cashWrap,
                }}
              >
                <BoxLogos logos={logoFallBack} />
              </GridList>
            </Grid>
          </Grid>
        </ToggleButton>
      </ToggleButtonGroup>
    </Fragment>
  );
};
