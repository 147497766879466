import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { TimerSessionProps } from "../TimerSession";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";

export interface UseTimerSessionState {
  minutes: string;
  seconds: string;
  warning: boolean;
  show: boolean;
}

let startingSeconds: number = 59;
let time: number = startingSeconds * 60;
export const useTimerSessionState = (
  props: TimerSessionProps
): UseTimerSessionState => {
  const [minutes, setMinutes] = useState<string>("");
  const [seconds, setSeconds] = useState<string>("");
  const [warning, setWarning] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const intervalRef = React.useRef();
  const history = useHistory();
  const timerState = useSelector((state: IWebcheckoutState) => state.timer);
  useEffect(() => {
    if (timerState !== undefined) {
      setShow(true);
      // @ts-ignore
      intervalRef.current = setInterval(runTimer, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [timerState, show]);

  const runTimer = () => {
    const currentDate = new Date().getTime();
    const endDate = new Date(timerState!.endTime!);
    const diff = differenceInSeconds(endDate, currentDate);
    if (diff <= 0) history.push("/webcheckout/expired-session");

    startingSeconds = diff;
    time = startingSeconds;

    let min: number = Math.floor(time / 60);
    let sec: number = time % 60;

    if (min <= 3 && sec === 0) {
      setWarning(true);
    }
    if (sec === 0 && min === 0) {
      setShow(false);
      clearInterval(intervalRef.current);
      history.push("/webcheckout/expired-session");
    }

    const secStr = sec < 10 ? "0" + sec : sec;
    const minStr = min < 10 ? "0" + min : min;
    setMinutes(String(minStr));
    setSeconds(String(secStr));
  };

  return {
    warning,
    minutes,
    seconds,
    show: show,
  };
};
