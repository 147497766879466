import React from "react";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { useStyle } from "./CapturePasswordlessStyle";
import {
  TCapturePasswordlessForm,
  useCapturePasswordlessState,
} from "./state/useCapturePasswordlessState";
import { useTypedController } from "@hookform/strictly-typed";
import { Translate as T, TranslateFunction } from "react-localize-redux";
import { setGAEvent } from "../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../shared/constants/GAConstants";
import {
  EventTypeEnum,
  EventTypes,
} from "../../shared/infrastructure/EventTypeEnum";

export interface CapturePasswordlessProps {
  handleRetryPayment: () => void;
  color?: string;
  translate: TranslateFunction;
  eventType?: string;
}

export const CapturePasswordless: React.FC<CapturePasswordlessProps> = (
  props: CapturePasswordlessProps
) => {
  const color: string = props.color ? props.color : "#0061ff";
  const classes = useStyle(color);

  const { form, handler, loading } = useCapturePasswordlessState({
    translate: props.translate,
  });
  const TypedController = useTypedController<TCapturePasswordlessForm>({
    control: form.control,
  });

  return (
    <React.Fragment>
      <Paper>
        <Card
          style={{
            position: "relative",
          }}
        >
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Grid container>
              <Grid item xs={12} className={classes.headerTitle}>
                <Typography className={classes.title} variant={"h3"}>
                  <T id="dataVerification" />
                </Typography>
              </Grid>

              <Grid container className={classes.contentContainer}>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    position: "relative",
                    marginBottom: 100,
                  }}
                >
                  <Box pb={3}>
                    <Typography className={classes.description}>
                      <T id="securityData" />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.description}>
                      <T id={EventTypes[props.eventType!]} />
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="flexStart"
                    justifyContent="center"
                  >
                    <Box mr={2}>
                      <TypedController
                        name={"securityCode"}
                        rules={{
                          required: props.translate(
                            "form.fieldRequired"
                          ) as string,
                        }}
                        render={(typedProps) => (
                          <TextField
                            {...typedProps}
                            onClick={() => {
                              setGAEvent(
                                GA_CONSTANTS.dataValidation.category,
                                GA_CONSTANTS.actions.click,
                                GA_CONSTANTS.dataValidation.labels.E2
                              );
                            }}
                            variant="outlined"
                            placeholder={
                              props.translate("form.securityCode") as string
                            }
                            error={!!form.errors.securityCode}
                            required
                            fullWidth
                            margin="none"
                            helperText={
                              !!form.errors.securityCode &&
                              form.errors.securityCode.message
                            }
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Button
                        variant={"contained"}
                        onClick={form.handleSubmit(handler.handleConfirm)}
                        disabled={loading}
                        endIcon={
                          loading ? (
                            <CircularProgress size={20} color={"primary"} />
                          ) : null
                        }
                        className={classes.button}
                      >
                        <Typography className={classes.continueLabel}>
                          <T id="confirmCode" />
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                {props.eventType && props.eventType === EventTypeEnum.PAY && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                      marginTop: 16,
                      marginBottom: 125,
                    }}
                  >
                    <Button variant={"text"} onClick={props.handleRetryPayment}>
                      <Typography className={classes.retryLabel}>
                        <T id="anotherCard" />
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </React.Fragment>
  );
};
