import React from "react";
import { FormCard, IFormCardProps } from "../../commons/FormCard/FormCard";
import transfer from "../../../assets/logos/transfer.svg";

import { BankList } from "../../../../types/bank_list";
import { FormSelect, SelectItem } from "../../commons/FormSelect/FormSelect";
import { Grid, Typography } from "@material-ui/core";
import {
  EmailInput,
  IdentificationInput,
  IdentificationSelect,
  NameInput,
  UserTypeSelect,
} from "../../commons/FormCardChildren/FormCardChildren";
import { Translate as T } from "react-localize-redux";
import { TransferPaymentDataResponse } from "../../../../types/payment_data_response";
import { makeStyles } from "@material-ui/core/styles";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { getValuePayButton } from "../../CardFormCard/state/useCardFormCardState";

const useStyles = makeStyles({
  color: {
    "& input, & div": {
      color: "#6D7781",
    },
  },
});

export const SafetyPayFormCard: React.FC<IFormCardProps> = (
  props: IFormCardProps
) => {
  const savedTransferPaymentData = props.savedSubscription as TransferPaymentDataResponse;
  const bankList: BankList = props.bankList || [];
  const banks: SelectItem[] = bankList.map((item) => ({
    value: item.code,
    label: item.name,
  }));
  const country = props.country;
  const classes = useStyles();

  return (
    <React.Fragment>
      <FormCard
        {...props}
        blurryOnLoad
        headerProps={{
          handleChangePaymentMethod: props.handleChangePaymentMethod,
          isMobile: props.isMobile,
          title: <T id="listPaymentMethods.titleTransfer" />,
          titleLogo: transfer,
        }}
        showAcceptTerms
        buttonText={props.buttonText}
        submitLabel={getValuePayButton(
          props?.showAmount,
          props.totalAmount,
          props.currency
        )}
      >
        <Grid container spacing={2} className={classes.color}>
          {country === CountryEnum.MXN ? (
            <Grid item xs={12}>
              <Typography
                style={{
                  lineHeight: "136%",
                  fontSize: "13",
                  color: "#293036",
                }}
              >
                <T id="safetyPay" />
              </Typography>
            </Grid>
          ) : null}
          {country === CountryEnum.COL ? (
            <React.Fragment>
              <Grid item xs={12}>
                <UserTypeSelect
                  form={props.form}
                  defaultValue={savedTransferPaymentData.userType}
                />
              </Grid>
            </React.Fragment>
          ) : null}

          {![CountryEnum.BRA, CountryEnum.CRI].find(
            (value: string) => value === country
          ) ? (
            <Grid item xs={12}>
              <NameInput
                form={props.form}
                defaultValue={savedTransferPaymentData.name}
              />
            </Grid>
          ) : null}
          {[
            CountryEnum.ECU,
            CountryEnum.PER,
            CountryEnum.COL,
            CountryEnum.BRA,
            CountryEnum.CRI,
            CountryEnum.CHL,
          ].find((value: string) => value === country) ? (
            <React.Fragment>
              <Grid item xs={props.isMobile ? 4 : 3}>
                <IdentificationSelect
                  form={props.form}
                  items={props.identificationType}
                  defaultValue={savedTransferPaymentData.documentType}
                />
              </Grid>
              <Grid item xs={props.isMobile ? 8 : 9}>
                <IdentificationInput
                  form={props.form}
                  defaultValue={savedTransferPaymentData.documentNumber}
                />
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={12}>
            <EmailInput
              form={props.form}
              defaultValue={savedTransferPaymentData.email}
            />
          </Grid>
        </Grid>
      </FormCard>
    </React.Fragment>
  );
};
