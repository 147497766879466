import React from "react";
import { Box, FormControl, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTypedController } from "@hookform/strictly-typed";
import { Control } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { SelectItem } from "../FormSelect/FormSelect";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";

export interface FormToggleButtonGroupProps {
  name: string;
  label: string;
  items: SelectItem[];
  form: {
    control: Control<Record<string, any>>;
  };
  defaultValue?: string;
}

const useStyles = makeStyles({
  label: {
    fontSize: "15px",
    lineHeight: "140%",
    color: "#6D7781",
    paddingRight: 60,
    width: "100%",
  },
  toggleBtn: {
    "&.MuiToggleButton-root.Mui-selected": {
      textTransform: "none",
      border: "0.6px solid #023365",
      boxSizing: "border-box",
      backgroundColor: "#023365",
      color: "white",
      height: "30px",
    },
    textTransform: "none",
    border: "0.6px solid #023365",
    boxSizing: "border-box",
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "white",
    color: "#023365",
    height: "30px",
  },
});

export const renderToggleButtonGroup = (
  items: SelectItem[],
  className: string
) => (props: any) => (
  <ToggleButtonGroup
    {...props}
    exclusive
    onChange={(_e, value) => value !== null && props.onChange(value)}
    defaultValue={"natural"}
  >
    {items.map((item: SelectItem, index: number) => (
      <ToggleButton
        key={`${index}_selectedItem`}
        className={className}
        value={item.value}
        onClick={() => {
          setGAEvent(
            GA_CONSTANTS.dataForTransfer.category,
            GA_CONSTANTS.actions.select,
            item.value === "0"
              ? GA_CONSTANTS.dataForTransfer.labels.B2
              : GA_CONSTANTS.dataForTransfer.labels.B3
          );
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
          }}
        >
          {item.label}
        </Typography>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

export const FormToggleButtonGroup: React.FC<FormToggleButtonGroupProps> = (
  props: FormToggleButtonGroupProps
) => {
  const classes = useStyles();
  const defaultValue = props.defaultValue
    ? props.defaultValue
    : props.items[0].value;
  const TypedController = useTypedController({ control: props.form.control });
  return (
    <React.Fragment>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingBottom: 30,
        }}
      >
        <Box width={{ xs: "50%", sm: "35%" }}>
          <Typography className={classes.label}>{props.label}</Typography>
        </Box>
        <Box>
          <FormControl variant={"outlined"} fullWidth>
            <TypedController
              name={props.name}
              rules={{ required: true }}
              defaultValue={defaultValue}
              render={renderToggleButtonGroup(props.items, classes.toggleBtn)}
            />
          </FormControl>
        </Box>
      </Box>
    </React.Fragment>
  );
};
