import { SubmitHandler, useForm, UseFormMethods } from "react-hook-form";
import { useAuth } from "../../../shared/service/AuthService";
import { useStateApp } from "../../../state/useStateApp";
import { useState } from "react";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";
import { useHistory } from "react-router-dom";
import { TranslateFunction } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";

interface ICapturePasswordlessForm {
  securityCode: string;
}

export type TCapturePasswordlessForm = ICapturePasswordlessForm;

export interface IUseCapturePasswordlessState {
  isMobile: boolean;
  loading: boolean;
  form: UseFormMethods<TCapturePasswordlessForm>;
  handler: {
    handleConfirm: SubmitHandler<TCapturePasswordlessForm>;
  };
}

export interface IUseCapturePasswordlessStateProps {
  translate?: TranslateFunction;
}

export const useCapturePasswordlessState = (
  props?: IUseCapturePasswordlessStateProps
): IUseCapturePasswordlessState => {
  const { isMobile } = useStateApp();
  const webcheckout = useSelector(
    (state: IWebcheckoutState) => state.webcheckout
  );

  const form = useForm<TCapturePasswordlessForm>();
  const { answerCustomChallenge } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const handleWrongCodeError = () => {
    form.setError("securityCode", {
      type: "manual",
      message: props!.translate!("form.codeInvalid") as string,
    });
  };
  const handleThreeTimesError = () => {
    history.push("/error/email", {
      ...webcheckout,
    });
  };

  const handleConfirm: SubmitHandler<TCapturePasswordlessForm> = async (
    formData
  ) => {
    setGAEvent(
      GA_CONSTANTS.dataValidation.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.dataValidation.labels.E3
    );
    setLoading(true);
    await answerCustomChallenge(
      formData.securityCode,
      handleWrongCodeError,
      handleThreeTimesError
    );
    setLoading(false);
  };

  return {
    isMobile,
    loading,
    form,
    handler: {
      handleConfirm,
    },
  };
};
