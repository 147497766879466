import React from "react";
import {
  Box,
  Card,
  Collapse,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { get, isEmpty } from "lodash";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Translate as T } from "react-localize-redux";
import { usePurchasDetailStyles } from "./PurchaseDetail.styles";
import { IProduct, IPurchaseDetail } from "./PurchaseDetail.interfaces";
import { ProductsDetailTabs } from "./ProductDetailTabs";
import { CurrencyEnum } from "../../shared/infrastructure/CurrencyEnum";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { WebcheckoutTypeEnum } from "../../shared/infrastructure/WebcheckoutTypeEnum";

export const formatAmount = (
  amount: number,
  currency: string,
  showCurrency = true
): string => {
  if (
    currency === CurrencyEnum[CountryEnum.CHL] ||
    currency === CurrencyEnum[CountryEnum.COL]
  )
    return `${Math.trunc(amount)} ${showCurrency ? currency : ""}`;

  const new_amount: string = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(amount));

  return `${new_amount} ${showCurrency ? currency : ""}`;
};

export const Details: React.FC<IPurchaseDetail> = (props: IPurchaseDetail) => {
  const classes = usePurchasDetailStyles(props);

  const products = props.products.filter((element) => {
    if (isEmpty(element)) return false;
    return true;
  });
  const showProductsList: boolean = products.length > 0;
  const isExpressCheckout =
    props.webcheckoutType === WebcheckoutTypeEnum.EXPRESS_CHECKOUT;
  const hasPaymentDescription = !isEmpty(props.paymentDescription);
  const showDescription = isExpressCheckout && hasPaymentDescription;

  return (
    <React.Fragment>
      {!props.isMobile ? (
        <Card className={classes.root}>
          <Box className={classes.boxColor}>
            <Typography className={classes.tittle1}>
              <T id="purchaseDetail.title" />
            </Typography>
            <Typography className={classes.tittle2}>
              <T id="purchaseDetail.totalToPay" />
            </Typography>
            <Typography className={classes.amountDetail}>
              {`${formatAmount(props.amount, props.currency)}`}
            </Typography>
          </Box>
          {(showProductsList || isExpressCheckout) && (
            <ProductsDetailTabs {...props} />
          )}
        </Card>
      ) : (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.collapse}
          classes={{
            root: classes.rootList,
            padding: classes.paddingList,
          }}
        >
          <ListItem button onClick={props.handleOpenCollapse}>
            <Typography className={classes.contrastLabel}>
              {showDescription ? (
                <T id="purchaseDetail.paymentDescription" />
              ) : (
                <T id="purchaseDetail.title" />
              )}
            </Typography>
            {showProductsList && (
              <>
                {props.openCollapse ? (
                  <ExpandLess className={classes.contrastLabel} />
                ) : (
                  <ExpandMore className={classes.contrastLabel} />
                )}
              </>
            )}

            {showDescription && (
              <>
                {props.openCollapse ? (
                  <ExpandLess className={classes.contrastLabel} />
                ) : (
                  <ExpandMore className={classes.contrastLabel} />
                )}
              </>
            )}
            <Box flexGrow={1} />
            <Box>
              <Typography className={classes.mobileLabel}>
                <T id="purchaseDetail.totalToPay" />
              </Typography>
              <Typography className={classes.mobileToPay}>
                {formatAmount(props.amount, props.currency)}
              </Typography>
            </Box>
          </ListItem>
          {showProductsList && (
            <Collapse in={props.openCollapse} timeout="auto" unmountOnExit>
              <Box boxShadow={3} className={classes.mobileBox}>
                {products.map((product: IProduct, index: number) => (
                  <React.Fragment key={`${index}_PurchaseDetailProduct`}>
                    <Grid
                      justify="center"
                      alignItems="center"
                      container
                      style={{
                        paddingBottom: 24,
                      }}
                    >
                      <Grid item xs={1}>
                        {product.quantity}
                      </Grid>
                      <Grid item xs={6}>
                        {product.description}
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={4}>
                        {formatAmount(product.unitPrice!, props.currency)}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Box>
            </Collapse>
          )}
          {showDescription && (
            <Collapse in={props.openCollapse} timeout="auto" unmountOnExit>
              <Box boxShadow={3} className={classes.mobileBox}>
                <Grid
                  justify="center"
                  alignItems="center"
                  container
                  style={{
                    paddingBottom: 24,
                  }}
                >
                  {isEmpty(props.paymentDescription) ? (
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      -
                    </Typography>
                  ) : (
                    <Typography style={{ width: "100%" }}>
                      {props.paymentDescription}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Collapse>
          )}
        </List>
      )}
    </React.Fragment>
  );
};

export default Details;
