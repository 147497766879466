/* istanbul ignore file */
/* eslint-disable */
export const environment: {
envName: string;
timeoutRedirectMs: string;
kushkiUrl: string;
devTools: boolean;
userPoolId: string;
appClientId: string;
rollbarId: string;
firebaseConfig: {
apiKey: string;
authDomain: string;
databaseURL: string;
projectId: string;
storageBucket: string;
messagingSenderId: string;
};
gtmId: string;
} = {
timeoutRedirectMs: "10000",
envName: "primary",
kushkiUrl: "https://api.kushkipagos.com",
devTools: false,
userPoolId: 'not_available',
appClientId: 'not_available',
rollbarId: '1ef065ba3cbf4610a746ee14ff40b7fd',
firebaseConfig: {
apiKey:'AIzaSyAXtQlq6sXC3-cVMCUvwp6DUC1RteuT4VA',
authDomain:'kushki-97eba.firebaseapp.com',
databaseURL: 'https://kushki-97eba.firebaseio.com',
projectId: 'kushki-97eba',
storageBucket: 'kushki-97eba.appspot.com',
messagingSenderId: '821114948879'
},
gtmId: 'GTM-KD85DX2'
};
