import { makeStyles } from "@material-ui/core/styles";
import { StringUtils } from "../../../shared/utils/StringUtils";

const getBorderRadious = (buttonStyle: string) => {
  if (buttonStyle === "round") {
    return "20px";
  }
  if (buttonStyle === "semi") {
    return "12px";
  }
  return "2px";
};

export const useTransferCardStyles = (
  color: string,
  secondaryColor: string,
  buttonStyle: string
) => {
  const [fontColor, lightenColor]: string[] = StringUtils.getContrastYIQ(
    secondaryColor
  );
  return makeStyles((theme) => ({
    root: {
      "&$checked": {
        color: color,
      },
      "& a": {
        color: color,
      },
    },
    checked: {},
    link: {
      color: color,
      fontSize: "14px",
    },
    label: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal",
      color: "#293036",
    },
    button: {
      backgroundColor: secondaryColor,
      marginTop: 15,
      borderRadius: getBorderRadious(buttonStyle),
      color: fontColor,
      "&:hover": {
        backgroundColor: lightenColor,
        color: fontColor,
      },
    },
    transferCard: {
      position: "relative",
      paddingTop: theme.spacing(2),
    },
    blurry: {
      WebkitFilter: "blur(5px)",
      msFilter: "blur(5px)",
      filter: "blur(5px)",
      userSelect: "none",
    },
    form: {
      marginLeft: 20,
      marginRight: 20,
    },
    personLabel: {
      fontSize: "16px",
      lineHeight: "140%",
      color: "#6D7781",
      paddingRight: 60,
    },
    toggleBtn: {
      "&.MuiToggleButton-root.Mui-selected": {
        textTransform: "none",
        border: "0.6px solid #023365",
        boxSizing: "border-box",
        backgroundColor: "#023365",
        color: "white",
        height: "30px",
      },
      textTransform: "none",
      border: "0.6px solid #023365",
      boxSizing: "border-box",
      borderRadius: "5px 5px 5px 5px",
      backgroundColor: "white",
      color: "#023365",
      height: "30px",
    },
    formLabel: {
      fontSize: "14px",
      lineHeight: "136%",
      color: "#293036",
    },
  }))();
};
