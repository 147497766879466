import React, { RefObject } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { DeepMap, FieldError } from "react-hook-form";
import { Translate as T } from "react-localize-redux";

interface InputProps {
  name: string;
  placeholder: string;
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
  };
}

export type FormInputProps = InputProps & TextFieldProps;

export const FormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
  const validateEmailPatternError = (type: string) =>
    type === "pattern" ? (
      <T id="form.emailInvalid" />
    ) : (
      props.form.errors[props.name].message
    );

  return (
    <React.Fragment>
      <TextField
        inputRef={props.form.register({
          required: <T id="form.fieldRequired" />,
        })}
        {...props}
        fullWidth
        variant={"outlined"}
        name={props.name}
        placeholder={props.placeholder}
        error={props.form.errors[props.name]}
        helperText={
          props.form.errors[props.name]
            ? validateEmailPatternError(props.form.errors[props.name].type)
            : ""
        }
      />
    </React.Fragment>
  );
};
