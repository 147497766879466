import React, { Fragment } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  condition: boolean | undefined;
  children: React.ReactNode;
}

const ConditionalRender = ({ condition, children, ...props }: Props) => {
  if (condition) {
    return <Fragment {...props}>{children}</Fragment>;
  }
  return <></>;
};
export default ConditionalRender;
