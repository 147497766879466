import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import success from "../../assets/SuccessIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";

export interface HeaderSummaryProps {
  title: string;
  message: string;
  isMobile: boolean;
  paymentMethod?: string;
}
export const HeaderSummary: React.FC<HeaderSummaryProps> = (
  props: HeaderSummaryProps
) => {
  const useStyles = makeStyles({
    title: {
      fontWeight: 500,
      lineHeight: props.paymentMethod === "cash" ? "220%" : "120%",
      color: "#293036",
      fontSize: props.isMobile ? "24px" : "32px",
      fontStyle: "normal",
    },
    message: {
      color: "#293036",
      fontWeight: 500,
      lineHeight: "150%",
      fontSize: "16px",
      fontStyle: "normal",
    },
  });
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <img src={success} alt={success} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align={"center"} className={classes.title}>
              <T id={props.title} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography align={"center"} className={classes.message}>
            <T id={props.message} />
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
