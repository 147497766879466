import React, { PropsWithChildren } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

export interface ConfirmMessageProps {
  open?: boolean;
  handleOnClose?: () => void;
  title?: any;
  message?: any;
  handleSecondaryButton?: (kind: string) => void;
  handlePrimaryButton?: () => void;
  textPrimaryButton?: any;
  textSecondaryButton?: any;
  colorPrimaryButton?: string;
  loading: boolean;
}

export const ConfirmMessage: React.FC<ConfirmMessageProps> = (
  props: PropsWithChildren<ConfirmMessageProps>
) => {
  return (
    <React.Fragment>
      <Dialog open={props.open!} onClose={props.handleOnClose}>
        <DialogTitle>
          <Typography color={"primary"}>{props.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.handleSecondaryButton && props.textSecondaryButton ? (
            <Button
              onClick={() => {
                props.handleSecondaryButton!("delete");
              }}
            >
              {props.textSecondaryButton}
            </Button>
          ) : null}
          <Button
            disabled={props.loading}
            onClick={props.handlePrimaryButton}
            style={{ color: props.colorPrimaryButton }}
          >
            {props.textPrimaryButton}
          </Button>
          {props.loading && (
            <CircularProgress
              size={24}
              style={{ color: props.colorPrimaryButton }}
            />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
