import { TransactionSummaryIndexProps } from "../TransactionSummaryIndex";
import { useMediaQuery } from "@material-ui/core";
import { Product, SmartLink } from "../../../../types/webcheckout";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { defaultTo, get, isEmpty } from "lodash";
import { SuccessCharge } from "../../../../types/success_charge";
import { ParamsTransfer } from "../../state/useMainState";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";
import { TransactionTypeEnum } from "../../../shared/infrastructure/TransactionTypeEnum";
import { ResumeTypeEnum } from "../../../shared/infrastructure/ResumeTypeEnum";
import { FirebasePathEnum } from "../../../shared/infrastructure/FirebasePathEnum";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";

export interface UseSummaryState {
  header: {
    title: string;
    message: string;
  };
  isDeferred: boolean;
  isPreAuth: boolean;
  information: { label: string; value: string }[];
  isMobile: boolean;
  handler: {
    handleDownloadFile: () => void;
    handleBackMerchant: () => void;
  };
  loading: boolean;
  lengthColumn: 2 | 4 | 6 | 8;
  config: {
    color: string;
  };
  paymentMethod: string;
  loadingWS: boolean;
  isCardAsync: boolean;
  isServiceSTP: boolean;
  kindWebcheckout: string;
  redirectTimer: number;
}

let fields: Record<
  string,
  { label: string; value: string; products?: Product[] }[]
> = {
  card: [
    { label: "purchaseSummaryTable.mount", value: "1200 USD" },
    { label: "purchaseSummaryTable.state", value: "Aprobado" },
    { label: "purchaseSummaryTable.product", value: "", products: [] },
    { label: "purchaseSummaryTable.ticketNumber", value: "#1234567890987654" },
  ],
  cardAsync: [
    { label: "purchaseSummaryTable.mount", value: "1200 USD" },
    { label: "purchaseSummaryTable.state", value: "Aprobado" },
    { label: "purchaseSummaryTable.product", value: "", products: [] },
    { label: "purchaseSummaryTable.ticketNumber", value: "#1234567890987654" },
  ],
  transfer: [
    { label: "purchaseSummaryTable.businessName", value: "" },
    { label: "purchaseSummaryTable.mount", value: "" },
    { label: "purchaseSummaryTable.date", value: "" },
    { label: "purchaseSummaryTable.name", value: "" },
    { label: "purchaseSummaryTable.identification", value: "" },
    { label: "purchaseSummaryTable.mail", value: "" },
    { label: "purchaseSummaryTable.product", value: "", products: [] },
  ],
  pse: [
    { label: "purchaseSummaryTable.businessName", value: "" },
    { label: "purchaseSummaryTable.mount", value: "" },
    { label: "purchaseSummaryTable.name", value: "" },
    { label: "purchaseSummaryTable.identification", value: "" },
    { label: "purchaseSummaryTable.mail", value: "" },
    { label: "purchaseSummaryTable.bank", value: "" },
    { label: "purchaseSummaryTable.cus", value: "" },
    { label: "purchaseSummaryTable.date", value: "" },
    { label: "purchaseSummaryTable.product", value: "", products: [] },
  ],
  stp: [
    { label: "purchaseSummaryTable.clabeAccount", value: "138180000118356712" },
    { label: "purchaseSummaryTable.orderNumber", value: "19384939" },
    { label: "purchaseSummaryTable.mount", value: "19040.99 MXN" },
    {
      label: "purchaseSummaryTable.bank",
      value: "STP (Sistema de Transferencias y pagos)",
    },
  ],
  cash: [
    { label: "purchaseSummaryTable.mount", value: "1200 USD" },
    { label: "purchaseSummaryTable.state", value: "Aprobado" },
    { label: "purchaseSummaryTable.product", value: "", products: [] },
    { label: "purchaseSummaryTable.ticketNumber", value: "#1234567890987654" },
  ],
};
const headerMethod: Record<string, { title: string; message: string }> = {
  general: {
    title: "headerGeneral.titleHeader",
    message: "headerGeneral.subTitleHeader",
  },
  stp: {
    title: "headerStp.titleHeader",
    message: "headerStp.subTitleHeader",
  },
  cash: {
    title: "headerCash.titleHeader",
    message: "headerCash.subTitleHeader",
  },
  preAuth: {
    title: "headerPreAuth.titleHeader",
    message: "headerPreAuth.subTitleHeader",
  },
};
const useParams = () => {
  return new URLSearchParams(useLocation().search);
};
export const useSummaryState = (
  props: TransactionSummaryIndexProps
): UseSummaryState => {
  const kind = useParams().get("kind");
  const resumeType = useParams().get("resumeType");
  const webcheckout = useSelector(
    (state: IWebcheckoutState) => state.webcheckout
  );
  const kindWebcheckoutParam: string = defaultTo(
    useParams().get("kindWebcheckout"),
    ""
  );
  const kindWebcheckout: string = get(
    webcheckout,
    "kushkiMetadata.source",
    kindWebcheckoutParam
  );
  const params: ParamsTransfer = {
    amount: defaultTo(useParams().get("amount"), 0),
    currency: defaultTo(useParams().get("currency"), ""),
    merchantName: defaultTo(useParams().get("merchantName"), ""),
    date: defaultTo(useParams().get("date"), ""),
    customerName: defaultTo(useParams().get("customerName"), ""),
    documentNumber: defaultTo(useParams().get("documentNumber"), ""),
    email: defaultTo(useParams().get("email"), ""),
    products: JSON.parse(defaultTo(useParams().get("products"), "[]")),
    bankName: defaultTo(useParams().get("bankName"), ""),
    redirectUrl: defaultTo(useParams().get("redirectUrl"), ""),
    id: defaultTo(useParams().get("id"), ""),
  };
  const tokenTransfer = useParams().get("token");
  const history = useHistory<SmartLink>();
  const redirectUrl: string =
    params.redirectUrl || get(history, "location.state.redirectURL", "/");
  const isMobile = useMediaQuery("(max-width:960px)");
  const [loadingWS, setLoadingWS] = useState<boolean>(!!params.redirectUrl);
  const [loading, setLoading] = useState<boolean>(false);
  const [lengthColumn, setLengthColumn] = useState<2 | 4 | 6 | 8>(6);
  const [information, setInformation] = useState(fields["card"]);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [header, setHeader] = useState({
    title: "headerGeneral.titleHeader",
    message: "headerGeneral.subTitleHeader",
  });
  const [config, setConfig] = useState<{
    color: string;
    secondaryColor: string;
  }>({
    color: "#CC9200",
    secondaryColor: "#CC9200",
  });
  const [isCardAsync, setIsCardAsync] = useState<boolean>(false);
  const [isServiceSTP, setIsServiceSTP] = useState<boolean>(false);
  const redirectTimer = Date.now() + 30000;

  useEffect(() => {
    setGAEvent(
      GA_CONSTANTS.successfulPurchaseSummary.category,
      GA_CONSTANTS.actions.view,
      GA_CONSTANTS.successfulPurchaseSummary.labels.E8
    );
    setPropertiesInformation();
    if (resumeType === ResumeTypeEnum.CARD_ASYNC) setIsCardAsync(true);
    if (
      resumeType !== undefined &&
      resumeType !== null &&
      tokenTransfer !== undefined &&
      tokenTransfer !== null
    ) {
      props.getInfoFirebase(tokenTransfer, resumeType);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(props.receipt)) {
      window.open(props.receipt!.url);
    }
    setLoading(false);
  }, [props.receipt]);

  useEffect(() => {
    setLoading(props.loadingReceipt!);
  }, [props.loadingReceipt]);

  useEffect(() => {
    if (!isEmpty(props.firebaseInfo)) {
      if (get(props, getPathFirebase(), "") === "approvedTransaction") {
        setPropertiesInformation(
          get(props, FirebasePathEnum.Cus, ""),
          get(props, FirebasePathEnum.TicketNumber, "")
        );
        setLoadingWS(false);
      } else {
        if (params.redirectUrl) {
          history.push("/webcheckout/payment-declined", {
            id: params.id,
            redirectURL: redirectUrl,
          } as never);
        }
      }
    }
  }, [props.firebaseInfo]);

  const getPathFirebase = (): string => {
    if (defaultTo(resumeType, "") === ResumeTypeEnum.CARD_ASYNC)
      return FirebasePathEnum.Status;

    return FirebasePathEnum.TransferStatus;
  };

  const setPropertiesInformation = (
    cus?: string,
    ticketNumberAsync?: string
  ) => {
    const service: string = get(history, "location.state.kind", kind);
    setConfig({
      color: get(
        history,
        "location.state.styleAndStructure.primaryColor",
        "#CC9200"
      ),
      secondaryColor: get(
        history,
        "location.state.styleAndStructure.secondaryColor",
        "#CC9200"
      ),
    });
    setPaymentMethod(service);

    switch (service) {
      case "card":
        if (isPreAuth) {
          setHeader(headerMethod["preAuth"]);
          setLengthColumn(6);
        } else {
          setHeader(headerMethod["general"]);
          setLengthColumn(6);
        }
        break;
      case "transfer":
        setHeader(headerMethod["general"]);
        setLengthColumn(4);
        break;
      case "pse":
        setHeader(headerMethod["general"]);
        setLengthColumn(4);
        break;
      case "stp":
        setHeader(headerMethod[service]);
        setLengthColumn(6);
        setIsServiceSTP(true);
        break;
      case "cash":
        setHeader(headerMethod[service]);
        setLengthColumn(6);
        break;
      case "cardAsync":
        if (isPreAuth) {
          setHeader(headerMethod["preAuth"]);
          setLengthColumn(6);
        }
        break;
    }
    setFieldsByPaymentMethod(service, fields[service], cus, ticketNumberAsync);
  };

  const setFieldsByPaymentMethod = (
    service: string,
    fields: { label: string; value: string; products?: Product[] }[],
    cus?: string,
    ticketNumberAsync?: string
  ) => {
    const response: SuccessCharge = get(history, "location.state.response");
    if (!isEmpty(get(history, "location.state.kind")) || kind !== null) {
      switch (service) {
        case "card":
          fields[0].value = `${response.amount!} ${response.currency!}`;
          fields[1].value = isPreAuth ? "Preautorizado" : "Aprobado";
          fields[2].products = get(response, "products", []);
          fields[3].value = `#${response.ticketNumber!}`;
          break;
        case "transfer":
          fields[0].value = params.merchantName;
          fields[1].value = `${params.amount} ${params.currency}`;
          fields[2].value = params.date;
          fields[3].value = params.customerName;
          fields[4].value = params.documentNumber;
          fields[5].value = params.email;
          fields[6].products = params.products;
          break;
        case "pse":
          fields[0].value = params.merchantName;
          fields[1].value = `${params.amount} ${params.currency}`;
          fields[2].value = params.customerName;
          fields[3].value = params.documentNumber;
          fields[4].value = params.email;
          fields[5].value = defaultTo(params.bankName, "");
          fields[6].value = defaultTo(cus, "");
          fields[7].value = params.date;
          fields[8].products = params.products;
          break;
        case "stp":
          fields[0].value = response.referenceProcessor!;
          fields[1].value = response.ticketNumber!;
          fields[2].value = `${response.amount!} ${response.currency!}`;
          fields[3].value = response.bankName!;
          break;
        case "cash":
          fields[0].value = `${response.amount!} ${response.currency!}`;
          fields[1].value = "Inicializado";
          fields[2].products = get(response, "products", []);
          fields[3].value = `#${response.ticketNumber!}`;
          break;
        case "cardAsync":
          fields[0].value = `${params.amount} ${params.currency}`;
          fields[1].value = isPreAuth ? "Preautorizado" : "Aprobado";
          fields[2].products = params.products;
          fields[3].value = defaultTo(ticketNumberAsync, "");
          break;
      }
    }
    setInformation(fields);
  };

  const getGAValues = (paymentMethodForm: string) => {
    switch (paymentMethodForm) {
      case "card":
        setGAEvent(
          GA_CONSTANTS.cardTransactionSummary.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.cardTransactionSummary.labels.A14
        );
        break;
      case "transfer":
        setGAEvent(
          GA_CONSTANTS.purchaseSummary.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.purchaseSummary.labels.B11
        );
        break;
      case "cash":
        setGAEvent(
          GA_CONSTANTS.purchaseSummaryPayment.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.purchaseSummaryPayment.labels.C9
        );
        break;
    }
  };

  const handleDownloadFile = () => {
    getGAValues(paymentMethod);
    setLoading(true);
    let trxReference: string;
    let merchantId: string;

    switch (paymentMethod) {
      case "cash":
        const response: SuccessCharge = get(history, "location.state.response");
        window.open(response.cashPinUrl);
        setLoading(false);
        break;
      case "card":
      case "stp":
        trxReference = get(
          history,
          "location.state.response.transactionReference"
        );
        merchantId = get(history, "location.state.response.merchantId");
        props.getReceiptUrl(merchantId, trxReference);
        break;
      default:
        trxReference = get(props, "firebaseInfo.transactionReference");
        merchantId = get(props, "firebaseInfo.publicMerchantId");
        props.getReceiptUrl(merchantId, trxReference);
    }
  };

  const handleBackMerchant = () => {
    setGAEvent(
      GA_CONSTANTS.rateOfReturn.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.rateOfReturn.labels.G1
    );

    window.location.replace(redirectUrl);
  };

  const isPreAuth =
    get(
      history,
      "location.state.transactionType",
      useParams().get("transactionType")
    ) === TransactionTypeEnum.PRE_AUTH;

  const isDeferred = get(history, "location.state.isActive");

  return {
    header,
    information,
    isMobile,
    loading,
    loadingWS,
    handler: {
      handleDownloadFile,
      handleBackMerchant,
    },
    lengthColumn,
    config,
    paymentMethod,
    isPreAuth,
    isDeferred,
    isCardAsync,
    isServiceSTP,
    kindWebcheckout,
    redirectTimer,
  };
};
