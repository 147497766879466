import React, { RefObject } from "react";
import { FormInput } from "../FormInput/FormInput";
import { FormSelect, SelectItem } from "../FormSelect/FormSelect";
import { FormToggleButtonGroup } from "../FormToggleButtonGroup/FormToggleButtonGroup";
import { FileText, Mail, User } from "react-feather";
import { InputAdornment, TextFieldProps } from "@material-ui/core";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { Translate as T } from "react-localize-redux";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";
import { TextMaskCustom } from "../../CardFormCard/commons/TextMaskCustom";
import {
  CategoryDocument,
  TypeDocuments,
} from "../../../shared/service/CountryCatalog";
import { get } from "lodash";
import { maskBuildAlphaNumeric } from "../../../shared/utils/MaskBuildDocumentType";
import { FieldMaskTypeEnum } from "../../../shared/infrastructure/FieldMaskTypeEnum";

export interface IForm {
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
  };
}

type KshInputProps = IForm & TextFieldProps;
type KshSelectProps = IForm & {
  items: SelectItem[];
  defaultValue?: string;
  label?: string;
};
type KshUserTypeProps = IForm & { defaultValue?: string };

const getCategory = (props: KshInputProps) =>
  props.label
    ? GA_CONSTANTS.dataForCash.category
    : GA_CONSTANTS.dataForTransfer.category;

export const NameInput: React.FC<KshInputProps> = (props: KshInputProps) => {
  const label = props.label
    ? GA_CONSTANTS.dataForCash.labels.C1
    : GA_CONSTANTS.dataForTransfer.labels.B4;

  return (
    <T>
      {({ translate }) => (
        <FormInput
          {...props}
          onClick={() =>
            setGAEvent(getCategory(props), GA_CONSTANTS.actions.click, label)
          }
          form={props.form}
          name={"name"}
          placeholder={translate("form.name") as string}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <User color={"#6D7781"} size={16} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </T>
  );
};

export const DescriptionInput: React.FC<KshInputProps> = (
  props: KshInputProps
) => {
  return (
    <T>
      {({ translate }) => (
        <FormInput
          {...props}
          form={props.form}
          name={"description"}
          placeholder={"Descripción"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FileText color={"#6D7781"} size={16} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </T>
  );
};

export const EmailInput: React.FC<KshInputProps> = (props: KshInputProps) => {
  const label = props.label
    ? GA_CONSTANTS.dataForCash.labels.C4
    : GA_CONSTANTS.dataForTransfer.labels.B7;

  return (
    <T>
      {({ translate }) => (
        <FormInput
          {...props}
          onClick={() =>
            setGAEvent(getCategory(props), GA_CONSTANTS.actions.click, label)
          }
          form={props.form}
          name={"email"}
          placeholder={translate("form.email") as string}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail color={"#6D7781"} size={16} />
              </InputAdornment>
            ),
          }}
          inputRef={props.form.register({
            required: <T id="form.fieldRequired" />,
            pattern: {
              value: new RegExp("^[\\w-.]+@([\\w-]+\\.)+[\\w-]+$"),
              message: translate("form.emailInvalid") as string,
            },
          })}
        />
      )}
    </T>
  );
};

export const UserTypeSelect: React.FC<KshUserTypeProps> = (
  props: KshUserTypeProps
) => {
  const items: SelectItem[] = [
    {
      value: "0",
      label: <T id="naturalPerson" />,
    },
    {
      value: "1",
      label: <T id="legalPerson" />,
    },
  ];
  return (
    <T>
      {({ translate }) => (
        <FormToggleButtonGroup
          defaultValue={props.defaultValue}
          name={"type"}
          items={items}
          form={props.form}
          label={translate("personType") as string}
        />
      )}
    </T>
  );
};

export const IdentificationSelect: React.FC<KshSelectProps> = (
  props: KshSelectProps
) => {
  return (
    <FormSelect
      defaultValue={props.defaultValue}
      name={"identificationType"}
      form={props.form}
      items={props.items}
      label={props.label}
    />
  );
};

export const IdentificationInput: React.FC<KshInputProps> = (
  props: KshInputProps
) => {
  const label = props.label
    ? GA_CONSTANTS.dataForCash.labels.C3
    : GA_CONSTANTS.dataForTransfer.labels.B6;

  const maskBuild = maskBuildAlphaNumeric(
    [
      CategoryDocument["CURP"],
      CategoryDocument["RFC"],
      CategoryDocument["Pasaporte"],
      CategoryDocument["PAS"],
    ].includes(
      get(
        props.form.control.getValues(),
        "identificationType",
        ""
      ) as TypeDocuments
    ),
    FieldMaskTypeEnum.TextFieldForm
  );

  return (
    <T>
      {({ translate }) => (
        <FormInput
          {...props}
          onClick={() =>
            setGAEvent(getCategory(props), GA_CONSTANTS.actions.click, label)
          }
          form={props.form}
          name={"identification"}
          placeholder={translate("form.identificationNumber") as string}
          inputRef={props.form.register({
            required: <T id="form.fieldRequired" />,
          })}
          InputProps={{
            inputComponent: TextMaskCustom as any,
            inputProps: {
              mask: maskBuild,
            },
          }}
        />
      )}
    </T>
  );
};
