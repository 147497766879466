import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";
import theme from "../../theme";
import success from "../../assets/SuccessIcon.svg";

const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    lineHeight: "120%",
    textAlign: "center",
    color: "#293036",
  },
  description: {
    fontSize: "16px",
    lineHeight: "150%",
    fontWeight: 400,
    textAlign: "center",
    color: "#293036",
  },
  mainBoxStyle: {
    position: "relative",
    width: "100%",
    height: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContentPadding: {
    paddingTop: "55px",
    paddingBottom: "55px !important",
  },
}));

export const NotRequiredOtp = () => {
  const classes = useStyle();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.mainBoxStyle}>
      <Box maxWidth={isMobile ? "90%" : "40%"}>
        <Card>
          <CardContent className={classes.cardContentPadding}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img src={success} alt={success} />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "26px" }}>
                  <Typography variant={"h2"} className={classes.title}>
                    <T id={"emailRedirectForOTP.title"} />
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                  <Typography className={classes.description}>
                    <T id={"emailRedirectForOTP.description"} />
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "16px" }}>
                  <Typography className={classes.description}>
                    <T id={"emailRedirectForOTP.message"} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default NotRequiredOtp;
