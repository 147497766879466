import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { ReactComponent as WrongIcon } from "../../assets/images/wrongOtp.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../store/reducer";

const useStyle = makeStyles({
  title: {
    fontWeight: 500,
    lineHeight: "120%",
    alignItems: "center",
    color: "#293036",
  },
  description: {
    lineHeight: "150%",
    textAlign: "center",
    color: "#293036",
    margin: "0px 20px",
  },
  btn: {
    border: "0.5px solid #CBD5E0",
    boxSizing: "border-box",
    borderRadius: "4px",
    marginBottom: 50,
  },
  btnLabel: {
    fontWeight: 500,
    lineHeight: "140%",
    color: "#023365",
    margin: "10px 50px",
  },
});

export const WrongOtpIndex: React.FC = () => {
  const classes = useStyle();
  const redirectUrl = useSelector(
    (state: IWebcheckoutState) => state.webcheckout!.redirectURL
  );
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "85%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box width={{ xs: "100%", sm: "70%", md: "50%" }}>
        <Card>
          <CardContent>
            <Box style={{ left: "50%", top: "50%" }}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <WrongIcon
                      style={{
                        marginTop: 50,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant={"h2"} className={classes.title}>
                    Verificación de datos fallida
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant={"h6"} className={classes.description}>
                    No se ha podido realizar la verificación de tu información.
                  </Typography>
                  <Typography
                    variant={"h6"}
                    className={classes.description}
                    style={{
                      marginTop: 20,
                    }}
                  >
                    El pago ha sido cancelado.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    className={classes.btn}
                    onClick={() => window.location.replace(redirectUrl)}
                  >
                    <Typography variant={"h6"} className={classes.btnLabel}>
                      <T id="backStore" />
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default WrongOtpIndex;
