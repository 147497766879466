import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
} from "@material-ui/core";
import { IWebcheckoutState } from "../../store/reducer";
import {
  getInfoFirebase,
  getReceiptUrl,
  IWebcheckoutAction,
} from "../../store/actionCreators";
import { useSummaryState } from "./useSummaryState/useSummaryState";
import { HeaderSummary } from "../../components/HeaderSummary/HeaderSummary";
import { DetailTransactionSummary } from "../../components/DetailTransactionSummary/DetailTransactionSummary";
import { Download } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { CheckOutResume } from "../../../types/checkout_resume";
import { Translate as T } from "react-localize-redux";
import { StringUtils } from "../../shared/utils/StringUtils";
import { WebcheckoutTypeEnum } from "../../shared/infrastructure/WebcheckoutTypeEnum";
import { RedirectTimer } from "../../components/RedirectTimer/RedirectTimer";
import { useRedirectTimerState } from "../../components/RedirectTimer/state/useRedirectTimerState";
import { defaultRedirectTimeout } from "../../shared/constants/Timer";

export interface TransactionSummaryPropsFuncs {
  getReceiptUrl: (merchantId: string, transactionReference: string) => void;
  getInfoFirebase: (token: string, resumeType: string) => void;
}
export interface TransactionSummaryPropsState {
  receipt?: { url: string };
  firebaseInfo?: CheckOutResume;
  merchantId?: string;
  loadingReceipt?: boolean;
}
export type TransactionSummaryIndexProps = TransactionSummaryPropsFuncs &
  TransactionSummaryPropsState;

export const TransactionSummaryIndex: React.FC<TransactionSummaryIndexProps> = (
  props: TransactionSummaryIndexProps
) => {
  const {
    header,
    information,
    isMobile,
    handler,
    loading,
    lengthColumn,
    config,
    paymentMethod,
    isPreAuth,
    isDeferred,
    loadingWS,
    isCardAsync,
    isServiceSTP,
    kindWebcheckout,
  } = useSummaryState(props);

  const [fontColor, lightenColor]: string[] = StringUtils.getContrastYIQ(
    config.color
  );

  const useStyles = makeStyles((theme) => ({
    buttonBack: {
      minWidth: !isMobile ? 240 : "auto",
      backgroundColor: "transparent",
      borderColor: "#CBD5E0",
    },
    buttonDownload: {
      backgroundColor: config.color,
      color: fontColor,
      "&:hover": {
        backgroundColor: lightenColor,
        color: fontColor,
      },
    },
    gridContainerHeader: {
      paddingBottom: `${theme.spacing(1.25)}px !important`,
    },
    paddingTopOf1: {
      paddingTop: `${theme.spacing(0.125)}px !important`,
    },
  }));
  const classes = useStyles();
  const redirectTime = defaultRedirectTimeout;

  const { minutes, seconds, show } = useRedirectTimerState({
    redirectTime,
    activate: true,
  });

  return loadingWS ? (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100vw"}
      height={"75vh"}
    >
      <CircularProgress />
    </Box>
  ) : (
    <React.Fragment>
      <Container>
        <Box display={"flex"} justifyContent={"center"} pt={5} pb={5} mb={5}>
          <Card style={{ maxWidth: isMobile ? "90%" : "900px" }}>
            <CardContent>
              <Grid
                container
                justify={"center"}
                spacing={7}
                component={Box}
                p={2}
              >
                {show && (
                  <RedirectTimer
                    second={seconds}
                    minute={minutes}
                    shows={show}
                  />
                )}
              </Grid>
              <Grid
                container
                justify={"center"}
                spacing={7}
                component={Box}
                p={2}
              >
                <Grid item xs={12} className={classes.gridContainerHeader}>
                  <HeaderSummary
                    title={header.title}
                    message={header.message}
                    isMobile={isMobile}
                    paymentMethod={paymentMethod}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={3}
                  className={classes.paddingTopOf1}
                >
                  <DetailTransactionSummary
                    lengthColumnNumber={lengthColumn}
                    information={information}
                    isMobile={isMobile}
                  />
                  <Grid item xs={12} hidden={isCardAsync || isServiceSTP}>
                    {!isPreAuth && !isDeferred && (
                      <Box display={"flex"} justifyContent={"center"} pt={3}>
                        <Button
                          size={"large"}
                          startIcon={
                            loading ? (
                              <CircularProgress size={16} color={"primary"} />
                            ) : (
                              <Download color={fontColor} size={14} />
                            )
                          }
                          variant={"contained"}
                          className={classes.buttonDownload}
                          onClick={handler.handleDownloadFile}
                          disabled={loading}
                        >
                          {loading ? (
                            <T id="generatingConfirmation" />
                          ) : (
                            <T id="downloadConfirmation" />
                          )}
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  {kindWebcheckout !== WebcheckoutTypeEnum.EXPRESS_CHECKOUT && (
                    <Grid item xs={12} className={classes.paddingTopOf1}>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        pt={isCardAsync ? 3 : 0}
                      >
                        <Button
                          className={classes.buttonBack}
                          size={"large"}
                          variant={"outlined"}
                          color={"primary"}
                          disabled={loading}
                          onClick={handler.handleBackMerchant}
                        >
                          <T id="backStore" />
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export const mapDispatchToProps: (
  dispatch: Dispatch
) => TransactionSummaryPropsFuncs = (
  dispatch: ThunkDispatch<IWebcheckoutState, undefined, IWebcheckoutAction>
): TransactionSummaryPropsFuncs => ({
  getReceiptUrl: (merchantId: string, transactionReference: string): void =>
    dispatch(getReceiptUrl(merchantId, transactionReference)),
  getInfoFirebase: (token: string, resumeType: string): void =>
    dispatch(getInfoFirebase(token, resumeType)),
});

export const mapStateToProps: (
  state: IWebcheckoutState
) => TransactionSummaryPropsState = (
  state: IWebcheckoutState
): TransactionSummaryPropsState => ({
  receipt: state.receipt,
  firebaseInfo: state.firebaseInfo,
  merchantId: state.merchantId,
  loadingReceipt: state.loading,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionSummaryIndex);
