import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { Clock } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { IWebcheckoutState } from "../../store/reducer";
import { connect } from "react-redux";
import { Translate as T } from "react-localize-redux";

export interface TimerSessionState {
  timer?: { endTime: number };
}

export interface TimerAttributes {
  minute?: string;
  second?: string;
  warn?: boolean;
  shows?: boolean;
}
export type TimerSessionProps = TimerSessionState & TimerAttributes;
const useStyles = makeStyles(() => ({
  warningBox: {
    borderColor: "#AD0C2A",
    borderStyle: "solid",
    borderWidth: "1px",
  },
  fontWarning: {
    fontFamily: "IBM Plex Sans,sans-serif",
    color: "#AD0C2A",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  fontNormal: {
    color: "#6D7781",
    fontFamily: "IBM Plex Sans,sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  timeWarning: {
    color: "#AD0C2A",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  timeNormal: {
    color: "#6D7781",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
}));
export const TimerSession: React.FC<TimerSessionProps> = (
  props: TimerSessionProps
) => {
  const classes = useStyles();
  return props.shows ? (
    <React.Fragment>
      <Box
        display={"flex"}
        component={Paper}
        width={{ xs: "100%", md: "50%" }}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"nowrap"}
        className={props.warn ? classes.warningBox : undefined}
      >
        <Box p={1}>
          <Clock size={18} color={props.warn ? "#ad0c2a" : "#6D7781"} />
        </Box>
        <Box p={1}>
          <Typography
            className={props.warn ? classes.fontWarning : classes.fontNormal}
          >
            <T id="messageTimeSession" />
            <span
              className={props.warn ? classes.timeWarning : classes.timeNormal}
            >
              {props.minute}
            </span>
            {" : "}
            <span
              className={props.warn ? classes.timeWarning : classes.timeNormal}
            >
              {props.second}
            </span>
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  ) : null;
};

export const mapStateToProps: (
  state: IWebcheckoutState
) => TimerSessionState = (state: IWebcheckoutState): TimerSessionState => ({
  timer: state.timer,
});

export default connect(mapStateToProps)(TimerSession);
