import { ICardFormCard } from "./interfaces/ICardFormCard";
import { TCardFormCardProps } from "../CardFormCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";
import { TransactionTypeEnum } from "../../../shared/infrastructure/TransactionTypeEnum";
import { formatAmount } from "../../PurchaseDetail/PurchaseDetail";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { Translate as T } from "react-localize-redux";
import { get, isEmpty } from "lodash";
import { ProcessorsNameEnum } from "../../../shared/infrastructure/ProcessorsNameEnum";

export interface ICardFormCardState {
  showSiftForm: boolean | undefined;
  showAmount: boolean;
  actions: {
    handleSubmitForm: () => void;
  };
  checkCardTitleLabel: () => JSX.Element;
}

export type TCardFormCard = ICardFormCard;

export const getValuePayButton = (
  showAmount: boolean | undefined,
  totalAmount: number,
  currency: string
): string => {
  if (!Boolean(showAmount)) return "";

  return " " + formatAmount(totalAmount, currency);
};

export const useCardFormCardState = (
  props: TCardFormCardProps
): ICardFormCardState => {
  const siftScienceEnable = useSelector(
    (state: IWebcheckoutState) => state.merchantSiftScience?.enabled
  );
  const [showSiftForm, setShowSiftForm] = useState(siftScienceEnable);
  const [showAmount, setShowAmount] = useState(false);

  /**
   * Hook actions
   */
  const handleSubmitForm = () => {
    props.form.handleOnSubmitForm();
  };

  const checkCardTitleLabel = () => {
    if (props.webcheckout!.country === CountryEnum.CHL) {
      const paymentMethod = props.webcheckout?.paymentConfig.paymentMethod;
      const hasKushkiProcessor = !isEmpty(
        get(props, "settings.processors.card", []).filter(
          (processor: { processorName: string }) =>
            get(processor, "processorName", "") ===
            ProcessorsNameEnum.KUSHKI_PROCESSOR
        )
      );
      const hasCreditCard =
        paymentMethod && paymentMethod.includes("credit-card");
      const hasCardAsync =
        paymentMethod && paymentMethod.includes("card-async");

      if (hasKushkiProcessor)
        return <T id="listPaymentMethods.titleCardAndDebit" />;
      if (hasCreditCard && !hasCardAsync)
        return <T id="listPaymentMethods.titleCardAndDebit" />;
      if (hasCreditCard && hasCardAsync)
        return <T id="listPaymentMethods.titleCard" />;

      return <T id="listPaymentMethods.titleCard" />;
    }

    return <T id="listPaymentMethods.titleCardAndDebit" />;
  };

  useEffect(() => {
    setShowSiftForm(
      siftScienceEnable &&
        props.webcheckout &&
        props.webcheckout.transactionType === TransactionTypeEnum.SALE
    );
  }, [siftScienceEnable]);

  const verifyIfIsLoadedWebcheckout = () =>
    Boolean(props.webcheckout) && Boolean(props.webcheckout!.configuration);

  useEffect(() => {
    if (verifyIfIsLoadedWebcheckout()) {
      setShowAmount(
        props.webcheckout !== undefined &&
          Object.entries(props.webcheckout!.configuration).length === 0
      );
    }
  }, [props.webcheckout]);

  return {
    actions: {
      handleSubmitForm,
    },
    checkCardTitleLabel,
    showSiftForm,
    showAmount,
  };
};
