import React from "react";
import { Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  overlay: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1000000,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7FAFC",
  },
  overlayContent: {
    left: "50%",
    top: "50%",
  },
  overlayTitle: {
    fontStyle: "normal",
    fontWeight: 200,
    color: "#293036",
    marginBottom: 10,
  },
  overlaySubtitle: {
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 300,
    marginBottom: 20,
    textAlign: "center",
  },
});

export interface ITransferLoadingCardProps {
  mainLine: any;
  secondaryLine: any;
}

export const TransferLoadingCard: React.FC<ITransferLoadingCardProps> = (
  props: ITransferLoadingCardProps
) => {
  const classes = useStyle();
  return (
    <Card className={classes.overlay}>
      <div className={classes.overlayContent}>
        <div
          style={{
            textAlign: "center",
            paddingBottom: 40,
          }}
        >
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={"spin"}
          >
            <circle cx="40" cy="40" r="35" stroke="#F0F4F9" strokeWidth="10" />
            <path
              d="M40 5C20.67 5 5 20.67 5 40C5 59.33 20.67 75 40 75C55.6717 75 68.9378 64.6999 73.3977 50.5"
              stroke={"#00E6B2"}
              strokeWidth="10"
            />
          </svg>
        </div>
        <Typography className={classes.overlaySubtitle}>
          {props.mainLine}
        </Typography>
        <Typography className={classes.overlaySubtitle}>
          {props.secondaryLine}
        </Typography>
      </div>
    </Card>
  );
};

export default TransferLoadingCard;
