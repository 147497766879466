import React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import { Control } from "react-hook-form";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";

export interface SelectItem {
  value: string;
  label: any;
}

export interface FormSelectProps {
  name: string;
  items: SelectItem[];
  form: {
    control: Control<Record<string, any>>;
  };
  defaultValue?: string;
  label?: string;
}

export const renderSelect = (items: SelectItem[]) => (
  props: any
): JSX.Element => (
  <Select {...props} style={{ color: "#6D7781" }}>
    {items.map((item: { value: string; label: string }, index: number) => (
      <MenuItem value={item.value} key={index}>
        {item.label}
      </MenuItem>
    ))}
  </Select>
);

export const FormSelect: React.FC<FormSelectProps> = (
  props: FormSelectProps
) => {
  const setGAValues = (props: FormSelectProps) => {
    if (props.label) {
      setGAEvent(
        GA_CONSTANTS.dataForCash.category,
        GA_CONSTANTS.actions.click,
        GA_CONSTANTS.dataForCash.labels.C2
      );
    } else {
      setGAEvent(
        GA_CONSTANTS.dataForTransfer.category,
        GA_CONSTANTS.actions.click,
        props.name === "bank"
          ? GA_CONSTANTS.dataForTransfer.labels.B1
          : GA_CONSTANTS.dataForTransfer.labels.B5
      );
    }
  };

  const defaultValue: string = props.defaultValue
    ? props.defaultValue
    : props.items[0]
    ? props.items[0].value
    : "";
  const TypedController = useTypedController({ control: props.form.control });

  return (
    <React.Fragment>
      <FormControl
        fullWidth
        variant="outlined"
        onClick={() => setGAValues(props)}
      >
        <TypedController
          name={[props.name]}
          rules={{ required: true }}
          defaultValue={defaultValue}
          render={renderSelect(props.items)}
        />
      </FormControl>
    </React.Fragment>
  );
};
