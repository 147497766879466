import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "1px",
      height: "1px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  title: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    textTransform: "none",
    color: "#112B45",
  },
  textContent: {
    display: "flex",
    alignItems: "center",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  toggleGroup: {
    width: "100%",
    height: "70px",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100px",
    },
  },
}));
