import React from "react";
import { Translate as T } from "react-localize-redux";
import LanguageModalComponent from "../LanguageModal/LanguageModal";
import { useToggleLanguageState } from "./state/useToggleLanguage";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  changeLanguage: {
    marginTop: "1rem",
    cursor: "pointer",
    color: "#023365",
    fontSize: "14px",
    background: "none",
    border: "0",
    font: "inherit",
    lineHeight: "normal",
    overflow: "visible",
    padding: "0",
    webkitUserSelect: "none",
    webkitAppearance: "button",
    MozUserSelect: "none",
    margin: "10px",
  },
});

const ToggleLanguage = () => {
  const { displayModal, handler } = useToggleLanguageState();
  const classes = useStyle();

  return (
    <div className="toggle-language">
      <div id="language-modal">
        <LanguageModalComponent
          handleExit={handler.handleCloseLanguageModal}
          open={displayModal}
        />
      </div>
      <button
        id="language-button"
        className={classes.changeLanguage}
        onClick={handler.handleOpenLanguageModal}
      >
        <T id="footer.changeLanguage" />
      </button>
    </div>
  );
};
export default ToggleLanguage;
