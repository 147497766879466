import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";

export interface UseTimerSessionState {
  minutes: string;
  seconds: string;
  show: boolean;
}

export interface RedirectTimerProps {
  redirectTime: number;
  activate: boolean;
}

export const useRedirectTimerState = (
  props: RedirectTimerProps
): UseTimerSessionState => {
  const [timeLeft, setTimeLeft] = useState(props.redirectTime);
  const [show, setShow] = useState(false);

  const automaticRedirection = useSelector(
    (state: IWebcheckoutState) =>
      state.webcheckout?.configuration.automaticRedirection
  );
  const redirectUrl = useSelector(
    (state: IWebcheckoutState) => state.webcheckout?.redirectURL
  );

  useEffect(() => {
    if (!props.activate) {
      return;
    }

    if (!automaticRedirection) {
      setShow(false);
      return;
    }

    setShow(true);

    if (timeLeft <= 0) {
      if (automaticRedirection && redirectUrl) {
        window.location.replace(redirectUrl);
      }
      return;
    }

    const timerId = setTimeout(() => {
      setTimeLeft(timeLeft - 1000);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [timeLeft, automaticRedirection, redirectUrl]);

  const minutes = String(Math.floor(timeLeft / 60000)).padStart(2, "0");
  const seconds = String(Math.floor((timeLeft % 60000) / 1000)).padStart(
    2,
    "0"
  );

  return { minutes, seconds, show };
};
