import React from "react";

const MainLoading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={"spin"}
      >
        <circle cx="40" cy="40" r="35" stroke="#F0F4F9" strokeWidth="10" />
        <path
          d="M40 5C20.67 5 5 20.67 5 40C5 59.33 20.67 75 40 75C55.6717 75 68.9378 64.6999 73.3977 50.5"
          stroke="#000000"
          strokeWidth="10"
        />
      </svg>
      <p style={{ fontSize: "44px", marginTop: "8px" }}>
        We are working on it...
      </p>
    </div>
  );
};

export default MainLoading;
