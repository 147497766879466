import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "IBM Plex Sans,sans-serif",
    fontSize: "25px",
    lineHeight: "141%",
    fontStyle: "normal",
    color: "#293036",
    fontWeight: 300,
    alignItems: "flex-end",
  },
  gridItem: {
    paddingBottom: theme.spacing(2),
  },
  cardContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingInline: theme.spacing(3),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  listPaymentMethodsLoader: {
    height: "100%",
  },
}));

export default useStyles;
