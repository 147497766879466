import React, { RefObject } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { HeaderPayment } from "../../HeaderPayment/HeaderPayment";
import card from "../../../assets/logos/card.svg";
import { TermsConditions } from "../../TermsConditions/TermsConditions";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { SelectOption } from "../../../containers/state/useMainState";
import { LoadingCard } from "../../LoadingCard/LoadingCard";
import {
  getValuePayButton,
  useCardFormCardState,
} from "../state/useCardFormCardState";
import { IWebcheckoutState } from "../../../store/reducer";
import { connect } from "react-redux";
import {
  BillingDetails,
  SmartLink,
  Webcheckout,
} from "../../../../types/webcheckout";
import type { MerchantSettingsResponse } from "@kushki/js/lib/types/merchant_settings_response";
import { Translate as T, TranslateFunction } from "react-localize-redux";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";
import pci from "../../../assets/images/pci.svg";
import { get, isEmpty } from "lodash";
import { StringUtils } from "../../../shared/utils/StringUtils";
import { ISiftscienceForm } from "../state/interfaces/ISiftScienceForm";
import { EmailInput } from "../../commons/FormCardChildren/FormCardChildren";
import { BrandsCardAsync } from "../../../shared/constants/BrandsCardAsync";

export interface AsyncCardFormCardStateProps {
  webcheckout?: SmartLink | Webcheckout;
  settings?: MerchantSettingsResponse;
  webcheckoutEmail?: string;
}

export interface AsyncCardFormCardProps {
  logos: { image: string; value: string }[];
  paymentMethodForm: string;
  isMobile: boolean;
  handleChangePaymentMethod: () => void;
  handleSaveMethod: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openTerms: boolean;
  handleClose: () => void;
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    handleSubmit: any;
    getValues: (field: string) => any;
    handleOnSubmitForm: () => void;
    control: Control<Record<string, any>>;
    currentValues: {
      months: string;
      monthsOfGrace: string;
      typeDeferred: string;
    };
  };
  color?: string;
  secondaryColor?: string;
  buttonStyle?: string;
  optionsDeferred?: string[];
  handleSetFields: (kind: string, value?: string) => void;
  currency: string;
  totalAmount: number;
  brandCardInput?: string;
  typeCardInput?: string;
  deferredOptions?: {
    hasDeferred?: boolean;
    isDynamic?: boolean;
    typesDeferred?: SelectOption[];
    months?: SelectOption[];
    monthsOfGrace?: SelectOption[];
    isActive?: boolean;
  };
  loading?: boolean;
  translate: TranslateFunction;
  siftscienceForm?: ISiftscienceForm;
  isValidDate: (expDate: string) => boolean;
  isValidCVV: (cvv: string) => boolean;
  billingDetails?: BillingDetails;
}

export type TAsyncCardFormCardProps = AsyncCardFormCardProps &
  AsyncCardFormCardStateProps;

export const AsyncCardFormCard: React.FC<TAsyncCardFormCardProps> = (
  props: TAsyncCardFormCardProps
) => {
  const getBorderRadious = () => {
    if (props.buttonStyle === "round") {
      return "20px";
    }
    if (props.buttonStyle === "semi") {
      return "12px";
    }
    return "2px";
  };

  const { secondaryColor = "#000000" } = props;
  const [fontColor, lightenColor]: [
    string,
    string
  ] = StringUtils.getContrastYIQ(secondaryColor);

  const useStyles = makeStyles((theme) => ({
    root: {
      "&$checked": {
        color: props.color,
      },
      "& a": {
        color: props.color,
      },
    },
    checked: {},
    link: {
      color: props.color,
      fontSize: "14px",
    },
    infoLabel: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: "#6D7781",
      margin: "0px 6.16573px",
      display: "flex",
      marginTop: "5px",
      alignItems: "flex-end",
      paddingBottom: 8,
    },
    pciIcon: {
      position: "relative",
      paddingLeft: "5px",
      paddingRight: "10px",
    },
    label: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal",
      color: "#293036",
    },
    button: {
      borderRadius: getBorderRadious(),
      backgroundColor: props.secondaryColor,
      color: fontColor,
      "&:hover": {
        backgroundColor: lightenColor,
        color: fontColor,
      },
    },
    poweredContainer: {
      marginTop: "25px",
      fontSize: "9.24px",
      textTransform: "uppercase",
    },
    poweredTitle: {
      marginRight: "7px",
      fontStyle: "normal",
      color: "#184661",
      display: "inline",
    },
    readMore: {
      position: "relative",
      paddingLeft: "5px",
      paddingRight: "10px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      color: "#6D7781",
    },
    spacing: {
      marginBottom: theme.spacing(1),
    },
    placeholder: {
      "& input": {
        color: "#6D7781",
      },
    },
    header: {
      paddingRight: `${theme.spacing(2)}px !important`,
      paddingLeft: `${theme.spacing(2)}px !important`,
      marginTop: theme.spacing(2),
    },
    body: {
      paddingRight: `${theme.spacing(3)}px !important`,
      paddingLeft: `${theme.spacing(3)}px !important`,
    },
    blurry: {
      WebkitFilter: "blur(5px)",
      msFilter: "blur(5px)",
      filter: "blur(5px)",
      userSelect: "none",
    },
  }));
  const classes = useStyles();
  const { actions, showSiftForm } = useCardFormCardState(props);
  const showAmount =
    props.webcheckout !== undefined &&
    Object.entries(props.webcheckout!.configuration).length === 0;

  return props.paymentMethodForm === "cardAsync" ? (
    <React.Fragment>
      <Card
        hidden={props?.siftscienceForm?.open}
        style={{
          position: "relative",
        }}
      >
        <CardContent>
          <form onSubmit={props.form.handleSubmit(actions.handleSubmitForm)}>
            {props.loading && <LoadingCard color={props.color} />}
            <Box
              width={"100%"}
              height={"100%"}
              className={props.loading ? classes.blurry : ""}
            >
              <Grid item className={classes.header}>
                <HeaderPayment
                  title={
                    props.webcheckout && (
                      <T id="listPaymentMethods.titleDebit" />
                    )
                  }
                  titleLogo={card}
                  isMobile={props.isMobile}
                  logos={BrandsCardAsync.logos}
                  handleChangePaymentMethod={props.handleChangePaymentMethod}
                />
              </Grid>

              <Grid item container spacing={2} className={classes.body}>
                <Grid item xs={12} className={classes.spacing}>
                  <Typography variant={"caption"}>
                    <T id="messagePCI" />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={2} className={classes.body}>
                <Grid item xs={12} className={classes.spacing}>
                  <EmailInput
                    form={props.form}
                    defaultValue={
                      props.webcheckout && props.webcheckout.contactDetail
                        ? props.webcheckout.contactDetail.email
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl error={!!props.form.errors.terms}>
                    <FormControlLabel
                      labelPlacement={"end"}
                      label={
                        <Typography className={classes.label}>
                          <T id="agree" />
                          <Link
                            onClick={props.handleClose}
                            className={classes.link}
                          >
                            <T id="terms" />
                          </Link>
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onClick={() =>
                            setGAEvent(
                              GA_CONSTANTS.dataForCreditCard.category,
                              GA_CONSTANTS.actions.click,
                              GA_CONSTANTS.dataForCreditCard.labels.A6
                            )
                          }
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                          color={"primary"}
                        />
                      }
                      name={"terms"}
                      inputRef={props.form.register({
                        required: true,
                      })}
                    />
                    {props.form.errors.terms && (
                      <FormHelperText>
                        <T id="termsConditions" />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Box hidden={props.webcheckout!.country !== "Peru"}>
                  <Typography className={classes.infoLabel}>
                    <img className={classes.pciIcon} src={pci} alt={pci} />
                    <Typography className={classes.readMore}>
                      <T id="footer.messagePay" />
                    </Typography>
                    <Link
                      rel={"noreferrer"}
                      target={"_blank"}
                      href={
                        "https://kushkipagos.com/en/blog/certificacion-pci-pagos-mas-seguros-para-tus-clientes-y-tu-negocio"
                      }
                      style={{
                        color: "#6D7781",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <T id="footer.readMore" />
                      </Typography>
                    </Link>
                  </Typography>
                </Box>

                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button
                      type="submit"
                      variant={"contained"}
                      className={classes.button}
                      onClick={() =>
                        setGAEvent(
                          GA_CONSTANTS.dataForCreditCard.category,
                          GA_CONSTANTS.actions.click,
                          GA_CONSTANTS.dataForCreditCard.labels.A8
                        )
                      }
                    >
                      {!isEmpty(props.webcheckout!.configuration.buttonText) ? (
                        props.webcheckout!.configuration.buttonText
                      ) : (
                        <T id="payButtonWithMount" />
                      )}
                      {getValuePayButton(
                        get(
                          props,
                          "webcheckout.configuration.showAmount",
                          false
                        ) || showAmount,
                        props.totalAmount,
                        props.currency
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </CardContent>
        <TermsConditions
          handleClose={props.handleClose}
          open={props.openTerms}
          isMobile={props.isMobile}
        />
      </Card>
    </React.Fragment>
  ) : null;
};

export const mapStateToProps: (
  state: IWebcheckoutState
) => AsyncCardFormCardStateProps = (
  state: IWebcheckoutState
): AsyncCardFormCardStateProps => ({
  webcheckout: state.webcheckout,
  settings: state.settings,
  webcheckoutEmail: state.webcheckoutEmail,
});

export default connect(mapStateToProps, null)(AsyncCardFormCard);
