import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { X } from "react-feather";
import { Translate as T } from "react-localize-redux";

export interface TermsConditionsProps {
  isMobile: boolean;
  open: boolean;
  handleClose: () => void;
}
export const TermsConditions: React.FC<TermsConditionsProps> = (
  props: TermsConditionsProps
) => {
  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.handleClose}>
        <MuiDialogTitle disableTypography>
          <Box display={"flex"} alignItems={"center"}>
            <Box flexGrow={1}>
              <Typography>
                <T id="terms" />
              </Typography>
            </Box>
            <Box>
              <IconButton aria-label="close" onClick={props.handleClose}>
                <X size={16} />
              </IconButton>
            </Box>
          </Box>
        </MuiDialogTitle>
        <DialogContent>
          <p>
            <T id="license" />
          </p>
          <br />
          <p>
            <T id="licenseMessage" />
          </p>
          <br />
          <p>
            <T id="clause" />
          </p>
          <br />
          <p>
            <T id="clauseFirstMessage" />
          </p>
          <p>
            <T id="clauseSecondMessage" />
          </p>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
