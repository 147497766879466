import React from "react";
import { FormCard, IFormCardProps } from "../commons/FormCard/FormCard";
import cash from "../../assets/logos/cash.svg";
import { Grid } from "@material-ui/core";
import {
  EmailInput,
  IdentificationInput,
  IdentificationSelect,
  NameInput,
} from "../commons/FormCardChildren/FormCardChildren";
import { Translate as T } from "react-localize-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getValuePayButton } from "../CardFormCard/state/useCardFormCardState";

const useStyles = makeStyles({
  color: {
    "& input, & div": {
      color: "#6D7781",
    },
  },
});

export const CashFormCard: React.FC<IFormCardProps> = (
  props: IFormCardProps
) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormCard
        {...props}
        blurryOnLoad
        headerProps={{
          handleChangePaymentMethod: props.handleChangePaymentMethod,
          isMobile: props.isMobile,
          title: <T id="listPaymentMethods.titleCash" />,
          titleLogo: cash,
          logos: [],
          country: props.country,
        }}
        showAcceptTerms
        submitLabel={getValuePayButton(
          props?.showAmount,
          props.totalAmount,
          props.currency
        )}
        handleSaveMethod={props.handleSaveMethod}
      >
        <Grid container spacing={2} className={classes.color}>
          <Grid item xs={12}>
            <NameInput
              form={props.form}
              defaultValue={props.savedSubscription.name}
            />
          </Grid>
          <Grid item xs={props.isMobile ? 4 : 3}>
            <IdentificationSelect
              form={props.form}
              items={props.identificationType}
            />
          </Grid>
          <Grid item xs={props.isMobile ? 8 : 9}>
            <IdentificationInput
              form={props.form}
              defaultValue={props.savedSubscription.documentNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailInput
              form={props.form}
              defaultValue={props.savedSubscription.email}
            />
          </Grid>
        </Grid>
      </FormCard>
    </React.Fragment>
  );
};
