import React from "react";
import transfer from "../../../assets/logos/transfer.svg";
import { FormCard, IFormCardProps } from "../../commons/FormCard/FormCard";
import { Grid } from "@material-ui/core";
import {
  EmailInput,
  IdentificationInput,
  IdentificationSelect,
  NameInput,
} from "../../commons/FormCardChildren/FormCardChildren";
import { TransferLoadingCard } from "../../TransferLoadingCard/TransferLoadingCard";
import { Translate as T } from "react-localize-redux";
import { TransferPaymentDataResponse } from "../../../../types/payment_data_response";
import { getValuePayButton } from "../../CardFormCard/state/useCardFormCardState";
export const EtpayFormCard: React.FC<IFormCardProps> = (
  props: IFormCardProps
) => {
  const savedTransferPaymentData = props.savedSubscription as TransferPaymentDataResponse;
  return (
    <React.Fragment>
      <FormCard
        {...props}
        headerProps={{
          handleChangePaymentMethod: props.handleChangePaymentMethod,
          isMobile: props.isMobile,
          title: <T id="listPaymentMethods.titleTransfer" />,
          titleLogo: transfer,
        }}
        showAcceptTerms
        submitLabel={getValuePayButton(
          props?.showAmount,
          props.totalAmount,
          props.currency
        )}
      >
        {props.loading ? (
          <TransferLoadingCard
            mainLine={<T id="loadingTCard.mainLine" />}
            secondaryLine={<T id="loadingTCard.secondaryLine" />}
          />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NameInput
              form={props.form}
              defaultValue={savedTransferPaymentData.name}
            />
          </Grid>
          <Grid item xs={props.isMobile ? 4 : 3}>
            <IdentificationSelect
              form={props.form}
              items={props.identificationType}
              defaultValue={savedTransferPaymentData.documentType}
            />
          </Grid>
          <Grid item xs={props.isMobile ? 8 : 9}>
            <IdentificationInput
              form={props.form}
              defaultValue={savedTransferPaymentData.documentNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailInput
              form={props.form}
              defaultValue={savedTransferPaymentData.email}
            />
          </Grid>
        </Grid>
      </FormCard>
    </React.Fragment>
  );
};
