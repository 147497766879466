import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Decline from "../../assets/declinado.svg";
import { useDeclineState } from "./useDeclineState/useDeclineState";
import { Translate as T } from "react-localize-redux";
import { SourceEnum } from "../../shared/infrastructure/SourceEnum";
import { RedirectTimer } from "../../components/RedirectTimer/RedirectTimer";
import { useRedirectTimerState } from "../../components/RedirectTimer/state/useRedirectTimerState";
import { defaultRedirectTimeout } from "../../shared/constants/Timer";

const DeclinePayment = () => {
  const {
    title,
    message,
    handler,
    isExpressCheckout,
    source,
  } = useDeclineState();
  const useStyles = makeStyles({
    button: {
      backgroundColor: "white",
      minWidth: 200,
      minHeight: 36,
      marginBottom: 5,
      fontSize: 16,
    },
  });
  const classes = useStyles();
  const redirectTime = defaultRedirectTimeout;
  const { minutes, seconds, show } = useRedirectTimerState({
    redirectTime,
    activate: source === SourceEnum.SHOPIFY,
  });

  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent={"center"} pt={10}>
        <Container>
          <Grid container component={Box} py={7} justify={"center"}>
            <Grid item container xs={12} justify={"center"}>
              <Box width={{ xs: "100%", sm: "70%", md: "50%" }}>
                <Card>
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {source === SourceEnum.SHOPIFY && (
                        <RedirectTimer
                          second={seconds}
                          minute={minutes}
                          shows={show}
                        />
                      )}
                    </Box>
                    <Grid container spacing={3} component={Box} p={5}>
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"}>
                          <img src={Decline} alt={Decline} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align={"center"} variant={"h2"}>
                          <T id={title} />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align={"center"}>
                          <T id={message} />
                        </Typography>
                      </Grid>
                      {source !== SourceEnum.SHOPIFY && (
                        <Grid item xs={12}>
                          <Box display={"flex"} justifyContent={"center"}>
                            <Button
                              id={"retryBtn"}
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              onClick={handler.handleRetry}
                            >
                              <T id="tryAgain" />
                            </Button>
                          </Box>
                        </Grid>
                      )}
                      {!isExpressCheckout && (
                        <Grid item xs={12}>
                          <Box display={"flex"} justifyContent={"center"}>
                            <Button
                              id={"exitBtn"}
                              className={classes.button}
                              onClick={handler.handleGoToStore}
                            >
                              <T id="backStore" />
                            </Button>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};
export default DeclinePayment;
