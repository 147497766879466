import React, { PropsWithChildren, RefObject } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useTransferCardStyles } from "../../TransferFormCard/commons/styles";
import { LoadingCard } from "../../LoadingCard/LoadingCard";
import {
  HeaderPayment,
  HeaderPaymentProps,
} from "../../HeaderPayment/HeaderPayment";
import { TermsConditions } from "../../TermsConditions/TermsConditions";
// import { CheckInformation } from "../../CheckInformation/CheckInformation";
import { BankList } from "../../../../types/bank_list";
import { SelectItem } from "../FormSelect/FormSelect";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { Translate as T } from "react-localize-redux";
import {
  CashPaymentDataResponse,
  TransferPaymentDataResponse,
} from "../../../../types/payment_data_response";
import { isEmpty } from "lodash";

export interface FormCardChildren {
  headerProps: HeaderPaymentProps;
  showAcceptTerms?: boolean;
  savePaymentData?: boolean;
  submitLabel: string;
  usePayButton?: boolean;
  formData?: any;
}

export interface IFormCardProps {
  showAmount?: boolean;
  buttonText?: string;
  blurryOnLoad?: boolean;
  handleSaveMethod: (event: React.ChangeEvent<HTMLInputElement>) => void;
  logos?: { image: string; value: string }[];
  country: string;
  disablePay: boolean;
  bankList?: BankList;
  visible: boolean;
  loading?: boolean;
  totalAmount: number;
  currency: string;
  color: string;
  secondaryColor: string;
  buttonStyle: string;
  isMobile: boolean;
  handleChangePaymentMethod: () => void;
  handleClose: () => void;
  openTerms: boolean;
  identificationType: SelectItem[];
  handleAcceptTerms: () => void;
  handleSetFields: (kind: string, value?: string) => void;
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    handleSubmit: any;
    handleOnSubmitForm: () => void;
    control: Control<Record<string, any>>;
    currentValues: {
      months: string;
      monthsOfGrace: string;
      typeDeferred: string;
    };
  };
  savedSubscription: TransferPaymentDataResponse | CashPaymentDataResponse;
}

export type FormCardProps = FormCardChildren & IFormCardProps;

export const FormCard: React.FC<FormCardProps> = (
  props: PropsWithChildren<FormCardProps>
) => {
  const classes = useTransferCardStyles(
    props.color,
    props.secondaryColor,
    props.buttonStyle
  );
  return props.visible ? (
    <React.Fragment>
      <Card className={classes.transferCard}>
        {props.blurryOnLoad && props.loading ? (
          <LoadingCard color={props.color} />
        ) : null}
        <CardContent
          className={props.blurryOnLoad && props.loading ? classes.blurry : ""}
        >
          <form
            className={classes.form}
            onSubmit={props.form.handleSubmit(props.form.handleOnSubmitForm)}
          >
            <Grid container item>
              <HeaderPayment {...props.headerProps} />
            </Grid>
            {props.children}
            <Grid container spacing={2}>
              {props.showAcceptTerms ? (
                <Grid item xs={12}>
                  <FormControl>
                    <FormControlLabel
                      labelPlacement={"end"}
                      label={
                        <Typography className={classes.label}>
                          <T id="agree" />
                          <Link
                            onClick={props.handleClose}
                            className={classes.link}
                          >
                            <T id="terms" />
                          </Link>
                        </Typography>
                      }
                      control={
                        <Checkbox
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                          color={"primary"}
                          value={!props.disablePay}
                          onClick={props.handleAcceptTerms}
                        />
                      }
                      name={"terms"}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              {/*<Grid item xs={12}>*/}
              {/*  <CheckInformation*/}
              {/*    handleSaveMethod={props.handleSaveMethod}*/}
              {/*    message={<T id="checkInformationSave" />}*/}
              {/*    isMobile={props.isMobile}*/}
              {/*    register={props.form.register}*/}
              {/*    control={props.form.control}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    type="submit"
                    disabled={props.disablePay && props.showAcceptTerms}
                    variant={"contained"}
                    color={"primary"}
                    className={classes.button}
                  >
                    {!props.usePayButton === undefined &&
                    !isEmpty(props.buttonText) ? (
                      <T id="payButton" />
                    ) : (
                      props.buttonText + " "
                    )}
                    {props.submitLabel}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <TermsConditions
          handleClose={props.handleClose}
          open={props.openTerms}
          isMobile={props.isMobile}
        />
      </Card>
    </React.Fragment>
  ) : null;
};
