import React from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { defaultTo, get, isEmpty } from "lodash";
import { Product } from "../../../types/webcheckout";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";

export interface DetailTrxSummaryProps {
  lengthColumnNumber: 2 | 4 | 6 | 8;
  information: { label: string; value: string; products?: Product[] }[];
  isMobile?: boolean;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#293036",
  },
  label: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "136%",
    color: "#6D7781",
  },
  value: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#293036",
    overflow: "auto",
  },
  products: {
    maxHeight: "80px ",
    overflowY: "auto",
  },
  paddingTopOf16: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  boxItemCenter: {
    padding: "12px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
  },
  contentSummary: {
    display: "flex",
    margin: "0 auto",
    width: "90%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  boxItemDefault: {
    margin: "12px auto",
  },
  labelCenter: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "136%",
    color: "#6D7781",
    textAlign: "center",
  },
}));
export const DetailTransactionSummary: React.FC<DetailTrxSummaryProps> = (
  props: DetailTrxSummaryProps
) => {
  const classes = useStyles();
  const validateProductLength = (props: DetailTrxSummaryProps): boolean => {
    const products: Product = defaultTo(props.information[2], {});

    return (
      get(products, "label") === "purchaseSummaryTable.product" &&
      isEmpty(get(products, "products[0]"))
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.paddingTopOf16}>
        <Typography align={"center"} className={classes.title}>
          <T id="purchaseSummary" />
        </Typography>
      </Grid>
      {props.information && (
        <Grid className={classes.contentSummary}>
          {props.information.map(
            (
              data: { label: string; value: string; products?: Product[] },
              index: number
            ) => {
              return (
                <>
                  {!(
                    data.label === "purchaseSummaryTable.product" &&
                    isEmpty(get(data, "products[0]"))
                  ) && (
                    <Grid
                      key={`${index}_detailsinformation`}
                      item
                      className={
                        validateProductLength(props)
                          ? classes.boxItemCenter
                          : classes.boxItemDefault
                      }
                      xs={12}
                      sm={6}
                      md={
                        data.products &&
                        data.label === "PRODUCTO" &&
                        index === props.information.length - 1
                          ? 12
                          : props.lengthColumnNumber
                      }
                    >
                      <Box>
                        <Typography
                          className={
                            validateProductLength(props)
                              ? classes.labelCenter
                              : classes.label
                          }
                        >
                          <T id={data.label} />
                        </Typography>
                        {data.products ? (
                          <Box className={classes.products}>
                            {data.products.map(
                              (product: Product, index: number) => (
                                <Typography
                                  key={`${index}_productDetailSummary`}
                                  className={classes.value}
                                >
                                  {product.description}
                                </Typography>
                              )
                            )}
                          </Box>
                        ) : (
                          <Typography className={classes.value}>
                            {data.value}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  )}
                </>
              );
            }
          )}
        </Grid>
      )}
    </React.Fragment>
  );
};
