import { useState } from "react";

export interface useFooter {
  displayContactModal: boolean;
  handler: {
    handleOpenContactModal: () => void;
  };
}
export const useFooterState = (): useFooter => {
  const [displayContactModal, setDisplayModal] = useState<boolean>(false);

  const handleOpenContactModal = () => {
    setDisplayModal(!displayContactModal);
  };
  return {
    displayContactModal,
    handler: {
      handleOpenContactModal,
    },
  };
};
