import React, { Fragment } from "react";
import { Box, Icon, Link, Typography, useMediaQuery } from "@material-ui/core";
import pci from "../../assets/images/pci.svg";
import logo_ksh from "../../assets/images/logo_ksh.png";
import { Translate as T } from "react-localize-redux";
import SelectLanguage from "./SelectLanguage/SelectLanguage";
import ToggleLanguage from "./Toggle/ToggleLanguage";
import { IWebcheckoutState } from "../../store/reducer";
import { connect } from "react-redux";
import { SmartLink } from "../../../types/webcheckout";
import { useFooterState } from "./Toggle/state/useFooter";
import email from "../../assets/images/mail.svg";
import phone from "../../assets/images/phone.svg";
import helpIcon from "../../assets/images/helpIcon.svg";
import { Customization } from "../../../types/merchant_fetch";
import { useStyles } from "./Footer.styles";

export interface FooterPropsState {
  smartLink?: SmartLink;
  merchantCustomizationInfo?: Customization;
}

export const Footer: React.FC<FooterPropsState> = (props: FooterPropsState) => {
  const isMobile = useMediaQuery("(max-width:960px)");
  const classes = useStyles();
  const { displayContactModal, handler } = useFooterState();
  const readMoreUrl =
    "https://kushkipagos.com/en/blog/certificacion-pci-pagos-mas-seguros-para-tus-clientes-y-tu-negocio";
  const footerBody = (
    <Fragment>
      <Box pt={3}>
        <Typography className={classes.infoLabel}>
          <T id="footer.messageFooter" />
          {props.smartLink?.merchantName}.
        </Typography>
        <Typography className={classes.infoLabel}>
          <img
            className={classes.pciIcon}
            src={pci}
            alt={pci}
            width={43}
            height={17}
          />
          <Typography component="span" className={classes.pciLabel}>
            <T id="footer.messagePay" />
          </Typography>
          <Link rel="noreferrer" target="_blank" href={readMoreUrl}>
            <Typography component="span" className={classes.readMore}>
              <T id="footer.readMore" />
            </Typography>
          </Link>
        </Typography>
      </Box>
    </Fragment>
  );
  const footerContact = (
    <Box
      display={"flex"}
      alignItems={"center"}
      onClick={handler.handleOpenContactModal}
      style={{
        minHeight: 64,
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <img src={helpIcon} alt={helpIcon} height={32} width={32} />
      <Typography className={classes.fontFooter}>
        <T id="footer.help" />
        <strong>
          <T id="footer.contacts" />
        </strong>
      </Typography>
    </Box>
  );
  return (
    <>
      {!isMobile ? (
        displayContactModal ? (
          <footer className={classes.footer}>
            <Box className={classes.container}>
              <Box
                className={classes.infoBox}
                alignSelf={"flex-end"}
                paddingBottom={"7px"}
              >
                {footerBody}
              </Box>
              <Box alignSelf={"flex-end"}>
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <SelectLanguage />
                </Box>
              </Box>
              {localStorage.getItem("contactPhoneNumber") ||
              localStorage.getItem("contactEmail") ? (
                <Box className={classes.boxContact}>
                  {footerContact}
                  <Box>
                    {localStorage.getItem("contactPhoneNumber") ? (
                      <Typography className={classes.infoLabel}>
                        <img
                          src={phone}
                          alt={phone}
                          height={18}
                          width={18}
                          style={{ paddingRight: "20px" }}
                        />
                        <a
                          href={
                            "tel:" + localStorage.getItem("contactPhoneNumber")
                          }
                        >
                          {localStorage.getItem("contactPhoneNumber")}
                        </a>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("contactEmail") ? (
                      <Typography className={classes.infoLabel}>
                        <img
                          src={email}
                          alt={email}
                          height={18}
                          width={18}
                          style={{ paddingRight: "20px" }}
                        />
                        <a
                          href={
                            "mailto:" + localStorage.getItem("contactEmail")
                          }
                        >
                          {localStorage.getItem("contactEmail")}
                        </a>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              ) : (
                <Box />
              )}
            </Box>
          </footer>
        ) : (
          <footer className={classes.footer}>
            <Box className={classes.container}>
              <Box className={classes.infoBox}>{footerBody}</Box>
              <Box alignSelf={"flex-end"}>
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <SelectLanguage />
                </Box>
              </Box>

              {localStorage.getItem("contactPhoneNumber") ||
              localStorage.getItem("contactEmail") ? (
                <Box className={classes.boxContact}>{footerContact}</Box>
              ) : (
                <Box />
              )}
            </Box>
          </footer>
        )
      ) : (
        <footer className={classes.footerMobil}>
          <Box className={classes.pciBoxMobil}>
            <Box alignSelf="center">
              <Box display="flex" height="100%" alignItems="center">
                <ToggleLanguage />
              </Box>
            </Box>
            <Box className={classes.infoBoxMobile}>
              <Box width="100%">
                <Typography className={classes.infoLabelMobil}>
                  <T id="footer.messageFooter" />
                  {props.smartLink?.merchantName}.
                </Typography>
                <Typography component="div" className={classes.infoLabelMobil}>
                  <Box display="flex" justifyContent="center" width="100%">
                    <Box margin={1}>
                      <Typography component="span">
                        <Icon component="span">
                          <img src={pci} alt={pci} width={43} height={17} />
                        </Icon>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      marginRight="4px"
                    >
                      <T id="footer.messagePay" />
                      <Box>
                        <Link
                          rel="noreferrer"
                          target="_blank"
                          href={readMoreUrl}
                        >
                          <Typography className={classes.readMoreMobile}>
                            <T id="footer.readMore" />
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Box
              display="inline-flex"
              alignSelf="center"
              className={classes.poweredContainer}
            >
              <span className={classes.poweredTitle}>Powered by</span>
              <img src={logo_ksh} width={67} height={16} alt="kushki-logo" />
            </Box>
          </Box>
        </footer>
      )}
    </>
  );
};
export const mapStateToProps: (state: IWebcheckoutState) => FooterPropsState = (
  state: IWebcheckoutState
): FooterPropsState => ({
  smartLink: state.webcheckout,
  merchantCustomizationInfo: state.merchantCustomizationInfo,
});
export default connect(mapStateToProps, null)(Footer);
