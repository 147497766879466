import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import success from "../../assets/SuccessIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useSpeiTransactionSummaryIndex } from "./useState/useSpeiTransactionSummaryIndex";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IWebcheckoutState } from "../../store/reducer";
import { IWebcheckoutAction, setHideTimer } from "../../store/actionCreators";
import { connect } from "react-redux";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    zIndex: 20,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "#F3F6F9",
  },
  card: {
    maxWidth: 672,
    marginLeft: 10,
    marginRight: 10,
  },
  kshLogo: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0px 20px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "2em",
    lineHeight: "120%",
    textAlign: "center",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1em",
    lineHeight: "150%",
    textAlign: "center",
    margin: "32px",
  },
  descriptionTitle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "120%",
    textAlign: "center",
    paddingBottom: "45px",
  },
  descriptionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: 25,
  },
  label: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#6D7781",
  },
  labelDescription: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    color: "#293036",
  },
});

export interface SpeiTransactionSummaryIndexFunctionProps {
  hideTimer: (value: boolean) => void;
}

export type SpeiTransactionSummaryIndexProps = SpeiTransactionSummaryIndexFunctionProps;

export const SpeiTransactionSummaryIndex: React.FC<SpeiTransactionSummaryIndexProps> = (
  props: SpeiTransactionSummaryIndexProps
) => {
  const { amount, clabe, isMobile } = useSpeiTransactionSummaryIndex(props);
  const classes = useStyles();
  return (
    <Box className={classes.container} pt={isMobile ? 2 : 10}>
      <Box>
        <Card className={classes.card}>
          <CardContent>
            <Box className={classes.kshLogo}>
              <img src={success} alt={success} />
            </Box>
            <Typography className={classes.title}>
              Compra en espera de pago
            </Typography>
            <Typography className={classes.subTitle}>
              Ingresa los siguientes datos en tu banco en línea para hacer la
              transferencia
            </Typography>
            <Typography className={classes.descriptionTitle}>
              Resumen de la compra
            </Typography>
            <Box className={classes.descriptionContainer}>
              <Box width={{ xs: "90%", sm: "60%" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>Monto:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.labelDescription}>
                      {amount}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>Clabe:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.labelDescription}>
                      {clabe}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className={classes.label}>
                      Nombre del beneficiario:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.labelDescription}>
                      Kushki
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className={classes.label}>
                      Banco destino:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.labelDescription}>
                      Accendo
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export const mapDispatchToProps: (
  dispatch: Dispatch
) => SpeiTransactionSummaryIndexFunctionProps = (
  dispatch: ThunkDispatch<IWebcheckoutState, undefined, IWebcheckoutAction>
): SpeiTransactionSummaryIndexFunctionProps => ({
  hideTimer: (value: boolean): void => {
    dispatch(setHideTimer(value));
  },
});

export const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeiTransactionSummaryIndex);
