import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";

export interface IDescriptionTab {
  selectTab: number;
  toSelect: number;
  paymentDescription: string;
}

export const DescriptionTab: React.FC<IDescriptionTab> = (
  props: IDescriptionTab
) => {
  return (
    <Box
      hidden={props.selectTab !== props.toSelect}
      id={`scrollable-prevent-tabpanel-${props.toSelect}`}
    >
      <Grid justify="center" alignItems="center" container>
        {isEmpty(props.paymentDescription) ? (
          <Typography style={{ width: "100%", textAlign: "center" }}>
            -
          </Typography>
        ) : (
          <Typography style={{ width: "100%" }}>
            {props.paymentDescription}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default DescriptionTab;
