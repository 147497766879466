import React from "react";
import transfer from "../../../assets/logos/transfer.svg";
import { BankList } from "../../../../types/bank_list";
import { FormCard, IFormCardProps } from "../../commons/FormCard/FormCard";
import {
  EmailInput,
  IdentificationInput,
  IdentificationSelect,
  NameInput,
  UserTypeSelect,
} from "../../commons/FormCardChildren/FormCardChildren";
import { Grid } from "@material-ui/core";
import { FormSelect, SelectItem } from "../../commons/FormSelect/FormSelect";
import pse from "../../../assets/logos/pse.svg";
import { TransferLoadingCard } from "../../TransferLoadingCard/TransferLoadingCard";
import { Translate as T } from "react-localize-redux";
import { TransferPaymentDataResponse } from "../../../../types/payment_data_response";
import { makeStyles } from "@material-ui/core/styles";
import { getValuePayButton } from "../../CardFormCard/state/useCardFormCardState";

const useStyles = makeStyles({
  color: {
    "& input, & div": {
      color: "#6D7781",
    },
  },
});

export const PseFormCard: React.FC<IFormCardProps> = (
  props: IFormCardProps
) => {
  const savedTransferPaymentData = props.savedSubscription as TransferPaymentDataResponse;
  const bankList: BankList = props.bankList || [];
  const banks: SelectItem[] = bankList.map((item) => ({
    value: item.code,
    label: item.name,
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <FormCard
        {...props}
        headerProps={{
          handleChangePaymentMethod: props.handleChangePaymentMethod,
          isMobile: props.isMobile,
          title: <T id="listPaymentMethods.titleTransfer" />,
          titleLogo: transfer,
          logos: [{ image: pse, value: "pse" }],
        }}
        showAcceptTerms
        submitLabel={getValuePayButton(
          props?.showAmount,
          props.totalAmount,
          props.currency
        )}
      >
        {props.loading && (
          <TransferLoadingCard
            mainLine={<T id="loadingTCard.mainLine" />}
            secondaryLine={<T id="loadingTCard.secondaryLine" />}
          />
        )}
        <Grid container spacing={2} className={classes.color}>
          <Grid item xs={12}>
            <FormSelect
              name={"bank"}
              form={props.form}
              items={banks}
              defaultValue={savedTransferPaymentData.bankId}
            />
          </Grid>
          <Grid item xs={12}>
            <UserTypeSelect
              form={props.form}
              defaultValue={savedTransferPaymentData.userType}
            />
          </Grid>
          <Grid item xs={12}>
            <NameInput
              form={props.form}
              defaultValue={savedTransferPaymentData.name}
            />
          </Grid>
          <Grid item xs={props.isMobile ? 4 : 3}>
            <IdentificationSelect
              defaultValue={savedTransferPaymentData.documentType}
              form={props.form}
              items={props.identificationType}
            />
          </Grid>
          <Grid item xs={props.isMobile ? 8 : 9}>
            <IdentificationInput
              form={props.form}
              defaultValue={savedTransferPaymentData.documentNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailInput
              form={props.form}
              defaultValue={savedTransferPaymentData.email}
            />
          </Grid>
        </Grid>
      </FormCard>
    </React.Fragment>
  );
};
