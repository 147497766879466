import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { Translate as T } from "react-localize-redux";

const useStyle = makeStyles({
  overlay: {
    position: "absolute",
    zIndex: 990,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlayContent: {
    left: "50%",
    top: "50%",
  },
  overlayTitle: {
    fontStyle: "normal",
    fontWeight: 200,
    color: "#293036",
    marginBottom: 10,
  },
  overlaySubtitle: {
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 300,
    marginBottom: 50,
    textAlign: "center",
  },
});

export interface LoadingCardProps {
  color?: string;
  isMobile?: boolean;
}

export const LoadingCard: React.FC<LoadingCardProps> = (
  props: LoadingCardProps
) => {
  const classes = useStyle();
  return (
    <div className={classes.overlay}>
      <div className={classes.overlayContent}>
        <Typography
          variant={props.isMobile ? "h3" : "h1"}
          className={classes.overlayTitle}
          align={"center"}
        >
          <T id="loadingCard.mainLine" />
        </Typography>
        <Typography className={classes.overlaySubtitle}>
          <T id="loadingCard.secondaryLine" />
        </Typography>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={"spin"}
          >
            <circle cx="40" cy="40" r="35" stroke="#F0F4F9" strokeWidth="10" />
            <path
              d="M40 5C20.67 5 5 20.67 5 40C5 59.33 20.67 75 40 75C55.6717 75 68.9378 64.6999 73.3977 50.5"
              stroke={get(props, "color", "#FF00FF")}
              strokeWidth="10"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
