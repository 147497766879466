import { useHistory } from "react-router-dom";
import { SmartLink } from "../../../../types/webcheckout";
import React, { useState } from "react";
import { get, defaultTo } from "lodash";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";
import { SET_ERROR_MESSAGE } from "../../../shared/utils/setErrorMessage";
import { ErrorCode } from "../../../shared/infrastructure/ErrorCodeEnum";
import { ErrorTitle } from "../../../shared/infrastructure/ErrorTitleEnum";
import { ErrorMessage } from "../../../shared/infrastructure/ErrorMessageEnum";
import { WebcheckoutTypeEnum } from "../../../shared/infrastructure/WebcheckoutTypeEnum";

export interface UseDeclineState {
  title: string;
  message: string;
  handler: {
    handleGoToStore: () => void;
    handleRetry: () => void;
  };
  source: string;
  isExpressCheckout: boolean;
}

export const useDeclineState = (): UseDeclineState => {
  const history = useHistory<SmartLink>();
  const state = history.location.state;
  const [title, setTitle] = useState<string>("declineTransaction.declineTitle");
  const webcheckout = useSelector(
    (state: IWebcheckoutState) => state.webcheckout
  );
  const [message, setMessage] = useState<string>(
    "declineTransaction.errorMessageCash"
  );
  const kind = get(state, "kind", "");
  const source = get(state, "source", "");
  // actualziar esquemas
  const isExpressCheckout =
    get(webcheckout, "kushkiMetadata.source", "") ===
    WebcheckoutTypeEnum.EXPRESS_CHECKOUT;

  React.useEffect(() => {
    if (kind !== "card") {
      setGAEvent(
        GA_CONSTANTS.expiredPage.category,
        GA_CONSTANTS.actions.click,
        GA_CONSTANTS.expiredPage.labels.H4
      );
      setTitle(ErrorTitle.CASH_TITTLE);
      setMessage(
        kind === "card-async"
          ? ErrorMessage.WEBPAY_INCOMPLETE_TRX
          : ErrorMessage.INCOMPLETE_TRX
      );
    } else {
      const errorCode = get(state, "codeError");
      handleSetMessage(errorCode);
      handleSetTitle(ErrorTitle.DECLINE_TITTLE);
    }
    if (kind === "3ds") {
      setTitle(ErrorTitle.TREE_D_SECURE);
      setMessage(ErrorMessage.THREE_D_SECURE);
    }
    setGAEvent(
      GA_CONSTANTS.expiredPage.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.expiredPage.labels.H3
    );
  }, []);
  const handleGoToStore = () => {
    setGAEvent(
      GA_CONSTANTS.rateOfReturn.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.rateOfReturn.labels.G1
    );

    window.location.replace(webcheckout?.redirectURL || state.redirectURL);
  };
  const handleRetry = () => {
    history.push(`/webcheckout/${state.id}`);
  };

  const handleSetTitle = (actualTitle: ErrorTitle) => {
    setTitle(defaultTo(actualTitle, ErrorTitle.CASH_TITTLE));
  };
  const handleSetMessage = (errorCode: ErrorCode) => {
    setMessage(defaultTo(SET_ERROR_MESSAGE[errorCode], ErrorMessage.DEFAULT));
  };

  return {
    title,
    message,
    handler: {
      handleGoToStore,
      handleRetry,
    },
    isExpressCheckout,
    source,
  };
};
