import React from "react";
import { Grid, Box } from "@material-ui/core";
import { formatAmount } from "../PurchaseDetail/PurchaseDetail";
import { IProduct } from "../PurchaseDetail/PurchaseDetail.interfaces";
import { isEmpty } from "lodash";

export interface IProductTab {
  selectTab: number;
  toSelect: number;
  products: IProduct[];
  currency: string;
}

export const ProductTab: React.FC<IProductTab> = (props: IProductTab) => {
  const products = props.products.filter((element) => {
    return !isEmpty(element);
  });

  return (
    <Box
      hidden={props.selectTab !== props.toSelect}
      id={`scrollable-prevent-tabpanel-${props.toSelect}`}
    >
      {products.map((product: IProduct, index: number) => (
        <React.Fragment key={index}>
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{
              paddingBottom: 24,
            }}
          >
            <Grid item xs={1}>
              {product.quantity}
            </Grid>
            <Grid item xs={6}>
              {product.description}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {formatAmount(product.unitPrice!, props.currency)}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ProductTab;
