import React, { RefObject, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { HeaderPayment } from "../../HeaderPayment/HeaderPayment";
import card from "../../../assets/logos/card.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Lock } from "react-feather";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { SelectOption } from "../../../containers/state/useMainState";
import { DeferredOptions } from "../../DeferredOptions/DeferredOptions";
import visa from "../../../assets/logos/visa.svg";
import amex from "../../../assets/logos/amex.svg";
import mastercard from "../../../assets/logos/Mastercard.svg";
import diners from "../../../assets/logos/Diners.svg";
import discover from "../../../assets/logos/Discover.svg";
import jcb from "../../../assets/logos/jcb.svg";
import carnet from "../../../assets/logos/Carnet.svg";
import { TermsConditions } from "../../TermsConditions/TermsConditions";
import { Translate as T, TranslateFunction } from "react-localize-redux";
import { setGAEvent } from "../../../shared/service/GoogleAnalyticsService";
import { GA_CONSTANTS } from "../../../shared/constants/GAConstants";
import { CardBrandsEnum } from "../../../shared/infrastructure/CardBrandsEnum";
import { StringUtils } from "../../../shared/utils/StringUtils";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../../../store/reducer";
import { TransactionTypeEnum } from "../../../shared/infrastructure/TransactionTypeEnum";
import { ISiftscienceForm } from "../state/interfaces/ISiftScienceForm";
import { ISiftForm } from "../state/interfaces/ISiftForm";
import { TextMaskCustom } from "../commons/TextMaskCustom";
import { LoadingCard } from "../../LoadingCard/LoadingCard";
import { getValuePayButton } from "../state/useCardFormCardState";
import { BillingDetails, Webcheckout } from "../../../../types/webcheckout";
import { PayInInstallmentsMessage } from "../../../shared/constants/MonthlyPaymentMessage";
import { get } from "lodash";

export interface ISavedCardFormProps {
  logos: { image: string; value: string }[];
  openForm: boolean;
  isMobile: boolean;
  color?: string;
  secondaryColor?: string;
  buttonStyle?: string;
  handleChangePaymentMethod: () => void;
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    handleSubmit: any;
    handleOnSubmitForm: () => void;
    control: Control<Record<string, any>>;
    currentValues: {
      months: string;
      monthsOfGrace: string;
      typeDeferred: string;
    };
  };
  deferredOptions?: {
    hasDeferred?: boolean;
    isDynamic?: boolean;
    typesDeferred?: SelectOption[];
    months?: SelectOption[];
    monthsOfGrace?: SelectOption[];
    isActive?: boolean;
  };
  typeCard?: string;
  title: string;
  subtitle: string;
  logo: string;
  handleSetFields: (kind: string, value?: string) => void;
  handleClose: () => void;
  totalAmount: number;
  currency: string;
  openTerms: boolean;
  loading?: boolean;
  translate: TranslateFunction;
  siftscienceForm?: ISiftscienceForm;
  siftFormData?: ISiftForm;
  savedCardId?: string;
  billingDetails?: BillingDetails;
  showMonthlyPaymentMessage?: boolean;
  country?: string;
}

export const SavedCardForm: (
  props: ISavedCardFormProps
) => JSX.Element | null = (props: ISavedCardFormProps) => {
  const siftScienceEnable = useSelector(
    (state: IWebcheckoutState) => state.merchantSiftScience?.enabled
  );
  const transactionType = useSelector(
    (state: IWebcheckoutState) => state.webcheckout?.transactionType
  );
  const [showSiftForm, setShowSiftForm] = useState(siftScienceEnable);
  const getBorderRadious = () => {
    if (props.buttonStyle === "round") {
      return "20px";
    }
    if (props.buttonStyle === "semi") {
      return "12px";
    }
    return "2px";
  };

  const getBrandLogos = (brand: string): string => {
    switch (StringUtils.removeBlanksAndConvertToLowercase(brand)) {
      case CardBrandsEnum.VISA:
        return visa;
      case CardBrandsEnum.AMEX:
        return amex;
      case CardBrandsEnum.MASTERCARD:
        return mastercard;
      case CardBrandsEnum.DINERS:
        return diners;
      case CardBrandsEnum.DISCOVER:
        return discover;
      case CardBrandsEnum.JCB:
        return jcb;
      case CardBrandsEnum.CARNET:
        return carnet;
      default:
        return "";
    }
  };

  const { secondaryColor = "#000000" } = props;
  const [fontColor, lightenColor]: string[] = StringUtils.getContrastYIQ(
    secondaryColor
  );
  const useStyles = makeStyles(() => ({
    root: {
      "&$checked": {
        color: props.color,
      },
      "& a": {
        color: props.color,
      },
    },
    blurry: {
      WebkitFilter: "blur(5px)",
      msFilter: "blur(5px)",
      filter: "blur(5px)",
      userSelect: "none",
    },
    button: {
      backgroundColor: props.secondaryColor,
      borderRadius: getBorderRadious,
      color: fontColor,
      "&:hover": {
        backgroundColor: lightenColor,
        color: fontColor,
      },
    },
    checked: {},
    imgStyle: {
      width: "75%",
      height: "auto",
    },
    label: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal",
      color: "#293036",
    },
    link: {
      color: props.color,
      fontSize: "14px",
    },
    listItemIconStyle: {
      minWidth: "70px",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    setShowSiftForm(
      siftScienceEnable && transactionType === TransactionTypeEnum.SALE
    );
  }, [siftScienceEnable]);

  return props.openForm ? (
    <React.Fragment>
      <Card
        hidden={props?.siftscienceForm?.open}
        style={{
          position: "relative",
        }}
      >
        <CardContent>
          <form
            onSubmit={props.form.handleSubmit(props.form.handleOnSubmitForm)}
          >
            {props.loading && <LoadingCard color={props.color} />}
            <Box
              width={"100%"}
              height={"100%"}
              className={props.loading ? classes.blurry : ""}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{ paddingRight: props.isMobile ? "16px" : "28px" }}
                >
                  <HeaderPayment
                    title={<T id="listPaymentMethods.titleCardAndDebit" />}
                    titleLogo={card}
                    isMobile={props.isMobile}
                    logos={props.logos}
                    handleChangePaymentMethod={props.handleChangePaymentMethod}
                  />
                </Grid>
                <Grid item xs={12} hidden={props.isMobile}>
                  <Typography variant={"caption"}>
                    <T id="messagePCI" />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List>
                    <ListItem disableGutters>
                      <ListItemIcon className={classes.listItemIconStyle}>
                        <img
                          className={classes.imgStyle}
                          src={getBrandLogos(props.logo)}
                          alt={`${props.logo.toLowerCase()}-brand-logo`}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box>
                            <Typography
                              variant={"body1"}
                              color="textPrimary"
                              align="left"
                            >
                              {props.title}
                            </Typography>
                            <Typography
                              variant={"body1"}
                              color="textPrimary"
                              align="left"
                            >
                              {props.subtitle}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  </List>
                  <TextField
                    onClick={() => {
                      setGAEvent(
                        GA_CONSTANTS.CVCCode.category,
                        GA_CONSTANTS.actions.click,
                        GA_CONSTANTS.CVCCode.labels.E4
                      );
                    }}
                    fullWidth
                    variant={"outlined"}
                    placeholder={"CVC"}
                    name={"cvv"}
                    type="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color={"#6D7781"} size={16} />
                        </InputAdornment>
                      ),
                      inputComponent: TextMaskCustom as any,
                      inputProps: { mask: [/\d/, /\d/, /\d/, /\d/] },
                    }}
                    inputRef={props.form.register({
                      required: <T id="form.fieldRequired" />,
                    })}
                    error={props.form.errors.cvv}
                    helperText={
                      props.form.errors.cvv ? props.form.errors.cvv.message : ""
                    }
                  />
                </Grid>
                {!props.isMobile && (
                  <Grid item xs={6}>
                    {""}
                  </Grid>
                )}
                {props.typeCard !== "debit" && (
                  <DeferredOptions
                    handleSetFields={props.handleSetFields}
                    form={props.form}
                    color={props.color}
                    deferredOptions={props.deferredOptions}
                    showMonthlyPaymentMessage={props.showMonthlyPaymentMessage}
                    country={props.country}
                  />
                )}
                <Grid item xs={12}>
                  <FormControl>
                    <FormControlLabel
                      labelPlacement={"end"}
                      label={
                        <Typography className={classes.label}>
                          <T id="agree" />
                          <Link
                            onClick={props.handleClose}
                            className={classes.link}
                          >
                            <T id="terms" />
                          </Link>
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onClick={() => {
                            setGAEvent(
                              GA_CONSTANTS.CVCCode.category,
                              GA_CONSTANTS.actions.click,
                              GA_CONSTANTS.CVCCode.labels.E6
                            );
                          }}
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                          color={"primary"}
                        />
                      }
                      inputRef={props.form.register({
                        required: <T id="form.fieldRequired" />,
                      })}
                      name={"terms"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button
                      type="submit"
                      variant={"contained"}
                      color={"primary"}
                      className={classes.button}
                      onClick={() =>
                        setGAEvent(
                          GA_CONSTANTS.CVCCode.category,
                          GA_CONSTANTS.actions.click,
                          GA_CONSTANTS.CVCCode.labels.E7
                        )
                      }
                    >
                      <T id="payButtonWithMount" />
                      {getValuePayButton(
                        true,
                        props.totalAmount,
                        props.currency
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </CardContent>
        <TermsConditions
          handleClose={props.handleClose}
          open={props.openTerms}
          isMobile={props.isMobile}
        />
      </Card>
    </React.Fragment>
  ) : null;
};
