import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Divider, Typography } from "@material-ui/core";
import { formatAmount } from "../PurchaseDetail/PurchaseDetail";
import { Translate as T } from "react-localize-redux";
import { ICommission } from "../PurchaseDetail/PurchaseDetail.interfaces";
import { get } from "lodash";

export interface IDesgolseTab {
  selectTab: number;
  toSelect: number;
  currency: string;
  amount: number;
  subtotal: number;
  iva: number;
  commissions?: ICommission;
}

export const AmountDetailTab: React.FC<IDesgolseTab> = (
  props: IDesgolseTab
) => {
  const useStyles = makeStyles({
    label: {
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "120%",
      color: "#293036",
    },
    labelAmount: {
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "120%",
      color: "#293036",
      textAlign: "right",
    },
    divider: {
      marginTop: 24,
      marginBottom: 16,
    },
    comisiones: {
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "136%",
      color: "#6D7781",
      marginBottom: 24,
    },
  });
  const classes = useStyles();

  return (
    <Box
      hidden={props.selectTab !== props.toSelect}
      id={`scrollable-prevent-tabpanel-${props.toSelect}`}
    >
      <Grid container spacing={5}>
        <Grid item xs={6} className={classes.label}>
          <T id="subtotal" />
        </Grid>
        <Grid item xs={6} className={classes.labelAmount}>
          {formatAmount(props.subtotal, props.currency)}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6} className={classes.label}>
          <T id="taxes" />
        </Grid>
        <Grid item xs={6} className={classes.labelAmount}>
          {formatAmount(props.iva, props.currency)}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6} className={classes.label}>
          <T id="total" />
        </Grid>
        <Grid item xs={6} className={classes.labelAmount}>
          {formatAmount(
            props.amount - get(props, "commissions.totalAmount", 0),
            props.currency
          )}
        </Grid>
      </Grid>
      {props.commissions ? (
        <React.Fragment>
          <Divider variant={"fullWidth"} className={classes.divider} />
          <Typography className={classes.comisiones}>Comisiones</Typography>
          <Grid container spacing={5}>
            <Grid item xs={6} className={classes.label}>
              <T id="commission" /> {props.commissions.parentMerchantName}
            </Grid>
            <Grid item xs={6} className={classes.labelAmount}>
              {formatAmount(props.commissions.totalAmount, props.currency)}
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={6} className={classes.label}>
              <T id="purchaseDetail.totalToPay" />
            </Grid>
            <Grid item xs={6} className={classes.labelAmount}>
              {formatAmount(props.amount, props.currency)}
            </Grid>
          </Grid>
        </React.Fragment>
      ) : null}
    </Box>
  );
};

export default AmountDetailTab;
