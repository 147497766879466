import React, { RefObject } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { SelectOption } from "../../containers/state/useMainState";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";
import { PayInInstallmentsMessage } from "../../shared/constants/MonthlyPaymentMessage";

export interface IDeferredOptionsProps {
  deferredOptions?: {
    hasDeferred?: boolean;
    isDynamic?: boolean;
    typesDeferred?: SelectOption[];
    months?: SelectOption[];
    monthsOfGrace?: SelectOption[];
    isActive?: boolean;
  };
  color?: string;
  handleSetFields: (kind: string, value?: string) => void;
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    handleSubmit: any;
    handleOnSubmitForm: () => void;
    control: Control<Record<string, any>>;
    currentValues: {
      months: string;
      monthsOfGrace: string;
      typeDeferred: string;
    };
  };
  showMonthlyPaymentMessage?: boolean;
  country?: string;
}

export const DeferredOptions: React.FC<IDeferredOptionsProps> = (
  props: IDeferredOptionsProps
) => {
  const useStyles = makeStyles(() => ({
    root: {
      "&$checked": {
        color: props.color,
      },
      "& a": {
        color: props.color,
      },
    },
    checked: {},
    label: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal",
      color: "#293036",
    },
    complementaryMessage: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "136%",
      color: "#293036",
      marginLeft: "8px",
    },
  }));
  const classes = useStyles();
  const getGridSpacing = () => {
    const lengthTypeDef: number = props.deferredOptions!.typesDeferred!.length;
    const lengthMonths: number = props.deferredOptions!.months!.length;
    const lengthMGrace: number = props.deferredOptions!.monthsOfGrace!.length;

    if (lengthTypeDef > 0 && lengthMGrace > 0 && lengthMonths > 0) return 3;
    else if (lengthTypeDef > 0 && lengthMGrace === 0 && lengthMonths > 0)
      return 4;
    else return 6;
  };

  return props.deferredOptions && props.deferredOptions.hasDeferred ? (
    <React.Fragment>
      <Grid item xs={6} md={getGridSpacing()}>
        <FormControl>
          <FormControlLabel
            labelPlacement={"end"}
            label={
              <Typography className={classes.label}>
                <T id="deferred.checkLabel" />
              </Typography>
            }
            control={
              <Checkbox
                color={"primary"}
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                onChange={() => props.handleSetFields("activeQuote")}
              />
            }
          />
        </FormControl>
      </Grid>
      {props.deferredOptions.isActive ? (
        <>
          {props.deferredOptions.typesDeferred &&
          props.deferredOptions.isDynamic &&
          props.deferredOptions.typesDeferred.length > 0 ? (
            <React.Fragment>
              <Grid item xs={6} md={getGridSpacing()}>
                <FormControl variant={"outlined"} fullWidth>
                  <InputLabel id={"type"}>
                    <T id="deferred.typeDeferred" />
                  </InputLabel>
                  <Controller
                    control={props.form.control}
                    render={() => (
                      <Select
                        value={props.form.currentValues.typeDeferred}
                        onChange={(evt) =>
                          props.handleSetFields(
                            "typeDeferred",
                            evt.target.value as string
                          )
                        }
                        label={"Tipo"}
                      >
                        {props.deferredOptions!.typesDeferred!.map(
                          (option: SelectOption, index) => (
                            <MenuItem
                              key={`${index}_typedeferred`}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                    name="typeDeferred"
                    defaultValue={props.form.currentValues.typeDeferred}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          ) : null}
          {props.deferredOptions.months &&
          props.deferredOptions.months.length > 0 ? (
            <Grid item xs={6} md={getGridSpacing()}>
              <FormControl variant={"outlined"} fullWidth>
                <InputLabel id={"months"}>
                  <T id="deferred.months" />
                </InputLabel>
                <Controller
                  control={props.form.control}
                  render={() => (
                    <Select
                      value={props.form.currentValues.months}
                      onChange={(evt) =>
                        props.handleSetFields(
                          "months",
                          evt.target.value as string
                        )
                      }
                      name={"months"}
                      label={"Cuotas"}
                    >
                      {props.deferredOptions !== undefined &&
                        props.deferredOptions.months !== undefined &&
                        props.deferredOptions.months.map(
                          (option: SelectOption, index) => (
                            <MenuItem
                              key={`${index}_month`}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  )}
                  name="months"
                  defaultValue={props.form.currentValues.months}
                />
              </FormControl>
            </Grid>
          ) : null}
          {props.deferredOptions.monthsOfGrace &&
          props.deferredOptions.monthsOfGrace.length > 0 ? (
            <Grid item xs={6} md={getGridSpacing()}>
              <FormControl variant={"outlined"} fullWidth>
                <InputLabel id={"monthsOfGrace"}>
                  <T id="deferred.monthsOfGrace" />
                </InputLabel>
                <Controller
                  control={props.form.control}
                  render={() => (
                    <Select
                      value={props.form.currentValues.monthsOfGrace}
                      onChange={(evt) =>
                        props.handleSetFields(
                          "monthsOfGrace",
                          evt.target.value as string
                        )
                      }
                      name={"monthsOfGrace"}
                      label={"Meses de gracia"}
                    >
                      {props.deferredOptions !== undefined &&
                        props.deferredOptions.monthsOfGrace !== undefined &&
                        props.deferredOptions.monthsOfGrace.map(
                          (option: SelectOption, index) => (
                            <MenuItem
                              key={`${index}_monthsofgraceOptions`}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  )}
                  name="monthsOfGrace"
                  defaultValue={props.form.currentValues.monthsOfGrace}
                />
              </FormControl>
            </Grid>
          ) : null}
          {props.showMonthlyPaymentMessage && (
            <Grid>
              <Typography className={classes.complementaryMessage}>
                {PayInInstallmentsMessage[props.country!]}
              </Typography>
            </Grid>
          )}
        </>
      ) : null}
    </React.Fragment>
  ) : null;
};
